export type TRGBAColorWith1AtTheEnd = `rgba(${number}, ${number}, ${number}, 1)`

export type TRGBAColorWithOpacityAtTheEnd = `rgba(${number}, ${number}, ${number}, ${number})`

export type TRGBAChartZoneColor = `rgba(${number}, ${number}, ${number}, 0.6)`

const colorsRaw = {
  // theme colors from Semios Design System Figma
  midnight: 'rgba(11, 20, 36, 1)',
  gray: 'rgba(204, 204, 204, 1)', // TODO: remove this after subsequent colors cleanup PR
  primary: 'rgba(14, 77, 164, 1)',
  primaryLight: 'rgba(40, 112, 214, 1)',
  secondary100: 'rgba(4, 227, 222, 1)',
  secondary500: 'rgba(0, 176, 172, 1)',
  secondary600: 'rgba(0, 133, 130, 1)',
  secondary800: 'rgba(0, 92, 91, 1)',
  black: 'rgba(0, 0, 0, 1)',
  blue: 'rgba(69, 142, 247, 1)',
  blue200: 'rgba(173, 197, 255, 1)',
  blue300: 'rgba(145, 174, 255, 1)',
  blue600: 'rgba(40, 112, 213, 1)',
  blue800: 'rgba(0, 75, 155, 1)',
  white: 'rgba(255, 255, 255, 1)',
  grey900: 'rgba(102, 102, 102, 1)',
  grey800: 'rgba(70, 75, 83, 1)',
  grey500: 'rgba(180, 179, 187, 1)',
  grey200: 'rgba(226, 226, 226, 1)',
  grey50: 'rgba(248, 248, 248, 1)',
  baseRed: 'rgba(186, 22, 21, 1)',
  red: 'rgba(235, 76, 76, 1)',
  red200: 'rgba(255, 172, 170, 1)',
  red500: 'rgba(251, 110, 114, 1)',
  red800: 'rgba(148, 23, 42, 1)',
  yellow: 'rgba(255, 171, 0, 1)',
  yellow200: 'rgba(220, 197, 119, 1)',
  yellow500: 'rgba(179, 154, 20, 1)',
  yellow800: 'rgba(90, 75, 0, 1)',
  orange: 'rgba(240, 146, 54, 1)',
  orange200: 'rgba(249, 185, 133, 1)',
  orange500: 'rgba(175, 92, 0, 1)',
  orange800: 'rgba(122, 58, 0, 1)',
  green: 'rgba(103, 202, 77, 1)',
  green200: 'rgba(159, 213, 140, 1)',
  green500: 'rgba(53, 154, 32, 1)',
  green800: 'rgba(0, 90, 0, 1)',
  pink: 'rgba(188, 80, 144, 1)',
  pink500: 'rgba(210, 93, 155, 1)',
  pink200: 'rgba(251, 177, 212, 1)',
  pink800: 'rgba(133, 37, 85, 1)',
  purple: 'rgba(174, 91, 205, 1)',
  purple200: 'rgba(232, 180, 248, 1)',
  purple300: 'rgba(218, 152, 241, 1)',
  purple800: 'rgba(111, 45, 136, 1)',
  royalBlue: 'rgba(65, 105, 225, 1)',

  // colors for Wind Machine charts
  wmPink: 'rgba(237, 115, 178, 1)',
  wmGreen: 'rgba(103, 202, 77, 1)',
  wmPurple: 'rgba(174, 91, 205, 1)',
  wmBlue: 'rgba(69, 142, 247, 1)',

  // colors for standardized charts
  aboveCanopy: 'rgba(14, 77, 164, 1)',
  inCanopy: 'rgba(21, 174, 36, 1)',
  belowCanopy: 'rgba(243, 165, 51, 1)',
  rain: 'rgba(40, 112, 214, 1)',

  lightYellow: 'rgba(242, 201, 76, 1)',
  lightGreen: 'rgba(196, 235, 200, 1)',

  zoneBorderLine: 'rgba(0, 0, 0, 1)',
  idealSaturatedBoundary: 'rgba(82, 93, 244, 1)',
  saturatedZone: 'rgba(122, 215, 244, 1)',
  idealZone: 'rgba(41, 205, 107, 1)',
  moderateDeficitZone: 'rgba(255, 238, 79, 1)',
  highDeficitZone: 'rgba(254, 148, 2, 1)',
  extremeDeficitZone: 'rgba(229, 53, 0, 1)',
  idealBadge: 'rgba(41, 205, 107, 1)',
  moderateDeficitBadge: 'rgba(255, 171, 0, 1)',
  extremeDeficitBadge: 'rgba(235, 76, 76, 1)',
  shortTitleForTables: 'rgba(14, 77, 164, 1)',
  offBadge: 'rgba(11, 20, 36, 1)',
  fullRunBadge: 'rgba(41, 205, 107, 1)',
  noConnectionBadge: 'rgba(255, 255, 255, 1)',

  forestGreen: 'rgba(34, 139, 34, 1)',
  chocolate: 'rgba(210, 105, 30, 1)',
  firebrick: 'rgba(178, 34, 34, 1)',

  awcZoneSaturated: 'rgba(189, 235, 250, 1)',
  awcZoneIdeal: 'rgba(148, 230, 181, 1)',
  awcZoneDeficitModerate: 'rgba(255, 247, 167, 1)',
  awcZoneDeficitHigh: 'rgba(255, 201, 128, 1)',
  awcZoneDeficitExtreme: 'rgba(238, 123, 89, 1)',

  // AWC line colors
  awcGreen: 'rgba(103, 202, 77, 1)', // #67CA4D
  awcOrange: 'rgba(240, 146, 54, 1)', // #F09236
  awcBlue: 'rgba(69, 142, 247, 1)', // #458EF7
  awcPink: 'rgba(237, 115, 178, 1)', // #ED73B2
  awcPurple: 'rgba(174, 91, 205, 1)', // #AE5BCD
  awcYellow: 'rgba(249, 217, 73, 1)', // #F9D949
  awcRed: 'rgba(202, 49, 66, 1)', // #CA3142
  awcPink800: 'rgba(133, 37, 91, 1)', // #85255B
  awcGreen800: 'rgba(0, 90, 0, 1)', // #005A00
  awcBlue800: 'rgba(0, 75, 155, 1)', // #004B9B
  awcPurple800: 'rgba(111, 45, 136, 1)', // #6F2D88
  awcYellow800: 'rgba(90, 75, 0, 1)', // #5A4B00
  awcRed800: 'rgba(148, 23, 42, 1)', // #94172A
  awcOrange800: 'rgba(122, 58, 0, 1)', // #7A3A00
  awcPink500: 'rgba(210, 93, 155, 1)', // #D25D9B
  awcGreen500: 'rgba(53, 154, 32, 1)', // #359A20

  // high/low heatmap colors
  highLowHeatmapStrongBlue: 'rgba(51, 131, 249, 1)',
  highLowHeatmapMediumBlue: 'rgba(122, 215, 244, 1)',
  highLowHeatmapMediumYellow: 'rgba(255, 222, 70, 1)',
  highLowHeatmapMediumOrange: 'rgba(254, 148, 2, 1)',
  highLowHeatmapStrongRed: 'rgba(229, 53, 0, 1)',

  // danger heatmap colors
  dangerHeatmapSoftYellow: 'rgba(255, 238, 79, 1)',
  dangerHeatmapStrongYellow: 'rgba(249, 216, 48, 1)',
  dangerHeatmapSoftOrange: 'rgba(246, 191, 64, 1)',
  dangerHeatmapStrongOrange: 'rgba(254, 148, 2, 1)',
  dangerHeatmapSoftRed: 'rgba(255, 101, 35, 1)',
  dangerHeatmapStrongRed: 'rgba(229, 53, 0, 1)',

  // irrigation heatmap colors
  irrigationHeatmapSoftBlue: 'rgba(144, 213, 241, 1)',
  irrigationHeatmapMediumBlue: 'rgba(73, 129, 241, 1)',
  irrigationHeatmapStrongBlue: 'rgba(49, 90, 167, 1)',

  // irrigation scheduler colors
  peakHours: 'rgba(255, 171, 0, 1)',
  applied: 'rgba(21, 174, 36, 1)',
  appliedBorder: 'rgba(3, 126, 15, 1)',
  scheduled: 'rgba(197, 235, 201, 1)',
  scheduledBorder: 'rgba(158, 209, 163, 1)',
  draftPreview: 'rgba(177, 177, 177, 1)', // TODO
  draftPreviewBorder: 'rgba(96, 96, 96, 1)', // TODO
  draftCreate: 'rgba(177, 177, 177, 1)',
  draftCreateBorder: 'rgba(96, 96, 96, 1)', // TODO from UX
  draftDelete: 'rgba(246, 180, 179, 1)',
  draftDeleteBorder: 'rgba(252, 93, 91, 1)', // TODO from UX
  pendingDelete: 'rgba(246, 180, 179, 1)',
  pendingDeleteBorder: 'rgba(252, 93, 91, 1)', // TODO from UX
  backgroundLightGray: 'rgba(248, 248, 248, 1)',
  borderLightGray: 'rgba(226, 226, 226, 1)',
  stopped: 'rgba(255, 171, 0, 1)',
  stoppedBorder: 'rgba(225, 141, 0, 1)',
  selectedEventBorder: 'rgba(247, 252, 0, 1)', // TODO UX: This is hard to see...

  // plant stress colors
  plantStressLow: 'rgba(41, 205, 107, 1)',
  plantStressMedium: 'rgba(255, 171, 0, 1)',
  plantStressHigh: 'rgba(235, 76, 76, 1)',
  // spray application plot line colors
  sprayRecordGreen: 'rgba(0, 128, 0, 1)',

  // compare seasons colors,
  compareSeasonsGreen: 'rgba(103, 202, 77, 1)',

  // default line chart colors (from Charline)
  lineChartSeriesOne: 'rgba(103, 202, 77, 1)',
  lineChartSeriesTwo: 'rgba(240, 146, 54, 1)',

  // alma
  almaDark: 'rgba(0, 0, 0, 1)',
  almaBackground: 'rgba(239, 239, 239, 1)',

  //regions
  regionsCompare: 'rgba(210, 93, 155, 1)',
} as const

const chartZoneColorsRaw = {
  // plant stress colors
  plantStressZoneLow: 'rgba(41, 205, 107, 0.6)',
  plantStressZoneMedium: 'rgba(255, 171, 0, 0.6)',
  plantStressZoneHigh: 'rgba(235, 76, 76, 0.6)',
} as const

export const irrigationEventsColorMap = {
  'rain': colorsRaw.rain,
  'emitter-buried-drip-1': 'rgba(157, 56, 117, 1)',
  'emitter-buried-drip-2': 'rgba(188, 80, 144, 1)',
  'emitter-drip-1': 'rgba(16, 129, 87, 1)',
  'emitter-drip-2': 'rgba(84, 171, 130, 1)',
  'emitter-microspray': 'rgba(255, 139, 115, 1)',
  'emitter-sprinkler': 'rgba(145, 174, 255, 1)',
  'emitter-flood-furrow': 'rgba(0, 74, 154, 1)',
  'emitter-overhead-cooling-microspray': 'rgba(105, 33, 0, 1)',
  'emitter-overhead-cooling-sprinkler': 'rgba(167, 58, 8, 1)',
  'emitter-other': 'rgba(97, 82, 183, 1)',
  'pump': 'rgba(186, 22, 21, 1)',
} as const

export const SOIL_MOISTURE_COLORS_ARRAY = [
  colorsRaw.awcGreen,
  colorsRaw.awcOrange,
  colorsRaw.awcBlue,
  colorsRaw.awcPink,
  colorsRaw.awcPurple,
  colorsRaw.awcYellow,
  colorsRaw.awcRed,
  colorsRaw.awcPink800,
  colorsRaw.awcGreen800,
  colorsRaw.awcBlue800,
  colorsRaw.awcPurple800,
  colorsRaw.awcYellow800,
  colorsRaw.awcRed800,
  colorsRaw.awcOrange800,
  colorsRaw.awcPink500,
  colorsRaw.awcGreen500,
]

// TODO: when upgrading to TypeScript 4.9, we might be able to use satisfies to avoid this funky thing
export const colors: Record<keyof typeof colorsRaw, TRGBAColorWith1AtTheEnd> = colorsRaw

export const alphaColorReplacer = (
  color: TRGBAColorWithOpacityAtTheEnd,
  alpha: number,
): TRGBAColorWithOpacityAtTheEnd =>
  color.replace(
    /*  ( ( <-- these parentheses keeps my rainbow-bracket plugin happy */ ' 1)',
    ` ${alpha})`,
  ) as TRGBAColorWithOpacityAtTheEnd

export const chartZoneColors: Record<keyof typeof chartZoneColorsRaw, TRGBAChartZoneColor> =
  chartZoneColorsRaw

export const getColorForRegionalSeries = (countOfOtherSeries: number): TRGBAColorWith1AtTheEnd => {
  if (countOfOtherSeries === 1) return colors.regionsCompare

  return colors.midnight
}
