import { routes } from '@semios/app-platform-banyan-route-definitions'
import { StackedChartPestSection } from 'components/StackedChart/types'
import { selectedFieldAssetsStore, TSelectedFieldAssetsStoreState } from 'stores/selectedFieldAssetsStore'
import { selectedValueGroupsStore } from 'stores/selectedValueGroupsStore/selectedValueGroupsStore'
import * as insectTrapCatchesPropertyLevel from './by-scope/insectTrapCatchesPropertyLevel'
import * as insectTrapCatchesBlockLevel from './by-scope/insectTrapCatchesBlockLevel'
import * as insectTrapCatchesRegionLevel from './by-scope/insectTrapCatchesRegionLevel'
import { mergeApiArgs } from 'App/Map/PanelDetails/_utils/mergeApiArgs'
import { getSelectedRegion } from 'utils/getSelectedRegion'
import { fieldAssetStore } from 'stores/fieldAssetStore'
import { smallStore } from 'stores/smallStore'
import { isUserOnlyAFreeRegionalUser } from 'utils/isUserOnlyAFreeRegionalUser'

export const apiArgs = ({
  selectedValueGroups,
  selectedFieldAssets,
}: {
  selectedValueGroups: ReturnType<typeof selectedValueGroupsStore.getState>['selectedValueGroups']
  selectedFieldAssets: TSelectedFieldAssetsStoreState
}): Partial<routes.Values.Request> => {
  const propertyLevelValues = insectTrapCatchesPropertyLevel.apiArgs({
    selectedValueGroups,
    selectedFieldAssets,
  })

  const blockLevelValues = insectTrapCatchesBlockLevel.apiArgs({ selectedValueGroups, selectedFieldAssets })
  const regionLevelValues = insectTrapCatchesRegionLevel.apiArgs({ selectedValueGroups, selectedFieldAssets })

  return mergeApiArgs(propertyLevelValues, blockLevelValues, regionLevelValues)
}

export const content = ({
  data,
  selectedFieldAssets,
  selectedValueGroups,
  compareSeasonsData,
}: {
  data: routes.Values.Response
  compareSeasonsData: routes.Values.Response
  selectedValueGroups: ReturnType<typeof selectedValueGroupsStore.getState>['selectedValueGroups']
  selectedFieldAssets: TSelectedFieldAssetsStoreState
}): StackedChartPestSection[] => {
  const selectedRegion = getSelectedRegion({ selectedProperty: selectedFieldAssetsStore.getState().property })
  const compareRegionsData = data?.regions?.[String(selectedRegion)]
  const regionName = fieldAssetStore.getState()?.regions?.[String(selectedRegion)]?.name ?? null
  const showRegionalData = smallStore.getState().showRegionalData

  if (showRegionalData && selectedRegion && isUserOnlyAFreeRegionalUser()) {
    const regionLevelData = insectTrapCatchesRegionLevel.content({
      data,
      selectedValueGroups,
      selectedFieldAssets,
      regionId: selectedRegion,
    })

    return regionLevelData
  } else {
    const propertyLevelContent = insectTrapCatchesPropertyLevel.content({
      data,
      selectedValueGroups,
      selectedFieldAssets,
      compareSeasonsData,
      compareRegionsData,
      regionName,
    })

    const blockLevelContent = insectTrapCatchesBlockLevel.content({
      data,
      selectedValueGroups,
      selectedFieldAssets,
      compareSeasonsData,
      compareRegionsData,
      regionName,
    })

    return [...propertyLevelContent, ...blockLevelContent]
  }
}
