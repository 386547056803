import { openModal, closeModal } from '@mantine/modals'
import { ErrorBoundary } from '@sentry/react'
import NodePositioningModal from 'App/ServiceCenter/NodePositioningModal'
import React, { useContext, useEffect, useState } from 'react'
import { translate } from 'i18n/i18n'
import { openNodeInstallationOverviewModal } from '../InstallationOverviewModal'
import { installationWorkflowStore } from 'App/ServiceCenter/store/installationWorkflowStore'
import { MapContext } from 'App/Map/MapContext/MapContext'

const MODAL_ID = 'drop-installation-overview'

export function openDropInstallModal() {
  openModal({
    modalId: MODAL_ID,
    fullScreen: true,
    withCloseButton: false,
    padding: 0,
    children: (
      <ErrorBoundary>
        <DropInstallModal />
      </ErrorBoundary>
    ),
    styles: {
      content: {
        marginLeft: 'env(safe-area-inset-left)',
        marginRight: 'env(safe-area-inset-right)',
        boxShadow: 'none',
        transform: 'none !important',
      },
    },
  })
}

const DropInstallModal: React.FC = () => {
  const [currentLocation, setCurrentLocation] = useState<google.maps.LatLngLiteral | null>(null)
  const { map } = useContext(MapContext)
  const mapCenter = map?.getCenter()?.toJSON()
  const mapZoom = map?.getZoom()

  if (!mapCenter || !mapZoom) return null

  useEffect(() => {
    navigator.geolocation.getCurrentPosition((position) => {
      const { coords } = position
      const { latitude, longitude } = coords

      setCurrentLocation({ lat: latitude, lng: longitude })
    })
  }, [])

  return (
    <NodePositioningModal
      onSubmit={(coordinates: google.maps.LatLngLiteral) => {
        if (!coordinates) return

        installationWorkflowStore.actions.setDropInstallLocation(coordinates)

        installationWorkflowStore.actions.start()

        openNodeInstallationOverviewModal()

        closeModal(MODAL_ID)
      }}
      onClose={() => {
        closeModal(MODAL_ID)
      }}
      mapDefaultCenter={currentLocation || mapCenter}
      mapDefaultZoom={mapZoom}
      headerTitle={translate.phrases.placeholder('Confirm Location')}
      showBanner
    />
  )
}

export default DropInstallModal
