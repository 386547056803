import React from 'react'
import { openModal, closeModal } from '@mantine/modals'
import { ErrorBoundary } from '@sentry/react'
import NodePositioningModal from '../NodePositioningModal'
import { notifications } from '@mantine/notifications'
import { TActiveGateway, TActiveNode, TPlannedGateway, TPlannedNode } from '../types'
import { GeoJSONPosition, stringify } from 'wellknown'
import { repositionPlannedGateway } from './repositionPlannedGateway'
import { repositionPlannedNodes } from './repositionPlannedNodes'
import { IconWarning } from 'components/icons/IconWarning'
import { isPlannedNode } from './isPlannedNode'
import { repositionActiveNode } from './repositionActiveNode'
import { repositionActiveGateway } from './repositionActiveGateway'

const MODAL_ID = 'reposition-node-modal'

export function openNodeRepositionModal(node: TActiveNode | TActiveGateway | TPlannedNode | TPlannedGateway) {
  const selectedNodeLocation = JSON.parse(node.location)

  const mapDefaultCenter = {
    lat: selectedNodeLocation.coordinates[1],
    lng: selectedNodeLocation.coordinates[0],
  }

  const handleSubmit = async (mapCenter: google.maps.LatLngLiteral | undefined) => {
    if (!mapCenter) return

    const { lat, lng } = mapCenter
    const coordinates = [lng, lat] as GeoJSONPosition

    const stringLocation = stringify({
      type: 'Point',
      coordinates,
    })

    try {
      if (isPlannedNode(node)) {
        if ((node as TPlannedNode).nodeType.includes('gtwy') && (node as TPlannedNode)?.pseudogatewayId) {
          const params = {
            pseudogatewayId: (node as TPlannedNode).pseudogatewayId as number,
            location: stringLocation,
          }

          await repositionPlannedGateway(params)
        } else {
          const params = { [(node as TPlannedNode).id]: stringLocation }

          await repositionPlannedNodes(params)
        }
      } else if ((node as TActiveGateway).gatewayType && (node as TActiveGateway).gatewayIdentifier) {
        const params = {
          gatewayIdentifier: (node as TActiveGateway).gatewayIdentifier,
          location: stringLocation,
          propertyId: (node as TActiveGateway).propertyIds[0],
        }

        await repositionActiveGateway(params)
      } else {
        const params = {
          nodeIdentifier: (node as TActiveNode).nodeIdentifier,
          location: stringLocation,
          propertyId: (node as TActiveNode).propertyId,
        }

        await repositionActiveNode(params)
      }

      closeModal(MODAL_ID)

      notifications.show({ title: 'Success', message: 'Device has been successfully repositioned' })
    } catch (e) {
      notifications.show({
        id: 'reposition-error',
        withCloseButton: true,
        icon: <IconWarning />,
        autoClose: 3000,
        title: 'Error',
        message: (e as Error).message,
      })
    }
  }

  openModal({
    modalId: MODAL_ID,
    fullScreen: true,
    withCloseButton: false,
    padding: 0,
    children: (
      <ErrorBoundary>
        <NodePositioningModal
          showDefaultLocationMarker
          mapDefaultCenter={mapDefaultCenter}
          onSubmit={handleSubmit}
          onClose={() => closeModal(MODAL_ID)}
          disableSubmitButtonOnDefaultLocation
        />
      </ErrorBoundary>
    ),
    styles: {
      content: {
        marginLeft: 'env(safe-area-inset-left)',
        marginRight: 'env(safe-area-inset-right)',
        boxShadow: 'none',
        transform: 'none !important',
      },
    },
  })
}
