import React, { useMemo } from 'react'
import { useMantineTheme } from '@mantine/core'
import moment from 'moment-timezone'
import { translate } from 'i18n/i18n'
import { TActiveNode, TNodeDevices, TNodeServiceIssueSummary, TServiceIssueStatus } from '../../types'
import { getServiceIssueStatusLabel } from '../../utils/getServiceIssueTitle'
import { ToggleSymbolValue } from '../../ToggleSymbolValue/ToggleSymbolValue'
import { Button } from 'components/Button/Button'
import { IconRightArrowCircle } from 'components/icons/IconRightArrowCircle'
import { getServiceIssueLabelIcon } from '../List/List'
import { renderNodeDeviceLabel } from '../../utils/renderNodeDeviceLabel'
import { fieldAssetStore } from 'stores/fieldAssetStore'
import { checkHasIssueUpdatePermission } from '../NodeMaintenanceModal'

interface IssueCardProps {
  issue: TNodeServiceIssueSummary
  node: TActiveNode

  onSelectIssue(): void
}

export const IssueCard: React.FC<IssueCardProps> = ({ issue, node, onSelectIssue }) => {
  const theme = useMantineTheme()
  const allDevices = fieldAssetStore.useSelector((s) => s.devices)

  let buttonLabel = translate.phrases.placeholder('View Issue')

  if (
    checkHasIssueUpdatePermission() &&
    (issue.serviceIssueStatus === TServiceIssueStatus.CREATED ||
      issue.serviceIssueStatus === TServiceIssueStatus.FLAGGED_FOR_SERVICE)
  ) {
    buttonLabel = translate.phrases.placeholder('Resolve Issue')
  }

  const selectedDevice = useMemo(() => {
    if (!issue.device) return null

    // Extract device source, connector, and channel (eg. 'SENTEK_DDT | SDI | 1' -> [SENTEK_DDT, SDI, 1])
    const [deviceSource, deviceConnector, deviceChannel] = issue.device.split('|').map((item) => item.trim())
    // Find a matching device in the node's devices
    const nodeDevice = node.devices?.[deviceConnector.toLowerCase() as keyof TNodeDevices]?.[+deviceChannel]

    if (nodeDevice && nodeDevice.source === deviceSource) {
      return {
        ...nodeDevice,
        connector: deviceConnector,
      }
    }

    // If no matching device is found, return the extracted device info
    return {
      source: deviceSource,
      connector: deviceConnector,
      channel: deviceChannel,
    }
  }, [issue.device, node])

  return (
    <div style={{ marginBottom: 20 }}>
      <div className="status" style={{ backgroundColor: theme.colors.grey[1], padding: '8px 10px' }}>
        <span
          style={{
            fontSize: '20px',
            lineHeight: '20px',
            marginRight: 10,
            height: 20,
            display: 'inline-block',
          }}
        >
          {getServiceIssueLabelIcon(issue.serviceIssueStatus)}
        </span>
        <span
          className="label"
          style={{ fontSize: '14px', lineHeight: '14px', display: 'inline-block', verticalAlign: 'text-top' }}
        >
          {getServiceIssueStatusLabel(issue.serviceIssueStatus)}
        </span>
      </div>

      <div style={{ backgroundColor: theme.colors.grey[0], padding: 10 }}>
        <div style={{ fontSize: '12px', color: theme.colors.grey[3], marginBottom: 10 }}>
          <ToggleSymbolValue value={moment.tz(issue.reportedOn, moment.tz.guess()).format('lll z')}>
            {moment.tz(issue.reportedOn, 'UTC').fromNow()}
          </ToggleSymbolValue>
        </div>

        <div css={{ display: 'flex', justifyContent: 'space-between', marginBottom: 10 }}>
          <span css={{ fontWeight: 'bold' }}>{translate.phrases.placeholder('Reported Issue')}</span>
          <span css={{ textAlign: 'right' }}>{issue.nodeServiceIssueTypeTitle}</span>
        </div>

        {!!selectedDevice && (
          <div
            css={{
              display: 'flex',
              justifyContent: 'space-between',
              marginBottom: 10,
            }}
          >
            <span css={{ fontWeight: 'bold' }}>{translate.phrases.placeholder('Device')}</span>
            <span css={{ textAlign: 'right' }}>{renderNodeDeviceLabel(selectedDevice, allDevices)}</span>
          </div>
        )}

        {/*<div>*/}
        {/*  {translate.phrases.placeholder('Quick Status')}:{' '}*/}
        {/*  <Badge*/}
        {/*    style={{*/}
        {/*      color: theme.colors.white[0],*/}
        {/*      backgroundColor: theme.colors.red[0],*/}
        {/*    }}*/}
        {/*  >*/}
        {/*    {translate.phrases.placeholder('Unresolved')}*/}
        {/*  </Badge>*/}
        {/*</div>*/}

        <div
          style={{
            paddingTop: 10,
            marginTop: 10,
            borderTop: '1px solid',
            borderColor: theme.colors.grey[1],
          }}
        >
          <Button
            variant="secondary"
            rightIcon={<IconRightArrowCircle />}
            onClick={onSelectIssue}
            style={{ width: '100%' }}
          >
            {buttonLabel}
          </Button>
        </div>
      </div>
    </div>
  )
}
