import { routes } from '@semios/app-platform-banyan-route-definitions'
import { TLanguage } from '@semios/app-platform-i18n-core'
import { AlertTypeId, Rule } from 'App/Map/UserSettingsMenu/Shared/Alerts/settings/alertSettings'
import { persistentInit } from 'stores/_utils/persistentStore/persistentStore'
import { ImperialXorMetric } from 'stores/fieldAssetStore'

export type GroupContact = {
  id: string
  name: string
  email: string
  sms: string
}

export type AlertContactMethod = 'email' | 'sms'

export type ReportContactMethod = 'email' | null

export type Contact = GroupContact & {
  groups: {
    id: string
    name: string
  }[]
  language: TLanguage
  alertsContactMethods: AlertContactMethod[] | null
  reportsContactMethods: ReportContactMethod[] | null
}

export type Group = {
  id: string
  name: string
  contacts: GroupContact[]
  alertsContactMethods: AlertContactMethod[] | null
  reportsContactMethods: ReportContactMethod[] | null
}

export type Alert = {
  id: string
  name: string
  type: {
    id: AlertTypeId
  }
  rules: Rule[]
  properties: {
    id: string
  }[]
  blocks: {
    id: string
  }[]
  stations: {
    geom: string
  }[]
  contacts: Contact[]
  groups: Group[]
  startDate: string
  endDate: string
  createdAt: string
  updatedAt: string
  deletedAt: string
  insect: {
    id: string
  }
  notifyOwnerByEmail: boolean
  notifyOwnerBySMS: boolean
  snoozeUntil: string
  snoozeAllUntil: string
  sleepMinutes: number
  frequencyMinutes: number | null
  repeatAnnually: boolean
}

export type Report = {
  weeklyGeneral?: {
    id: string
    name: string
    notifyOwnerByEmail: boolean
    contacts: Contact[]
    groups: Group[]
    rules: {
      dayOfWeek: string
      properties: {
        id: string
      }[]
      weather: {
        startDate: string | null
        endDate: string | null
      }
      fireBlight: {
        startDate: string | null
        endDate: string | null
      }
      pollination: {
        startDate: string | null
        endDate: string | null
      }
      growingDegreeDays: {
        startDate: string | null
        endDate: string | null
      }
      evapotranspiration: {
        startDate: string | null
        endDate: string | null
      }
      irrigationActivity: {
        startDate: string | null
        endDate: string | null
      }
      irrigationPlanner: {
        startDate: string | null
        endDate: string | null
      }
      soilMoisture: {
        startDate: string | null
        endDate: string | null
      }
      chill: {
        startDate: string | null
        endDate: string | null
      }
      scout: {
        startDate: string | null
        endDate: string | null
      }
      pests: {
        insect: {
          id: string
          name: string
        }
        startDate: string | null
        endDate: string | null
        trapCatches: string
        degreeDays: string
      }
    }
  }[]
  weeklyTrapCatches?: {
    id: string
    name: string
    notifyOwnerByEmail: boolean
    contacts: Contact[]
    groups: Group[]
    rules: {
      dayOfWeek: string
      properties: {
        id: string
      }[]
      startDate: string
      endDate: string
      pests: {
        insect: string
        startDate: string | null
        endDate: string | null
      }[]
    }
  }[]
} | null

export const storeName = 'userDetailsStore'

export const userDetailsStore = persistentInit<TUserDetailsStoreState>({
  initialState: {
    accountType: null,
    availableSemiosDefinedPresets: null,
    availableUserDefinedPresets: null,
    depth: 'METRIC',
    email: '',
    firstName: '',
    id: null,
    intercomToken: null,
    language: '',
    lastName: '',
    permissions: {},
    rain: 'METRIC',
    smsNumber: null,
    speed: 'METRIC',
    temperature: 'METRIC',
    pressure: 'METRIC',
    contacts: [],
    groups: [],
    alerts: [],
    reports: {},
    hasAgreedToCurrentAcceptancePackage: false,
    isFreeRegionalUser: false,
    location: undefined,
  },
  keysToPutInPersistentStorage: {
    accountType: true,
    availableSemiosDefinedPresets: true,
    availableUserDefinedPresets: true,
    depth: true,
    email: true,
    firstName: true,
    id: true,
    intercomToken: true,
    language: true,
    lastName: true,
    permissions: true,
    rain: true,
    smsNumber: true,
    speed: true,
    temperature: true,
    pressure: true,
    contacts: false,
    groups: false,
    alerts: false,
    reports: false,
    hasAgreedToCurrentAcceptancePackage: true,
    isFreeRegionalUser: false,
    location: false,
  },
  keysToPutInURL: {
    accountType: false,
    availableSemiosDefinedPresets: false,
    availableUserDefinedPresets: false,
    depth: false,
    email: false,
    firstName: false,
    id: false,
    intercomToken: false,
    language: false,
    lastName: false,
    permissions: false,
    rain: false,
    smsNumber: false,
    speed: false,
    temperature: false,
    pressure: false,
    contacts: false,
    groups: false,
    alerts: false,
    reports: false,
    hasAgreedToCurrentAcceptancePackage: false,
    isFreeRegionalUser: false,
    location: false,
  },
  storeName,
})

export type TUserDetailsStoreState = {
  id: number | null
  firstName: string
  lastName: string
  email: string
  smsNumber: string | null
  speed: ImperialXorMetric
  temperature: ImperialXorMetric
  depth: ImperialXorMetric
  rain: ImperialXorMetric
  pressure: ImperialXorMetric
  language: routes.UserAppStartup.Response['user']['language'] | ''
  accountType: routes.UserAppStartup.Response['user']['accountType'] | null
  permissions: routes.UserAppStartup.Response['user']['permissions']
  availableSemiosDefinedPresets: routes.UserAppStartup.Response['user']['availableSemiosDefinedPresets']
  availableUserDefinedPresets: routes.UserAppStartup.Response['user']['availableUserDefinedPresets']
  intercomToken: routes.UserAppStartup.Response['user']['intercomToken']
  contacts: Contact[]
  groups: Group[]
  alerts: Alert[]
  reports: Report
  hasAgreedToCurrentAcceptancePackage: boolean
  isFreeRegionalUser: boolean
  location: routes.UserAppStartup.Response['user']['location']
}
