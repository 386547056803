import { isNil } from 'lodash'
import { TTooltipFormatterContextObject } from '../../types'
import { getFixedValueOrUnknown } from './getFixedValueOrUnknown'
import { minAverageMaxCellMaker } from './minAverageMaxCellMaker'

export const buildTooltipValueRowsForTooltip = (points: TTooltipFormatterContextObject[]) => {
  let template = ''

  points.forEach(({ point, series }) => {
    if (series.userOptions.hideFromTooltip === true) return

    const valueDecimals = series.userOptions.tooltip?.valueDecimals

    const valueSuffix =
      (!isNil(point.y) || !isNil(point?.compareSeasons?.y)) && series.userOptions.tooltip?.valueSuffix
        ? series.userOptions.tooltip?.valueSuffix
        : ''

    template += `
  <tr>
    ${minAverageMaxCellMaker({
      seriesId: String(series.userOptions.id),
      low: point.low !== undefined ? getFixedValueOrUnknown(point.low, valueDecimals) : null,
      high: point.low !== undefined ? getFixedValueOrUnknown(point.high, valueDecimals) : null,
      value: getFixedValueOrUnknown(point.y, valueDecimals),
      seriesColor: series.color,
      seriesName: series.name,
      dashed: series.userOptions.tooltip?.dashed,
      xYear: `${new Date(point.x).getFullYear()}`,
      valueSuffix,
      compareSeasons: point.compareSeasons?.year
        ? {
            year: point.compareSeasons?.year,
            low:
              point.compareSeasons?.point?.low !== undefined
                ? getFixedValueOrUnknown(point.compareSeasons.point.low, valueDecimals)
                : null,
            high:
              point.compareSeasons?.point?.high !== undefined
                ? getFixedValueOrUnknown(point.compareSeasons.point.high, valueDecimals)
                : null,
            value: getFixedValueOrUnknown(point.compareSeasons.y, valueDecimals),
            seriesColor: point.compareSeasons?.color,
          }
        : undefined,
    })}
  </tr>
  `
  })

  return template
}
