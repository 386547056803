import { Tooltip, TooltipFormatterContextObject } from 'highcharts'
import { translate } from 'i18n/i18n'
import moment from 'moment-timezone'
import { detailsPanelStore } from 'stores/detailsPanelStore'
import { TChartSeries } from '../../types'
import { buildTooltipDualColumnValueRowsForTooltip } from './buildTooltipDualColumnValueRowsForTooltip'
import { buildTooltipPlotLinesIfAny } from './buildTooltipPlotLinesIfAny'
import { buildTooltipValueRowsForTooltip } from './buildTooltipValueRowsForTooltip'

export function lineChartTooltipFormatter(
  ctx: TooltipFormatterContextObject,
  tooltip: Tooltip,
  firstForecastTimestamp: number,
  filterSeries?: (s: { userOptions: { id: string } }) => boolean,
) {
  // @ts-ignore
  const timezone = tooltip?.chart?.time?.options?.timezone ?? 'America/Los_Angeles'
  const seriesByIds: Record<string, TChartSeries> = {}
  // @ts-ignore
  const chartSeries = filterSeries ? tooltip.chart.series.filter(filterSeries) : tooltip.chart.series

  chartSeries.forEach((s) => {
    seriesByIds[String(s.options.id)] = s as unknown as TChartSeries
  })

  const pointsBySeriesId: Record<string, NonNullable<TooltipFormatterContextObject['points']>[number]> = {}

  ctx.points?.forEach((p) => {
    if (p.series.options.id) {
      pointsBySeriesId[p.series.options.id] = p
    }
  })

  /**
   * the ctx.points only shows the non-null data-points. We want to show the
   * null points as well, so we can iterate through all the series we normally
   * want to show in the tooltip, and if they don't already have a found point
   * from the more efficient ctx.points, we can search through the data for the
   * same x value and use that point. Note that if all points are null, the
   * tooltip won't show at all because of an internal bug in highcharts. See
   * comment in syncCrosshairsAndTooltips for where the resulting bug happens
   */
  chartSeries.forEach((s) => {
    // @ts-ignore
    if (!pointsBySeriesId[s.options.id] && !s.userOptions.hideFromTooltip) {
      const foundPoint = s.data.find((d) => d.x === ctx.x)

      // @ts-ignore
      if (foundPoint) pointsBySeriesId[s.options.id] = foundPoint
    }
  })

  const { compareSeasonsInterval } = detailsPanelStore.getState()

  // calling defaultFormatter will generate what we would've got from headerFormat, pointFormat, and footerFormat
  let template = tooltip.defaultFormatter.call(ctx, tooltip)[0]

  if (!ctx.x || !ctx.points) return template

  try {
    const pointsWithNullPointsIncluded = []

    for (const series of chartSeries) {
      // @ts-ignore
      if (!series?.userOptions?.hideFromTooltip && !!series.visible) {
        const seriesId = String(series.options.id)
        const point = pointsBySeriesId[seriesId]
        const hasCorrelateCompareSeasonsSeries = !!seriesByIds[`${seriesId}_compareSeasons`]

        const pointWithNullPointsIncluded = {
          point: {
            high: point?.point?.high,
            low: point?.point?.low,
            x: ctx.x,
            y: point?.y,
            compareSeasons: hasCorrelateCompareSeasonsSeries
              ? {
                  ...pointsBySeriesId[`${seriesId}_compareSeasons`],
                  year: moment.tz(ctx.x, timezone).subtract(compareSeasonsInterval, 'years').year(),
                  color: seriesByIds[`${seriesId}_compareSeasons`]?.color,
                }
              : undefined,
            color: point?.series?.color,
          },
          series: {
            color: series?.color ?? 'rgba(255, 0, 0, 1)',
            name: series?.name,
            userOptions: series?.userOptions ?? {},
          },
        }

        pointsWithNullPointsIncluded.push(pointWithNullPointsIncluded)
      }
    }

    const numberOfRows = pointsWithNullPointsIncluded.length

    if (numberOfRows < 7) {
      // @ts-ignore
      const valueRows = buildTooltipValueRowsForTooltip(pointsWithNullPointsIncluded)

      template += valueRows
    } else {
      // @ts-ignore
      const dualColumnValueRows = buildTooltipDualColumnValueRowsForTooltip(pointsWithNullPointsIncluded)

      template += dualColumnValueRows
    }

    const plotLinesIfAny = buildTooltipPlotLinesIfAny(ctx)

    template += plotLinesIfAny

    template += '</table>'
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(err)
    // TODO: errorLogger
  }

  if (!compareSeasonsInterval && Number(ctx.x) >= firstForecastTimestamp) {
    // the next little replace pattern is just an easy place to jack into the headerFormat
    return template
      .split('</strong><table')
      .join(` (${translate.phrases.banyanApp('Forecast')})</strong><table`)
  }

  return template
}
