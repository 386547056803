import { apiFetch } from 'utils/apiFetch'
import { routes } from '@semios/app-platform-banyan-route-definitions'

export type NodeServiceIssueResolutionActionsAddMeta =
  routes.ServiceCenterNodeServiceIssueResolutionActionsAdd.Request

export const request = async (meta: NodeServiceIssueResolutionActionsAddMeta) => {
  return await apiFetch<
    routes.ServiceCenterNodeServiceIssueResolutionActionsAdd.Request,
    routes.ServiceCenterNodeServiceIssueResolutionActionsAdd.Response
  >({
    url: routes.ServiceCenterNodeServiceIssueResolutionActionsAdd.path,
    body: meta,
    params: {
      method: 'POST',
    },
  })
}

export const serviceCenterNodeServiceIssueResolutionActionsAdd = {
  request,
}
