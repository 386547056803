import { Badge, Flex, CloseButton, ActionIcon } from '@mantine/core'
import { routes } from '@semios/app-platform-banyan-route-definitions'
import { APP_PLATFORM_VERSION } from '@semios/app-platform-version'
import { IconAlma } from './IconAlma'
import { IconThumbDown } from './IconThumbDown'
import { IconThumbUp } from './IconThumbUp'
import { translate } from 'i18n/i18n'
import { isEmpty } from 'lodash'
import { useEffect, useState } from 'react'
import { colors } from 'settings/colors'
import { feedbackEventStore, TFeedbackEventStore } from 'stores/feedbackEventStore'
import { selectedValueGroupsStore } from 'stores/selectedValueGroupsStore/selectedValueGroupsStore'
import { apiFetch } from 'utils/apiFetch'
import { useApiREST } from 'utils/useApiREST'
import { IconThumbDownInverse } from './IconThumbDownInverse'
import { IconThumbUpInverse } from './IconThumbUpInverse'

export type TAlmaSummaryProps<TSummaryTemplate> = {
  queryParams: routes.ValuesCurrent.Request
  renderFunction: (summaryTemplate: TSummaryTemplate) => React.ReactNode
  getStatementId: (summaryTemplate: TSummaryTemplate) => string | undefined
  context?: string
}

const HoverIcon = (props: { onMouseEnter: () => void; onMouseLeave: () => void }) => {
  return <IconAlma {...props} />
}

const logFeedbackEvent = (params: routes.LogSidekickFeedback.Request) => {
  apiFetch({
    url: routes.LogSidekickFeedback.path,
    body: params,
  })
}

export const AlmaSummary = <TSummaryTemplate,>(props: TAlmaSummaryProps<TSummaryTemplate>) => {
  const { data, loading } = useApiREST({
    body: props.queryParams,
    url: routes.ValuesCurrent.path,
    preventFetch: isEmpty(props.queryParams),
    watchers: [JSON.stringify(props.queryParams)],
    initialState: null,
  })

  const [showHoverInfo, setShowHoverInfo] = useState(false)
  const [showSummary, setShowSummary] = useState(false)
  const [statementId, setStatementId] = useState<string | undefined>()
  const [renderedStatement, setRenderedStatement] = useState<React.ReactNode | null>(null)

  useEffect(() => {
    if (data) {
      setStatementId(props.getStatementId(data))

      setRenderedStatement(props.renderFunction(data))
    }
  }, [loading, data])

  const { feedbackType } = feedbackEventStore.useSelector((state) => {
    const viewed = statementId ? state.views.has(statementId) : false
    const feedbackType = statementId ? state.feedback[statementId] : undefined

    return { viewed, feedbackType }
  })

  const feedbackMeta = {
    appName: import.meta.env.VITE_APP_NAME,
    appVersion: APP_PLATFORM_VERSION,
    appContext: props.context,
    selectedValueGroups: Object.keys(selectedValueGroupsStore.getState().selectedValueGroups ?? {}),
  }

  const handleView = () => {
    if (statementId) {
      logFeedbackEvent({
        statementId,
        meta: feedbackMeta,
        feedbackType: 'view',
      })

      feedbackEventStore.setState((state) => {
        return {
          ...state,
          views: new Set([...state.views, statementId]),
        }
      })
    }
  }

  const handleFeedback = (feedbackType: TFeedbackEventStore['feedback'][string] | null) => {
    if (statementId) {
      logFeedbackEvent({
        statementId,
        meta: feedbackMeta,
        feedbackType,
      })

      feedbackEventStore.setState((state) => {
        return {
          ...state,
          feedback: {
            ...state.feedback,
            [statementId]: feedbackType,
          },
        }
      })
    }
  }

  if (showSummary && data && renderedStatement && !loading) {
    return (
      <div
        css={{
          background: colors.almaBackground,
          margin: 10,
          padding: 10,
          fontSize: 14,
          textAlign: 'left',
          borderRadius: 3,
        }}
      >
        <Flex direction="row" align="center" justify="space-between">
          <Flex direction="row" align="center" justify="flex-start" gap="sm">
            <IconAlma />
            <span>{translate.phrases.banyanApp('Summary')}</span>
          </Flex>
          <CloseButton
            onClick={() => setShowSummary(false)}
            size="lg"
            variant="transparent"
            css={{ color: colors.grey800 }}
          />
        </Flex>
        <div css={{ margin: 20, lineHeight: '1.5em' }}>{renderedStatement}</div>
        <Flex direction="row" align="center" justify="flex-start" gap="sm" css={{ marginLeft: 20 }}>
          <ActionIcon
            variant="subtle"
            css={{ 'borderRadius': '50%', ':hover': { backgroundColor: colors.grey50 }, 'fontSize': 24 }}
            onClick={() => {
              if (feedbackType === 'like') {
                handleFeedback(null)
              } else {
                handleFeedback('like')
              }
            }}
          >
            {feedbackType === 'like' ? <IconThumbUpInverse /> : <IconThumbUp />}
          </ActionIcon>
          <ActionIcon
            variant="subtle"
            css={{
              'borderRadius': '50%',
              ':hover': { backgroundColor: colors.grey50 },
              'fontSize': 24,
            }}
            onClick={() => {
              if (feedbackType === 'dislike') {
                handleFeedback(null)
              } else {
                handleFeedback('dislike')
              }
            }}
          >
            {feedbackType === 'dislike' ? <IconThumbDownInverse /> : <IconThumbDown />}
          </ActionIcon>
        </Flex>
      </div>
    )
  }

  if (data && renderedStatement && !loading) {
    return (
      <Badge
        variant="outline"
        size="xl"
        rightSection={
          <HoverIcon
            onMouseEnter={() => setShowHoverInfo(true)}
            onMouseLeave={() => setShowHoverInfo(false)}
          />
        }
        onClick={() => {
          setShowSummary(true)

          handleView()
        }}
        css={{
          'paddingRight': 0,
          'marginRight': 5,
          'marginTop': 5,
          'color': colors.almaDark,
          'borderColor': showHoverInfo ? colors.almaDark : 'transparent',
          'fontWeight': 500,
          'textTransform': 'unset',
          'fontSize': 14,
          '.mantine-Badge-rightSection': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            margin: 0,
          },
        }}
      >
        {showHoverInfo && <span css={{ marginRight: 2 }}>{translate.phrases.banyanApp('View Summary')}</span>}
      </Badge>
    )
  }

  return null
}
