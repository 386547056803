import { ApiRequestQueue, ApiRequestType } from './api/queue'
import { OfflineRequestError } from '../../../utils/errorCodes'
import { NodeServiceIssueStatusUpdateMeta } from './api/serviceCenterNodeServiceIssueStatusUpdate'
import { TServiceIssueStatus } from '../types'

export async function updateNodeServiceIssueStatus(serviceIssueId: number, status: TServiceIssueStatus) {
  try {
    // Request may or may not have been completed by the time the promise resolves
    await ApiRequestQueue.createRequest(ApiRequestType.UPDATE_SERVICE_ISSUE_STATUS, {
      serviceIssueId,
      status,
    } as NodeServiceIssueStatusUpdateMeta)
  } catch (error) {
    // If the request failed because the app is offline, don't throw an error
    if (!(error instanceof OfflineRequestError)) {
      // TODO: Return a user-friendly error message
      throw error
    }
  }
}
