import {
  TPermission,
  VIEW_PESTS_DEGREE_DAYS_ID_insectId,
  VIEW_PESTS_LARVAL_TREND_ID_insectId,
  VIEW_PESTS_TRAP_CATCHES_ID_insectId,
} from '@semios/app-platform-value-type-definitions'
import { translate } from 'i18n/i18n'
import type { ReactNode } from 'react'
import {
  TLayer,
  TSection,
  TValueGroup,
  valueGroupToSectionDictionary,
} from 'stores/selectedValueGroupsStore/selectedValueGroupsStore'
import { userDetailsStore } from 'stores/userDetailsStore'
import { checkAuthorization } from 'utils/checkAuthorization'
import { getInsectNameFromId } from 'utils/getInsectNameFromId'
import { toggleValueGroups } from '../../../../../stores/selectedValueGroupsStore/toggleValueGroups'
import { ComingSoonItem } from './ComingSoonItem/ComingSoonItem'
import { RecentlyAddedItem } from './RecentlyAddedItem/RecentlyAddedItem'
import { isUserOnlyAFreeRegionalUser } from 'utils/isUserOnlyAFreeRegionalUser'

export type TNavigationKey =
  | Exclude<
      TLayer,
      | `degree_days_insect_id_${number}`
      | `trap_catches_insect_id_${number}`
      | `larval_trend_insect_id_${number}`
    >
  | TInsectNavigationItems

export type TNavigationMenuItem =
  | {
      childLayers?: Partial<Record<TLayer, { key: TLayer; translatedTitle: string }>>
      key: TNavigationKey
      label?: ReactNode
      section: TSection
      translatedTitle: string
    }
  | {
      childLayers?: Partial<Record<TLayer, { key: TLayer; translatedTitle: string }>>
      key: TNavigationKey
      label?: ReactNode
      onToggleOn: () => void
      onToggleOff: () => void
      section: TSection
      translatedTitle: string
    }

export const leadingInsectKey = 'insect_id_'

const makeValueGroupNavigationItem = ({
  label,
  permission,
  section,
  translatedTitle,
  valueGroup,
  hideIfOnlyRegionsHavePermission = false,
}: Omit<NonNullable<TNavigationMenuItem>, 'onToggleOn' | 'onToggleOff' | 'key'> & {
  permission: TPermission
  valueGroup: Exclude<
    TValueGroup,
    | `degree_days_insect_id_${number}`
    | `trap_catches_insect_id_${number}`
    | `larval_trend_insect_id_${number}`
  >
  hideIfOnlyRegionsHavePermission?: boolean
}): TNavigationMenuItem | null => {
  if (
    !checkAuthorization({ entity: 'FOR_ANY_ENTITY', permission }, undefined, hideIfOnlyRegionsHavePermission)
  )
    return null

  return {
    key: valueGroup,
    label,
    onToggleOn: () => toggleValueGroups([valueGroup], true),
    onToggleOff: () => toggleValueGroups([valueGroup], false),
    section,
    translatedTitle,
  }
}

const makeInsectNavigationItem = (insectId: number): TNavigationMenuItem | null => {
  const userPermissions = userDetailsStore.getState().permissions
  const degreeDaysPermission = VIEW_PESTS_DEGREE_DAYS_ID_insectId(insectId)
  const trapCatchesPermission = VIEW_PESTS_TRAP_CATCHES_ID_insectId(insectId)
  const larvalTrendPermission = VIEW_PESTS_LARVAL_TREND_ID_insectId(insectId)
  const hideIfOnlyRegionsHavePermission = !isUserOnlyAFreeRegionalUser()

  const hasDegreeDaysPermission = checkAuthorization(
    { entity: 'FOR_ANY_ENTITY', permission: degreeDaysPermission },
    userPermissions,
    hideIfOnlyRegionsHavePermission,
  )

  const hasTrapCatchesPermission = checkAuthorization(
    { entity: 'FOR_ANY_ENTITY', permission: trapCatchesPermission },
    userPermissions,
    hideIfOnlyRegionsHavePermission,
  )

  const hasLarvalTrendPermission = checkAuthorization(
    { entity: 'FOR_ANY_ENTITY', permission: larvalTrendPermission },
    userPermissions,
    hideIfOnlyRegionsHavePermission,
  )

  if (!hasDegreeDaysPermission && !hasTrapCatchesPermission && !hasLarvalTrendPermission) return null

  const degreeDaysLayer = `degree_days_insect_id_${insectId}` as TValueGroup
  const trapCatchesLayer = `trap_catches_insect_id_${insectId}` as TValueGroup
  const larvalTrendLayer = `larval_trend_insect_id_${insectId}` as TValueGroup
  const childLayers: Partial<Record<TLayer, NonNullable<TNavigationMenuItem['childLayers']>[TLayer]>> = {}
  const insectName = getInsectNameFromId(insectId)

  if (hasDegreeDaysPermission) {
    childLayers[degreeDaysLayer as TLayer] = {
      key: degreeDaysLayer,
      translatedTitle: translate.phrases.banyanApp('{{insectName}} Degree Days', { insectName }),
    }
  }

  if (hasTrapCatchesPermission) {
    childLayers[trapCatchesLayer as TLayer] = {
      key: trapCatchesLayer,
      translatedTitle: translate.phrases.banyanApp('{{insectName}} Trap Catches', { insectName }),
    }
  }

  if (hasLarvalTrendPermission) {
    childLayers[larvalTrendLayer as TLayer] = {
      key: larvalTrendLayer,
      translatedTitle: translate.phrases.banyanApp('{{insectName}} Larval Trend', { insectName }),
    }
  }

  return {
    childLayers,
    key: `${leadingInsectKey}${insectId}` as TNavigationKey,
    onToggleOn: () => {
      const selectedLayers: TValueGroup[] = []

      if (hasDegreeDaysPermission) selectedLayers.push(degreeDaysLayer)

      if (hasTrapCatchesPermission) selectedLayers.push(trapCatchesLayer)

      if (hasLarvalTrendPermission) selectedLayers.push(larvalTrendLayer)

      toggleValueGroups(selectedLayers, true)
    },
    onToggleOff: () => toggleValueGroups([degreeDaysLayer, trapCatchesLayer, larvalTrendLayer], false),
    section: 'insects',
    translatedTitle: getInsectNameFromId(insectId),
  }
}

type TInsectNavigationItems =
  | 'insect_id_1'
  | 'insect_id_2'
  | 'insect_id_3'
  | 'insect_id_4'
  | 'insect_id_6'
  | 'insect_id_7'
  | 'insect_id_8'
  | 'insect_id_9'
  | 'insect_id_10'
  | 'insect_id_11'
  | 'insect_id_12'
  | 'insect_id_13'
  | 'insect_id_14'
  | 'insect_id_15'
  | 'insect_id_16'
  | 'insect_id_17'
  | 'insect_id_18'
  | 'insect_id_19'
  | 'insect_id_20'
  | 'insect_id_23'
  | 'insect_id_24'
  | 'insect_id_25'
  | 'insect_id_26'
  | 'insect_id_27'
  | 'insect_id_28'
  | 'insect_id_29'
  | 'insect_id_30'

export const generateFlatNavigationList = (): Partial<Record<TNavigationKey, TNavigationMenuItem>> => {
  const menuIncludingNulls: Record<TNavigationKey, TNavigationMenuItem | null> = {
    irrigation_activity: makeValueGroupNavigationItem({
      label: <RecentlyAddedItem translatedTitle={translate.phrases.banyanApp('Irrigation Activity')} />,
      permission: 'VIEW_IRRIGATION_DETAILS',
      section: valueGroupToSectionDictionary.irrigation_activity,
      translatedTitle: translate.phrases.banyanApp('Irrigation Activity'),
      valueGroup: 'irrigation_activity',
    }),
    evapotranspiration: makeValueGroupNavigationItem({
      label: <RecentlyAddedItem translatedTitle={translate.phrases.banyanApp('Evapotranspiration')} />,
      permission: 'VIEW_EVAPOTRANSPIRATION_DETAILS',
      section: valueGroupToSectionDictionary.evapotranspiration,
      translatedTitle: translate.phrases.banyanApp('Evapotranspiration'),
      valueGroup: 'evapotranspiration',
    }),
    wind_machine_activity: null,
    // environment
    air_temperature: makeValueGroupNavigationItem({
      permission: 'VIEW_WEATHER_DETAILS',
      section: valueGroupToSectionDictionary.air_temperature,
      translatedTitle: translate.phrases.banyanApp('Temperature'),
      valueGroup: 'air_temperature',
    }),
    atmospheric_pressure: null,
    conditions: makeValueGroupNavigationItem({
      permission: 'VIEW_WEATHER_DETAILS',
      section: valueGroupToSectionDictionary.conditions,
      translatedTitle: translate.phrases.banyanApp('Conditions'),
      valueGroup: 'conditions',
      hideIfOnlyRegionsHavePermission: true,
    }),
    bee_hours: makeValueGroupNavigationItem({
      permission: 'VIEW_BEE_HOURS_DETAILS',
      section: valueGroupToSectionDictionary.bee_hours,
      translatedTitle: translate.phrases.banyanApp('Bee Hours'),
      valueGroup: 'bee_hours',
    }),
    almond_bloom: makeValueGroupNavigationItem({
      permission: 'VIEW_BLOOM_PREDICTION',
      section: valueGroupToSectionDictionary.almond_bloom,
      translatedTitle: translate.phrases.banyanApp('Almond Bloom'),
      valueGroup: 'almond_bloom',
    }),
    almond_hull_split: makeValueGroupNavigationItem({
      permission: 'VIEW_ALMOND_HULL_SPLIT_SUMMARY',
      section: valueGroupToSectionDictionary.almond_bloom,
      translatedTitle: translate.phrases.banyanApp('Almond Hull Split'),
      valueGroup: 'almond_hull_split',
      label: <RecentlyAddedItem translatedTitle={translate.phrases.banyanApp('Almond Hull Split')} />,
    }),
    wet_bulb: makeValueGroupNavigationItem({
      permission: 'VIEW_WEATHER_DETAILS',
      section: valueGroupToSectionDictionary.wet_bulb,
      translatedTitle: translate.phrases.banyanApp('Wet Bulb'),
      valueGroup: 'wet_bulb',
    }),
    wind: makeValueGroupNavigationItem({
      permission: 'VIEW_WEATHER_DETAILS',
      section: valueGroupToSectionDictionary.wind,
      translatedTitle: translate.phrases.banyanApp('Wind'),
      valueGroup: 'wind',
    }),
    dew_point: makeValueGroupNavigationItem({
      permission: 'VIEW_WEATHER_DETAILS',
      section: valueGroupToSectionDictionary.dew_point,
      translatedTitle: translate.phrases.banyanApp('Dew Point'),
      valueGroup: 'dew_point',
    }),
    humidity: makeValueGroupNavigationItem({
      permission: 'VIEW_WEATHER_DETAILS',
      section: valueGroupToSectionDictionary.humidity,
      translatedTitle: translate.phrases.banyanApp('Humidity'),
      valueGroup: 'humidity',
    }),
    leaf_wetness: makeValueGroupNavigationItem({
      permission: 'IMPOSSIBLE_PERMISSION',
      section: valueGroupToSectionDictionary.leaf_wetness,
      translatedTitle: translate.phrases.banyanApp('Leaf Wetness'),
      valueGroup: 'leaf_wetness',
    }),
    precipitation: makeValueGroupNavigationItem({
      permission: 'VIEW_WEATHER_DETAILS',
      section: valueGroupToSectionDictionary.precipitation,
      translatedTitle: translate.phrases.banyanApp('Rain'),
      valueGroup: 'precipitation',
    }),
    // water management
    soil: makeValueGroupNavigationItem({
      permission: 'VIEW_SOIL_MOISTURE_DETAILS',
      section: valueGroupToSectionDictionary.soil,
      translatedTitle: translate.phrases.banyanApp('Soil'),
      valueGroup: 'soil',
    }),
    fruit_growth: makeValueGroupNavigationItem({
      label: <RecentlyAddedItem translatedTitle={translate.phrases.banyanApp('Fruit Growth')} />,
      permission: 'VIEW_FRUIT_GROWTH_DETAILS',
      section: valueGroupToSectionDictionary.fruit_growth,
      translatedTitle: translate.phrases.banyanApp('Fruit Growth'),
      valueGroup: 'fruit_growth',
    }),
    plant_stress: makeValueGroupNavigationItem({
      label: <RecentlyAddedItem translatedTitle={translate.phrases.banyanApp('Plant Stress')} />,
      permission: 'VIEW_PLANT_STRESS_DETAILS',
      section: valueGroupToSectionDictionary.plant_stress,
      translatedTitle: translate.phrases.banyanApp('Plant Stress'),
      valueGroup: 'plant_stress',
    }),
    // pests
    insect_id_1: makeInsectNavigationItem(1),
    insect_id_2: makeInsectNavigationItem(2),
    insect_id_3: makeInsectNavigationItem(3),
    insect_id_4: makeInsectNavigationItem(4),
    insect_id_6: makeInsectNavigationItem(6),
    insect_id_7: makeInsectNavigationItem(7),
    insect_id_8: makeInsectNavigationItem(8),
    insect_id_9: makeInsectNavigationItem(9),
    insect_id_10: makeInsectNavigationItem(10),
    insect_id_11: makeInsectNavigationItem(11),
    insect_id_12: makeInsectNavigationItem(12),
    insect_id_13: makeInsectNavigationItem(13),
    insect_id_14: makeInsectNavigationItem(14),
    insect_id_15: makeInsectNavigationItem(15),
    insect_id_16: makeInsectNavigationItem(16),
    insect_id_17: makeInsectNavigationItem(17),
    insect_id_18: makeInsectNavigationItem(18),
    insect_id_19: makeInsectNavigationItem(19),
    insect_id_20: makeInsectNavigationItem(20),
    insect_id_23: makeInsectNavigationItem(23),
    insect_id_24: makeInsectNavigationItem(24),
    insect_id_25: makeInsectNavigationItem(25),
    insect_id_26: makeInsectNavigationItem(26),
    insect_id_27: makeInsectNavigationItem(27),
    insect_id_28: makeInsectNavigationItem(28),
    insect_id_29: makeInsectNavigationItem(29),
    insect_id_30: makeInsectNavigationItem(30),
    // sprays
    spray_conditions: makeValueGroupNavigationItem({
      permission: 'VIEW_WEATHER_DETAILS', //Need to create permissions
      section: valueGroupToSectionDictionary.spray_conditions,
      translatedTitle: translate.phrases.banyanApp('Spray Conditions'),
      valueGroup: 'spray_conditions',
      hideIfOnlyRegionsHavePermission: true,
    }),
    // crop development
    chill: makeValueGroupNavigationItem({
      permission: 'VIEW_CHILL_DETAILS',
      section: valueGroupToSectionDictionary.chill,
      translatedTitle: translate.phrases.banyanApp('Chill'),
      valueGroup: 'chill',
    }),
    // diseases
    alternaria: makeValueGroupNavigationItem({
      permission: 'VIEW_ALTERNARIA_DETAILS',
      section: valueGroupToSectionDictionary.alternaria,
      label: <RecentlyAddedItem translatedTitle={translate.phrases.banyanApp('Alternaria')} />,
      translatedTitle: translate.phrases.banyanApp('Alternaria'),
      valueGroup: 'alternaria',
    }),
    walnut_blight: !checkAuthorization({ entity: 'FOR_ANY_ENTITY', permission: 'VIEW_WALNUT_BLIGHT_DETAILS' })
      ? null
      : {
          key: 'walnut_blight',
          label: <ComingSoonItem translatedTitle={translate.phrases.banyanApp('Walnut Blight')} />,
          section: 'diseases',
          translatedTitle: translate.phrases.banyanApp('Walnut Blight'),
        },
    wind_machine: makeValueGroupNavigationItem({
      permission: 'VIEW_FROST_FAN_DETAILS',
      section: valueGroupToSectionDictionary.equipment_status,
      translatedTitle: translate.phrases.banyanApp('Wind Machine'),
      valueGroup: 'wind_machine',
    }),
    equipment_status: makeValueGroupNavigationItem({
      permission: 'EDIT_SERVICE_CENTER',
      label: translate.phrases.placeholder('Equipment Status'),
      section: valueGroupToSectionDictionary.equipment_status,
      translatedTitle: translate.phrases.placeholder('Equipment Status'),
      valueGroup: 'equipment_status',
    }),
  }

  const menuitemsWithoutNulls: Partial<Record<TNavigationKey, TNavigationMenuItem>> = {}

  Object.entries(menuIncludingNulls).forEach(([key, value]) => {
    if (value) menuitemsWithoutNulls[key as TNavigationKey] = value
  })

  return menuitemsWithoutNulls
}
