import { TFieldAssetKeyTypes } from 'App/Map/types'
import { fieldAssetStore } from 'stores/fieldAssetStore'
import { selectedFieldAssetsStore } from 'stores/selectedFieldAssetsStore'

export const getTimezoneForSelectedPropertyOrRegion = (
  {
    properties,
    propertyId,
    regions,
    regionId,
  }: {
    properties?: ReturnType<typeof fieldAssetStore.getState>['properties']
    propertyId?: TFieldAssetKeyTypes.TPropertyId | null
    regions?: ReturnType<typeof fieldAssetStore.getState>['regions']
    regionId?: TFieldAssetKeyTypes.TRegionId | null
  } = {
    properties: fieldAssetStore.getState().properties,
    propertyId: selectedFieldAssetsStore.getState().property,
    regions: fieldAssetStore.getState().regions,
    regionId: selectedFieldAssetsStore.getState().region,
  },
) => {
  return (
    properties?.[propertyId as number]?.timezone ??
    regions?.[regionId as string]?.timezone ??
    'America/Los_Angeles'
  )
}
