import React from 'react'
import { translate } from 'i18n/i18n'
import { TNodeType, TSnNodeLog } from '../../../types'
import { renderWithDefaultValue } from '../../../utils/renderers'
import moment from 'moment-timezone'
import { SignalStrength } from './SignalStrength'
import { ToggleSymbolValue } from '../../../ToggleSymbolValue/ToggleSymbolValue'
import { getActiveNodeStatus, NODE_STATUS_COLORS } from '../../../Map/_utils/getActiveNodeStatus'
import { BatteryStrength } from './BatteryStrength'

interface SnNodeLogProps {
  log: TSnNodeLog | undefined
  nodeType: TNodeType
}

export const SnNodeLog: React.FC<SnNodeLogProps> = ({ log, nodeType }) => {
  const lastLogTimeAgoText = log ? (
    <ToggleSymbolValue value={moment.tz(log.stamp, moment.tz.guess()).format('lll z')}>
      {moment.tz(log.stamp, 'UTC').fromNow()}
    </ToggleSymbolValue>
  ) : (
    translate.phrases.placeholder('Never')
  )

  const nodeStatus = getActiveNodeStatus(log)
  const nodeStatusColor = NODE_STATUS_COLORS[nodeStatus]

  const lastPhotoText = log?.lastGoodPhoto ? (
    <ToggleSymbolValue value={moment.tz(log.lastGoodPhoto, moment.tz.guess()).format('lll z')}>
      {moment.tz(log.lastGoodPhoto, 'UTC').fromNow()}
    </ToggleSymbolValue>
  ) : (
    translate.phrases.placeholder('Never')
  )

  return (
    <dl
      css={{
        margin: 0,
        dt: {
          display: 'inline-block',
          width: '40%',
          lineHeight: 1.7,
          verticalAlign: 'top',
        },
        dd: {
          marginLeft: 0,
          display: 'inline-block',
          width: '60%',
          lineHeight: 1.7,
        },
      }}
    >
      <div className="row">
        <dt>{translate.phrases.placeholder('Last Log')}</dt>
        <dd css={{ color: nodeStatusColor, fontWeight: 500 }}>{lastLogTimeAgoText}</dd>
      </div>
      {!!log && (
        <>
          <div className="row">
            <dt>{translate.phrases.placeholder('Signal Strength')}</dt>
            <dd>
              <ToggleSymbolValue value={`${log.signal * -1} dBm`}>
                <SignalStrength signal={log.signal * -1} />
              </ToggleSymbolValue>
            </dd>
          </div>
          {!!log?.battery && (
            <div className="row">
              <dt>{translate.phrases.placeholder('Battery')}</dt>
              <dd>
                <ToggleSymbolValue value={`${log.battery}`}>
                  <BatteryStrength nodeType={nodeType} voltage={log.battery} />
                </ToggleSymbolValue>
              </dd>
            </div>
          )}

          {(nodeType === 'rpt' || nodeType === 'y') && (
            <>
              {!!log?.lithium && (
                <div className="row">
                  <dt>{translate.phrases.placeholder('Lithium')}</dt>
                  <dd>
                    <ToggleSymbolValue value={`${log.lithium}`}>
                      <BatteryStrength nodeType={nodeType} voltage={log.lithium} />
                    </ToggleSymbolValue>
                  </dd>
                </div>
              )}
              {!!log?.solar && (
                <div className="row">
                  <dt>{translate.phrases.placeholder('Solar')}</dt>
                  <dd>
                    <ToggleSymbolValue value={`${log.solar}`}>
                      <BatteryStrength nodeType={nodeType} voltage={log.solar} />
                    </ToggleSymbolValue>
                  </dd>
                </div>
              )}
            </>
          )}

          {['trp', 'dn_trp'].includes(nodeType) && (
            <>
              <div className="row">
                <dt>{translate.phrases.placeholder('Last Good Image')}</dt>
                <dd>{lastPhotoText}</dd>
              </div>

              <div className="row">
                <dt>{translate.phrases.placeholder('Image Count')}</dt>
                <dd>{renderWithDefaultValue(log.imageCount)}</dd>
              </div>
            </>
          )}
        </>
      )}
    </dl>
  )
}
