import { TNodeType } from 'App/ServiceCenter/types'

export const BATTERY_BARS_COLORS: { [batteryLevel: number]: string } = {
  // green
  0: '#29CD6B',
  1: '#29CD6B',
  // orange
  2: '#F5A623',
  // red
  3: '#EB4C4C',
}

export const BATTERY_THRESHOLDS: { [key in TNodeType]?: number[] } = {
  trp: [0, 2.6, 2.8, 2.9],
  scd: [0, 2.3, 2.7, 2.9],
  rpt: [0, 2.5, 2.9, 3.3],
  y: [0, 2.5, 2.9, 3.3],
  dn_trp: [0, 2.6, 2.8, 2.9],
  dn_y: [0, 2.5, 2.9, 3.3],
  ln_scd: [0, 2.3, 2.7, 2.9],
  ln_r: [0, 2.5, 2.9, 3.3],
}

export function getBatteryLevel(nodeType: TNodeType, voltage?: number): number {
  if (voltage === undefined) return 3

  if (BATTERY_THRESHOLDS[nodeType] === undefined) return 3

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const batteryLevels: number[] = BATTERY_THRESHOLDS[nodeType]!

  // battery bar levels are 0-3 (3 is empty, 0 is full) matching with BATTERY_THRESHOLDS array indexes

  if (voltage >= batteryLevels[3]) {
    return 0
  } else if (voltage >= batteryLevels[2]) {
    return 1
  } else if (voltage >= batteryLevels[1]) {
    return 2
  } else if (voltage > batteryLevels[0]) {
    return 3
  }

  return 3
}
