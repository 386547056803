import { ApiRequestQueue, ApiRequestStatus, ApiRequestType } from './api/queue'
import { OfflineRequestError } from 'utils/errorCodes'
import { serviceCenterPlannedNodesReposition } from './api/serviceCenterPlannedNodesReposition'

export interface RepositionPlannedNodesMeta {
  pseudonodes: { [plannedNodeId: number]: string }
}

export async function repositionPlannedNodes(param: { [plannedNodeId: number]: string }) {
  const payload = {
    pseudonodes: param,
  }

  try {
    // Request may or may not have been completed by the time the promise resolves
    const { status } = await ApiRequestQueue.createRequest(ApiRequestType.REPOSITION_PSEUDO_NODES, payload)

    if (status === ApiRequestStatus.COMPLETED) return
  } catch (error) {
    // If the request failed because the app is offline, don't throw an error
    if (!(error instanceof OfflineRequestError)) {
      throw error
    }
  }

  serviceCenterPlannedNodesReposition.callback(payload)
}
