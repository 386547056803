import { faCalendar } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Checkbox as MantineCheckbox, Loader, LoadingOverlay, Table } from '@mantine/core'
import { DatePickerInput } from '@mantine/dates'
import { routes } from '@semios/app-platform-banyan-route-definitions'
import { isNil } from '@semios/app-platform-common'
import { almondCultivarsSelector } from 'App/Map/_utils/almondCultivarsSelector'
import { getTimezoneForSelectedPropertyOrRegion } from 'App/Map/PanelDetails/_utils/getTimezoneForSelectedPropertyOrRegion'
import { Authorization } from 'components/Authorization/Authorization'
import { Button } from 'components/Button/Button'
import { Checkbox } from 'components/Checkbox/Checkbox'
import { ConfirmationModal } from 'components/ConfirmationModal/ConfirmationModal'
import { ModalDrawer } from 'components/ModalDrawer/ModalDrawer'
import { translate } from 'i18n/i18n'
import moment from 'moment-timezone'
import React, { useState } from 'react'
import { colors } from 'settings/colors'
import { SharedSettings } from 'settings/SharedSettings'
import { detailsPanelStore } from 'stores/detailsPanelStore'
import { fieldAssetStore } from 'stores/fieldAssetStore'
import { selectedFieldAssetsStore } from 'stores/selectedFieldAssetsStore'
import { apiFetch } from 'utils/apiFetch'
import { showNotification } from 'utils/showNotification'
import { useApiREST } from 'utils/useApiREST'

const permission = 'EDIT_ALMOND_HULL_SPLIT_VALUES_FOR_STATIONS'

type THullSplitMapEntry = {
  cultivarName: string
  fullBloomDate: string
  hullSplitForecast: string
  timezone: string
}

const refreshTheDetailsPanelData = () =>
  detailsPanelStore.setState((s) => ({ ...s, keyForRefreshing: new Date().toISOString() }))

export const AlmondHullSplitSettings = () => {
  const [isHullSplitSettingsOpen, setIsHullSplitSettingsOpen] = useState(false)

  const [selectedCultivars, setSelectedCultivars] = useState<Map<number, string | null>>(
    new Map<number, string | null>(),
  )

  const [hullSplitSettings, setHullSplitSettings] = useState<Map<number, THullSplitMapEntry>>(new Map())
  const [isRefreshing, setIsRefreshing] = useState<boolean>(false)
  const [hasChanges, setHasChanges] = useState<boolean>(false)
  const [checkedCultivars, setCheckedCultivars] = useState<string[]>([])
  const [cultivarDates, setCultivarDates] = useState<Map<number, Date | null>>(new Map<number, Date | null>())
  const [confirmModalOpened, setConfirmModalOpened] = useState(false)
  const { propertyId } = selectedFieldAssetsStore.useSelector((s) => ({ propertyId: s.property }))
  const property = selectedFieldAssetsStore.useSelector((s) => s.property)
  const year = new Date().getFullYear()
  const propertyIds = property ? [property] : []
  const years = [year]

  const cultivars = almondCultivarsSelector(fieldAssetStore.getState()).reduce(
    (acc: Record<string, string>, cultivar) => {
      return {
        ...acc,
        [cultivar.id]: cultivar.name,
      }
    },
    {},
  )

  const { data, loading } = useApiREST<
    routes.FieldAssetSettingsGet.Request,
    routes.FieldAssetSettingsGet.Response,
    {
      bloomDefaultDates: Map<number, string>
    }
  >({
    body: { almondHullSplit: { propertyIds, years } },
    url: routes.FieldAssetSettingsGet.path,
    initialLoading: true,
    initialState: {
      bloomDefaultDates: new Map(),
    },
    preventFetch: !isHullSplitSettingsOpen || !propertyId || isNil(property),
    watchers: [isHullSplitSettingsOpen],
    shaper: (data) => {
      const hullSplitInitialMap = new Map()
      const hullSplitDefaultDates = new Map()
      const hullSplitDatesInitialMap = new Map()
      const hullSplitInitialSelected = new Map()
      const initiallyCheckedCultivars: string[] = []

      if (isNil(data.almondHullSplit) || 'error' in data.almondHullSplit)
        return {
          initialHullSplitSettings: new Map(),
          bloomDefaultDates: new Map(),
        }

      const hullSplitData = data.almondHullSplit?.cultivars ?? []
      const defaultDates = data.almondHullSplit?.defaultCultivarBloomDates ?? []

      hullSplitData.forEach((entry) => {
        hullSplitInitialMap.set(entry.cultivarId, {
          cultivarName: entry.cultivarName,
          fullBloomDate: entry.fullBloom,
          hullSplitForecast: entry.hullSplitForecast,
          timezone: entry.timezone,
        })

        hullSplitDatesInitialMap.set(
          entry.cultivarId,
          new Date(moment.tz(entry.fullBloom, entry.timezone).toISOString()),
        )

        hullSplitInitialSelected.set(
          entry.cultivarId,
          moment.tz(entry.fullBloom, entry.timezone).toISOString(),
        )

        if (!isNil(entry.cultivarId)) initiallyCheckedCultivars.push(entry.cultivarId.toString())
      })

      defaultDates.forEach((defaultDate) => {
        hullSplitDefaultDates.set(defaultDate.cultivarId, defaultDate.defaultDate)
      })

      setCultivarDates(hullSplitDatesInitialMap)

      setSelectedCultivars(hullSplitInitialSelected)

      setCheckedCultivars(initiallyCheckedCultivars)

      setHullSplitSettings(hullSplitInitialMap)

      return {
        bloomDefaultDates: hullSplitDefaultDates,
      }
    },
  })

  const { bloomDefaultDates } = data

  const handleResetAndClose = () => {
    setSelectedCultivars(new Map())

    setHullSplitSettings(new Map())

    setCheckedCultivars([])

    setCultivarDates(new Map())

    setHasChanges(false)

    setIsRefreshing(false)

    setIsHullSplitSettingsOpen(false)

    refreshTheDetailsPanelData()
  }

  const updateCultivars = async () => {
    const updatedCultivars = Array.from(selectedCultivars, ([cultivarId, fullbloomDate]) => {
      const cleanedDate = fullbloomDate === '' ? null : fullbloomDate

      return {
        cultivarId,
        fullbloomDate: cleanedDate,
      }
    })

    if (isNil(propertyId)) return

    if (updatedCultivars.length === 0) {
      showNotification({
        message: translate.phrases.banyanApp('There were no settings to update.'),
        type: 'warning',
      })

      return
    }

    const thingsToUpdate: {
      propertyId: number
      year: number
      cultivars: {
        cultivarId: number
        fullbloomDate: string | null
      }[]
    }[] = [
      {
        propertyId,
        year,
        cultivars: updatedCultivars,
      },
    ]

    try {
      const response = await apiFetch<
        routes.FieldAssetSettingsSet.Request,
        routes.FieldAssetSettingsSet.Response
      >({
        url: routes.FieldAssetSettingsSet.path,
        body: {
          almondHullSplitUpdate: thingsToUpdate,
        },
      })

      if (Array.isArray(response?.almondHullSplitUpdate)) {
        showNotification({
          message: translate.phrases.banyanApp('Successfully updated settings'),
          type: 'success',
        })
      } else {
        showNotification({
          message: translate.phrases.banyanApp('Failed to update Almond Hull Split settings'),
          type: 'error',
        })
      }
    } catch (error) {
      showNotification({
        message: translate.phrases.banyanApp('Failed to update Almond Hull Split settings'),
        type: 'error',
      })
    }
  }

  const getNewForecastDates = async () => {
    setIsRefreshing(true)

    await updateCultivars()

    const updatedData = await apiFetch<
      routes.FieldAssetSettingsGet.Request,
      routes.FieldAssetSettingsGet.Response
    >({
      body: { almondHullSplit: { propertyIds, years } },
      url: routes.FieldAssetSettingsGet.path,
    })

    if (isNil(updatedData.almondHullSplit) || 'error' in updatedData.almondHullSplit) return

    const newSettings = updatedData.almondHullSplit?.cultivars.reduce((acc, cultivarData) => {
      const cultivarId = cultivarData.cultivarId

      if (isNil(cultivarId)) return acc

      acc.set(cultivarId, {
        cultivarName: cultivarData.cultivarName,
        fullBloomDate: cultivarData.fullBloom,
        hullSplitForecast: cultivarData.hullSplitForecast,
        timezone: cultivarData.timezone,
      })

      return acc
    }, new Map())

    setHullSplitSettings(new Map(newSettings))

    setIsRefreshing(false)
  }

  const handleSave = async () => {
    await updateCultivars()

    handleResetAndClose()
  }

  const handleCloseWithoutSaving = () => {
    if (hasChanges) {
      setConfirmModalOpened(true)
    } else {
      handleResetAndClose()
    }
  }

  const propertyTimezone = getTimezoneForSelectedPropertyOrRegion()

  const Footer = (
    <div
      css={{
        display: 'flex',
        justifyContent: 'space-between',
        borderTop: `1px solid ${colors.grey200}`,
        backgroundColor: colors.grey50,
        padding: '8px',
        alignItems: 'center',
      }}
    >
      <Button
        variant={'tertiary'}
        style={{ height: 40 }}
        onClick={() => {
          handleCloseWithoutSaving()
        }}
      >
        {translate.phrases.banyanApp('Cancel')}
      </Button>
      <span>
        <Button
          variant={'tertiary'}
          disabled={!hasChanges}
          style={{ marginRight: 10, height: 40 }}
          onClick={() => {
            getNewForecastDates()

            setHasChanges(false)
          }}
        >
          {translate.phrases.banyanApp('Save')}
        </Button>
        <Button
          variant={'primary'}
          onClick={() => {
            handleSave()
          }}
        >
          {translate.phrases.banyanApp('Save & Close')}
        </Button>
      </span>
    </div>
  )

  return (
    <Authorization requires={{ permission, entity: Number(propertyId) }}>
      <>
        <ModalDrawer
          title={translate.phrases.banyanApp('Hull Split Configuration')}
          opened={isHullSplitSettingsOpen}
          customWideFooter={Footer}
          customNarrowFooter={Footer}
          onClose={handleCloseWithoutSaving}
          zIndex={SharedSettings.DEFAULT_MODAL_DRAWER_Z_INDEX}
        >
          <div style={{ marginLeft: '10px', marginTop: '10px' }}>
            <LoadingOverlay visible={loading} />
            <MantineCheckbox.Group value={checkedCultivars} onChange={setCheckedCultivars}>
              <Table>
                <thead>
                  <tr>
                    {/* a small hack to keep focus from hiding value of an input */}
                    <th data-autofocus>{translate.phrases.banyanApp('Cultivar')}</th>
                    <th>{translate.phrases.banyanApp('Bloom Date')}</th>
                    <th>{translate.phrases.banyanApp('Forecast')}</th>
                  </tr>
                </thead>

                <tbody>
                  {Object.entries(cultivars)
                    .filter((cultivarEntry) =>
                      Array.from(bloomDefaultDates.keys()).includes(Number(cultivarEntry[0])),
                    )
                    .sort((a, b) => (a[1] > b[1] ? 1 : -1))
                    .map(([cultivarId, cultivarName]) => {
                      const numericalCultivarId = Number(cultivarId)
                      const dataForCultivar = hullSplitSettings.get(Number(cultivarId))
                      const cultivarFullBloomDate = dataForCultivar ? dataForCultivar.fullBloomDate : null
                      const cultivarTimezone = dataForCultivar ? dataForCultivar.timezone : propertyTimezone
                      const hullSplitDate = dataForCultivar?.hullSplitForecast

                      const defaultDate = cultivarFullBloomDate
                        ? new Date(moment.tz(cultivarFullBloomDate, cultivarTimezone).toISOString())
                        : undefined

                      return (
                        <tr key={cultivarId} style={{ height: 60 }}>
                          <td
                            onClick={(e) => {
                              e.stopPropagation()

                              setHasChanges(true)

                              const initialDate = cultivarDates.has(numericalCultivarId)
                                ? cultivarDates.get(numericalCultivarId)
                                : defaultDate

                              const isoDate = initialDate
                                ? moment.tz(initialDate, cultivarTimezone).toISOString()
                                : bloomDefaultDates.get(numericalCultivarId) ?? ''

                              setSelectedCultivars((prev) => {
                                prev.has(numericalCultivarId)
                                  ? prev.delete(numericalCultivarId)
                                  : prev.set(numericalCultivarId, isoDate)

                                return prev
                              })

                              setCultivarDates((prev) => {
                                if (!isNil(isoDate) && isoDate !== '') {
                                  prev.set(numericalCultivarId, new Date(isoDate))
                                }

                                return new Map(prev)
                              })

                              setCheckedCultivars((prev) =>
                                prev.includes(cultivarId)
                                  ? prev.filter((position) => position !== cultivarId)
                                  : [...prev, cultivarId],
                              )
                            }}
                          >
                            <Checkbox
                              label={cultivarName}
                              value={cultivarId}
                              style={{ zIndex: -1, position: 'relative' }}
                            />
                          </td>
                          <td>
                            <span style={{ display: 'block' }}>
                              <DatePickerInput
                                valueFormat={'MMM, DD YYYY'}
                                placeholder={translate.phrases.banyanApp('Select Full Bloom Date')}
                                clearable
                                minDate={moment
                                  .tz(year.toString(), 'YYYY', cultivarTimezone)
                                  .startOf('year')
                                  .toDate()}
                                maxDate={moment
                                  .tz(year.toString(), 'YYYY', cultivarTimezone)
                                  .endOf('year')
                                  .toDate()}
                                onChange={(newDate) => {
                                  setSelectedCultivars((prev) => {
                                    prev.set(
                                      numericalCultivarId,
                                      moment.tz(newDate, cultivarTimezone).toISOString(),
                                    )

                                    setHasChanges(true)

                                    return new Map(prev)
                                  })

                                  setCultivarDates((prev) => {
                                    if (!isNil(newDate)) prev.set(numericalCultivarId, new Date(newDate))
                                    else prev.set(numericalCultivarId, null)

                                    return new Map(prev)
                                  })

                                  setCheckedCultivars((prev) => [...prev, cultivarId])
                                }}
                                value={cultivarDates.get(numericalCultivarId)}
                                style={{
                                  display: 'inline-block',
                                  width: '80%',
                                }}
                              />
                              <FontAwesomeIcon
                                css={{
                                  color: colors.grey800,
                                  fontSize: 16,
                                  display: 'inline-block',
                                  marginLeft: '4px',
                                  marginRight: 0,
                                }}
                                icon={faCalendar}
                              />
                            </span>
                          </td>
                          <td style={{ width: '25%' }}>
                            {checkedCultivars.includes(cultivarId) && isNil(hullSplitDate) && isRefreshing && (
                              <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <Loader />
                              </div>
                            )}
                            {!!hullSplitDate && (
                              <translate.Phrases.templates
                                k={'<value>{{value}}</value>'}
                                c={{ value: <strong /> }}
                                v={{
                                  value: translate.dates.format(
                                    moment.tz(hullSplitDate, cultivarTimezone),
                                    'MMM D, YYYY',
                                  ),
                                }}
                              />
                            )}
                            {!isNil(dataForCultivar) &&
                              isNil(hullSplitDate) &&
                              !isRefreshing &&
                              checkedCultivars.includes(cultivarId) && (
                                <translate.Phrases.templates
                                  k={'<value>{{value}}</value>'}
                                  c={{ value: <strong /> }}
                                  v={{
                                    value: translate.phrases.abbreviations('Not Applicable'),
                                  }}
                                />
                              )}
                          </td>
                        </tr>
                      )
                    })}
                </tbody>
              </Table>
            </MantineCheckbox.Group>
          </div>

          <ConfirmationModal
            modalOpened={confirmModalOpened}
            closeModal={() => setConfirmModalOpened(false)}
            title={translate.phrases.banyanApp('Unsaved Changes')}
            content={
              <div>
                {translate.phrases.banyanApp("Any changes you have made won't be saved.")}
                <br />
                {translate.phrases.banyanApp('Select an option below.')}
              </div>
            }
            modalWindowSize={350}
            buttonContent={
              <div
                style={{
                  color: colors.midnight,
                  display: 'grid',
                  gridTemplateColumns: '50% 50%',
                  width: '100%',
                  justifyContent: 'space-evenly',
                }}
              >
                <Button
                  variant={'negative'}
                  style={{ gridColumn: 1, gridRow: 1, marginRight: 2, width: 160, color: colors.midnight }}
                  onClick={() => {
                    setConfirmModalOpened(false)

                    handleResetAndClose()
                  }}
                >
                  {translate.phrases.banyanApp('Discard Changes')}
                </Button>
                <Button
                  variant={'secondary'}
                  css={{
                    'gridColumn': 2,
                    'gridRow': 1,
                    'width': 160,
                    'justifySelf': 'end',
                    'borderColor': colors.primary,
                    'color': colors.primary,
                    '&:not([disabled],[data-loading="true"]):hover': {
                      color: colors.primary,
                      background: colors.white,
                      shadow: '0px 0px 4px rgba(0, 0, 0, 0.25)', // Matches mantine style
                    },
                  }}
                  onClick={() => {
                    setConfirmModalOpened(false)
                  }}
                >
                  {translate.phrases.banyanApp('Keep Editing')}
                </Button>
                <Button
                  variant={'primary'}
                  style={{
                    gridArea: '2 / 1 / 2 / 3',
                    marginTop: 10,
                  }}
                  onClick={() => {
                    setConfirmModalOpened(false)

                    handleSave()
                  }}
                >
                  {translate.phrases.banyanApp('Save Changes')}
                </Button>
              </div>
            }
          />
        </ModalDrawer>
        <Button
          variant={'link'}
          onClick={() => setIsHullSplitSettingsOpen(true)}
          style={{
            marginLeft: 'auto',
            marginRight: 0,
            display: !isNil(property) ? 'unset' : 'none',
          }}
        >
          {translate.phrases.banyanApp('Edit Hull Split Settings')}
        </Button>
      </>
    </Authorization>
  )
}
