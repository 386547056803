import { routes } from '@semios/app-platform-banyan-route-definitions'
import { VV } from '@semios/app-platform-value-type-definitions'
import { getTimezoneForSelectedPropertyOrRegion } from 'App/Map/PanelDetails/_utils/getTimezoneForSelectedPropertyOrRegion'
import { propertyLacksPermissionSectionMaker } from 'App/Map/PanelDetails/_utils/propertyLacksPermissionSectionMaker'
import { selectedPropertyHasPermission } from 'App/Map/PanelDetails/_utils/selectedPropertyHasPermission'
import { DropdownSelectorPoint } from 'App/Map/PanelDetails/SectionTitleBars/DropdownSelectorPoint/DropdownSelectorPoint'
import { updateTooltipForValueSource } from 'App/Map/PanelDetails/StackemCharts/_utils/by-domain/_utils/tooltipOptionsForValueSourceCharts'
import { EAggregationInterval, TFieldAssetKeyTypes } from 'App/Map/types'
import { lineChartTooltipFormatter } from 'components/StackedChart/_utils/lineChartTooltipFormatter/lineChartTooltipFormatter'
import { StackedChartSection, TChartSeries, TValueTypeObject } from 'components/StackedChart/types'
import { isEmpty } from 'lodash'
import moment from 'moment-timezone'
import { fieldAssetStore } from 'stores/fieldAssetStore'
import {
  selectedFieldAssetsStore,
  TPointCategory,
  TSelectedFieldAssetsStoreState,
} from 'stores/selectedFieldAssetsStore'
import { selectedValueGroupsStore } from 'stores/selectedValueGroupsStore/selectedValueGroupsStore'
import { smallStore } from 'stores/smallStore'
import { unitConverter } from 'utils/unitConverter/unitConverter'
import { chooseAmongstUnAggHourlyAndDaily } from '../../chooseAmongstUnAggHourlyAndDaily'
import { generateSeriesForEnvironmentalChart } from '../_utils/generateSeriesForEnvironmentalChart/generateSeriesForEnvironmentalChart'
import { getXDateFormat } from '../_utils/getXDateFormat'
import { plotOptionsToHideInactiveSeriesHarderMaker } from '../_utils/plotOptionsToHideInactiveSeriesHarderMaker'
import { getSelectedRegion } from 'utils/getSelectedRegion'
import { isUserOnlyAFreeRegionalUser } from 'utils/isUserOnlyAFreeRegionalUser'
import { selectedRegionHasPermission } from 'App/Map/PanelDetails/_utils/selectedRegionHasPermission'
import {
  makeRegionalSeries,
  makeRegionalSeriesFromRegularSeries,
} from 'App/Map/PanelDetails/StackemCharts/_utils/by-domain/_utils/makeRegionalSeriesFromRegularSeries'
import { Summary } from './Summary'

const pointCategory: TPointCategory = 'weatherPoint'
const permissionRequired = 'VIEW_WEATHER_DETAILS'

const checkPermission = () =>
  selectedPropertyHasPermission({ permission: permissionRequired }) ||
  (isUserOnlyAFreeRegionalUser() && selectedRegionHasPermission({ permission: permissionRequired }))

const possibleValueTypesToRequest: VV.DomainTypes.Weather.TTimeseriesValueTypeKeysMerged[] = [
  'temperature_ABOVE',
  'temperature_IN',
  'temperature_BELOW',
]

const getRegionalValuesRequested = ({
  selectedFieldAssets,
  preferredAggregationInterval,
}: {
  selectedFieldAssets: TSelectedFieldAssetsStoreState
  preferredAggregationInterval: {
    preferredAggregationInterval: ReturnType<typeof chooseAmongstUnAggHourlyAndDaily>
  }
}): Partial<routes.Values.Request> => {
  const selectedProperty = selectedFieldAssets.property
  const selectedRegion = getSelectedRegion({ selectedFieldAssets, selectedProperty })

  if (!selectedRegion) return {}

  const valuesForRegion = fieldAssetStore.getState().regions?.[selectedRegion]?.valuesTimeseries || []
  const valuesToRequestForRegion = possibleValueTypesToRequest.filter((v) => valuesForRegion.includes(v))

  const valuesRequested = valuesToRequestForRegion.reduce((request, valueType) => {
    request[valueType] = preferredAggregationInterval

    return request
  }, {} as Partial<Record<typeof possibleValueTypesToRequest[number], typeof preferredAggregationInterval>>)

  if (isEmpty(valuesRequested)) return {}

  return {
    regions: {
      regionIds: [selectedRegion],
      valuesRequested,
    },
  }
}

const getPointsValuesRequested = ({
  selectedFieldAssets,
  preferredAggregationInterval,
}: {
  selectedFieldAssets: TSelectedFieldAssetsStoreState
  preferredAggregationInterval: {
    preferredAggregationInterval: ReturnType<typeof chooseAmongstUnAggHourlyAndDaily>
  }
}): Partial<routes.Values.Request> => {
  if (!selectedFieldAssets[pointCategory] || !selectedFieldAssets.property) return {}

  const valuesForPoint =
    fieldAssetStore.getState().properties?.[selectedFieldAssets.property]?.points?.[
      selectedFieldAssets[pointCategory]
    ]?.valuesTimeseries || []

  const valuesToRequest = possibleValueTypesToRequest.filter((v) => valuesForPoint.includes(v))

  const valuesRequested = valuesToRequest.reduce((request, valueType) => {
    request[valueType] = preferredAggregationInterval

    return request
  }, {} as Partial<Record<typeof possibleValueTypesToRequest[number], typeof preferredAggregationInterval>>)

  if (isEmpty(valuesRequested)) return {}

  return {
    points: {
      lngLats: [selectedFieldAssets[pointCategory]],
      valuesRequested,
    },
  }
}

export const apiArgs = ({
  selectedValueGroups,
  selectedFieldAssets,
}: {
  selectedValueGroups: ReturnType<typeof selectedValueGroupsStore.getState>['selectedValueGroups']
  selectedFieldAssets: TSelectedFieldAssetsStoreState
}): Partial<routes.Values.Request> => {
  if (!checkPermission()) return {}

  if (!selectedValueGroups.air_temperature) return {}

  const preferredAggregationInterval = {
    preferredAggregationInterval: chooseAmongstUnAggHourlyAndDaily(),
  }

  const pointsToRequest = getPointsValuesRequested({
    selectedFieldAssets,
    preferredAggregationInterval,
  })

  const showRegionalData = smallStore.getState().showRegionalData

  const regionsToRequest = showRegionalData
    ? getRegionalValuesRequested({
        selectedFieldAssets,
        preferredAggregationInterval,
      })
    : {}

  return { ...pointsToRequest, ...regionsToRequest }
}

export const content = ({
  compareSeasonsData,
  data,
}: {
  compareSeasonsData: routes.Values.Response
  data: routes.Values.Response
}): StackedChartSection => {
  const commonReturnItems = {
    title: unitConverter.temperatureInCanopy().categoryTitleWithoutUnit(),
    titleChildren: (
      <DropdownSelectorPoint
        pointCategory={pointCategory}
        valuesTimeseriesToFilterOn={possibleValueTypesToRequest}
      />
    ),
    id: 'stackem-temperature',
  }

  if (!checkPermission()) return propertyLacksPermissionSectionMaker(commonReturnItems)

  const showRegionalData = smallStore.getState().showRegionalData
  const selectedFieldAssets = selectedFieldAssetsStore.getState()
  const stationLngLat = String(selectedFieldAssets[pointCategory])
  const selectedProperty = Number(selectedFieldAssetsStore.getState().property)
  const selectedRegion = getSelectedRegion({ selectedProperty, selectedFieldAssets })
  const series: TChartSeries[] = []
  const stationValues = data?.points?.[stationLngLat]?.values
  const regionValues = data?.regions?.[String(selectedRegion)]?.values
  const hasAboveCanopySensor = !isEmpty(stationValues?.temperature_ABOVE?.[0]?.timeseries)
  const hasInCanopySensor = !isEmpty(stationValues?.temperature_IN?.[0]?.timeseries)
  const hasBelowCanopySensor = !isEmpty(stationValues?.temperature_BELOW?.[0]?.timeseries)

  const isVirtual = selectedProperty
    ? fieldAssetStore.getState().properties?.[selectedProperty]?.points?.[
        stationLngLat as TFieldAssetKeyTypes.TLngLat
      ]?.isVirtual
    : true

  const isDaily =
    stationValues?.temperature_ABOVE?.[0]?.metadata?.aggregationInterval === EAggregationInterval.DAILY ||
    stationValues?.temperature_BELOW?.[0]?.metadata?.aggregationInterval === EAggregationInterval.DAILY ||
    stationValues?.temperature_IN?.[0]?.metadata?.aggregationInterval === EAggregationInterval.DAILY ||
    regionValues?.temperature_IN?.[0]?.metadata?.aggregationInterval === EAggregationInterval.DAILY

  const compareSeasonsDataWithRegionMaskAbove = showRegionalData
    ? undefined
    : (compareSeasonsData?.points?.[stationLngLat]?.values?.temperature_ABOVE?.[0] as TValueTypeObject)

  const compareSeasonsDataWithRegionMaskIn = showRegionalData
    ? undefined
    : (compareSeasonsData?.points?.[stationLngLat]?.values?.temperature_IN?.[0] as TValueTypeObject)

  const compareSeasonsDataWithRegionMaskBelow = showRegionalData
    ? undefined
    : (compareSeasonsData?.points?.[stationLngLat]?.values?.temperature_BELOW?.[0] as TValueTypeObject)

  const aboveCanopySeries = generateSeriesForEnvironmentalChart({
    hasSensor: hasAboveCanopySensor,
    valueTypeObject: data.points?.[stationLngLat]?.values?.temperature_ABOVE?.[0] as TValueTypeObject,
    compareSeasonsValueTypeObject: compareSeasonsDataWithRegionMaskAbove,
    unitConverter: unitConverter.temperatureAboveCanopy,
  })

  const inCanopySeries = generateSeriesForEnvironmentalChart({
    hasSensor: hasInCanopySensor,
    valueTypeObject: data.points?.[stationLngLat]?.values?.temperature_IN?.[0] as TValueTypeObject,
    compareSeasonsValueTypeObject: compareSeasonsDataWithRegionMaskIn,
    unitConverter: unitConverter.temperatureInCanopy,
  })

  const belowCanopySeries = generateSeriesForEnvironmentalChart({
    hasSensor: hasBelowCanopySensor,
    valueTypeObject: data.points?.[stationLngLat]?.values?.temperature_BELOW?.[0] as TValueTypeObject,
    compareSeasonsValueTypeObject: compareSeasonsDataWithRegionMaskBelow,
    unitConverter: unitConverter.temperatureBelowCanopy,
  })

  series.push(...aboveCanopySeries, ...inCanopySeries, ...belowCanopySeries)

  let regionSeriesId: string

  if (showRegionalData && selectedRegion) {
    if (series.length) {
      const regionData = data.regions?.[selectedRegion]?.values?.temperature_IN?.[0]
      const regionName = selectedRegion ? fieldAssetStore.getState().regions?.[selectedRegion]?.name : ''

      const shapedData = (regionData?.timeseries || []).map((ts) => ({
        x: +new Date(ts.timestamp),
        y: unitConverter
          .temperatureInCanopy(typeof ts.value === 'object' ? ts.value?.average : ts.value)
          .value(),
        low: isDaily
          ? unitConverter.temperatureInCanopy(typeof ts.value === 'object' ? ts.value?.min : null).value()
          : undefined,
        high: isDaily
          ? unitConverter.temperatureInCanopy(typeof ts.value === 'object' ? ts.value?.max : null).value()
          : undefined,
      }))

      const baseSeries: TChartSeries = inCanopySeries[0] || aboveCanopySeries[0] || belowCanopySeries[0]

      const regionalSeries = makeRegionalSeriesFromRegularSeries(baseSeries, {
        name: regionName,
        data: shapedData,
      })

      regionSeriesId = regionalSeries.id

      series.push(regionalSeries)
    } else {
      const regionalInCanopySeries =
        showRegionalData && selectedRegion
          ? generateSeriesForEnvironmentalChart({
              hasSensor: true,
              valueTypeObject: data.regions?.[selectedRegion]?.values
                ?.temperature_IN?.[0] as TValueTypeObject,
              compareSeasonsValueTypeObject: compareSeasonsDataWithRegionMaskIn,
              unitConverter: unitConverter.temperatureInCanopy,
            })
          : []

      regionalInCanopySeries.forEach((s) => {
        series.push(makeRegionalSeries(s))
      })

      regionSeriesId = regionalInCanopySeries[0]?.id
    }
  }

  const timezone = getTimezoneForSelectedPropertyOrRegion()

  const allForecastMoments = [
    stationValues?.temperature_ABOVE?.[0]?.metadata.forecastStartsAt,
    stationValues?.temperature_IN?.[0]?.metadata.forecastStartsAt,
    stationValues?.temperature_BELOW?.[0]?.metadata.forecastStartsAt,
    selectedRegion
      ? data.regions?.[selectedRegion]?.values?.temperature_IN?.[0]?.metadata.forecastStartsAt
      : null,
  ]
    .filter(Boolean)
    .map((d) => moment.tz(d, timezone))

  const firstForecastTimestamp = allForecastMoments.length ? +moment.min(allForecastMoments) : +new Date()

  return {
    ...commonReturnItems,
    items: [
      {
        childrenUpper: (
          <div css={{ textAlign: 'right' }}>
            <Summary
              regionId={showRegionalData ? selectedRegion : null}
              stationLngLat={selectedFieldAssets[pointCategory]}
            />
          </div>
        ),
        chartConfig: {
          semiosHighchartsAdditions: {
            id: commonReturnItems.id,
            firstForecastTimestamp,
          },
          plotOptions: plotOptionsToHideInactiveSeriesHarderMaker(),
          chart: {
            type: 'line',
          },
          series,
          tooltip: {
            xDateFormat: getXDateFormat(isDaily),
            formatter: function (tooltip) {
              let tooltipContents = lineChartTooltipFormatter(this, tooltip, firstForecastTimestamp)

              tooltipContents = updateTooltipForValueSource({
                seriesIds: [
                  aboveCanopySeries[0]?.id,
                  inCanopySeries[0]?.id,
                  belowCanopySeries[0]?.id,
                  regionSeriesId,
                ].filter(Boolean),
                compareSeasonIds: [
                  aboveCanopySeries[1]?.id,
                  inCanopySeries[1]?.id,
                  belowCanopySeries[1]?.id,
                ].filter(Boolean),
                points: this.points,
                tooltipContents,
                firstForecastTimestamp,
                isVirtual,
              })

              return tooltipContents
            },
          },
        },
      },
    ],
  }
}
