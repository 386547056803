import { TDevice } from '../types'
import { translate } from '../../../i18n/i18n'
import { getDeviceInstallationHeightLabel } from './getDeviceInstallationHeightLabel'
import React from 'react'

interface BaseNodeDevice {
  source?: string
  height?: string
  connector?: string
  port?: string
}

export function renderNodeDeviceLabel(
  nodeDevice: BaseNodeDevice,
  allDevices: Record<string, TDevice>,
): JSX.Element {
  return (
    <>
      {nodeDevice.source
        ? allDevices[nodeDevice.source]?.name || nodeDevice.source
        : translate.phrases.placeholder('Unknown')}
      <br />
      {nodeDevice.height && (
        // eslint-disable-next-line react/jsx-no-literals
        <>{getDeviceInstallationHeightLabel(nodeDevice.height)}</>
        // eslint-disable-next-line react/jsx-no-literals
      )}
      &nbsp;&nbsp;•&nbsp;&nbsp;
      {nodeDevice.port?.toUpperCase() || nodeDevice.connector?.toUpperCase()}
    </>
  )
}
