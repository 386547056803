import React, { useMemo } from 'react'
import { serviceCenterStore } from '../../store/serviceCenterStore'
import { NodeRenderer } from './renderers/NodeRenderer'
import { TActiveGateway, TActiveNode, TNodeStatus } from '../../types'
import { getIdentifier } from '../../utils/getIdentifier'
import { getNodeDeviceTypes } from '../_utils/getNodeDeviceTypes'
import { Point } from '@turf/helpers'

interface ActiveEquipmentLayerProps {
  activeNodes: Array<TActiveNode | TActiveGateway>
}

export const ActiveEquipmentLayer: React.FC<ActiveEquipmentLayerProps> = ({ activeNodes }) => {
  const selectedEquipmentStatuses = serviceCenterStore.useSelector(
    serviceCenterStore.selectors.getMapEquipmentStatuses,
  )

  const selectedEquipmentTypes = serviceCenterStore.useSelector(
    serviceCenterStore.selectors.getMapEquipmentTypes,
  )

  const selectedNodeDevices = serviceCenterStore.useSelector(
    serviceCenterStore.selectors.getMapEquipmentDevices,
  )

  const selectedEquipmentId = serviceCenterStore.useSelector(
    serviceCenterStore.selectors.getSelectedEquipmentId,
  )

  const nodeStatuses = serviceCenterStore.useSelector(serviceCenterStore.selectors.getNodeStatuses)
  const allServiceIssues = serviceCenterStore.useSelector(serviceCenterStore.selectors.getServiceIssues)

  const shapedNodes = useMemo(() => {
    return activeNodes.map((node) => {
      const nodeIdentifier = getIdentifier(node)

      // Use latest cached status from the store to avoid nodes showing offline while the logs are being fetched
      let nodeStatus = nodeStatuses[nodeIdentifier]

      const nodeServiceIssues = allServiceIssues[nodeIdentifier]

      if (nodeServiceIssues?.length) nodeStatus = TNodeStatus.ERROR

      return {
        id: nodeIdentifier,
        nodeType: (node as TActiveNode).nodeType || (node as TActiveGateway).gatewayType,
        geometry: JSON.parse(node.location as string) as Point,
        deviceTypes: (node as TActiveNode).devices ? getNodeDeviceTypes((node as TActiveNode).devices) : [],
        hasIssue: !!nodeServiceIssues?.length,
        status: nodeStatus || TNodeStatus.PENDING,
      }
    })
  }, [activeNodes, nodeStatuses, allServiceIssues])

  return (
    <NodeRenderer
      nodes={shapedNodes}
      selectedNodeId={selectedEquipmentId}
      dependencies={[
        selectedEquipmentStatuses,
        selectedEquipmentTypes,
        selectedNodeDevices,
        selectedEquipmentId,
      ]}
    />
  )
}
