import { css } from '@emotion/css'
import { ToggleButton } from 'components/ToggleButton/ToggleButton'
import { translate } from 'i18n/i18n'
import {
  DropdownSelectorPoint,
  TDropdownSelectorPointProps,
} from '../DropdownSelectorPoint/DropdownSelectorPoint'
import { detailsPanelStore } from 'stores/detailsPanelStore'
import { TPointCategory } from 'stores/selectedFieldAssetsStore'

const pointCategory: TPointCategory = 'soilPoint'

export const SoilAverageDepthsTitleChildren = (
  props: TDropdownSelectorPointProps & { showAverageToggle: boolean },
) => {
  const { showAverageToggle, ...rest } = props

  const { showSelectedSoilDepths } = detailsPanelStore.useSelector((s) => ({
    showSelectedSoilDepths: s?.showSelectedSoilDepths,
  }))

  return (
    <div css={{ display: 'flex', flexWrap: 'wrap', gap: '4px 12px', alignItems: 'center' }}>
      <DropdownSelectorPoint pointCategory={pointCategory} {...rest} />
      {showAverageToggle && (
        <div css={{ alignItems: 'center', display: 'flex', flexDirection: 'column', marginLeft: 12 }}>
          <div css={{ fontWeight: 'normal' }}>{translate.phrases.banyanApp('Show Selected Depths')}</div>
          <ToggleButton
            buttonProps={{ size: 'xs', className: css({ fontSize: 13 }) }}
            data={[
              { label: translate.phrases.banyanApp('On'), value: true },
              { label: translate.phrases.banyanApp('Off'), value: false },
            ]}
            onChange={(newValue) =>
              detailsPanelStore.setState((s) => ({ ...s, showSelectedSoilDepths: newValue }))
            }
            value={showSelectedSoilDepths}
          />
        </div>
      )}
    </div>
  )
}
