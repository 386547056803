import { selectedFieldAssetsStore } from 'stores/selectedFieldAssetsStore'
import { selectedValueGroupsStore } from 'stores/selectedValueGroupsStore/selectedValueGroupsStore'
import { useIsServiceCenterActive } from 'utils/useIsServiceCenterActive'
import { isUserOnlyAFreeRegionalUser } from './isUserOnlyAFreeRegionalUser'

// TODO Will get rid of this when we refactor/improve map ui item placement (considering screen size, orientation... etc.)
export const usePreventPanelDetailsFromBeingOpen = () => {
  const someFieldAssetIsSelected = selectedFieldAssetsStore.useSelector((s) => {
    // TODO: FREE REGIONS - won't need this anymore
    const { region, ...rest } = s
    const isFreeRegionalUser = isUserOnlyAFreeRegionalUser()

    if (isFreeRegionalUser) {
      return Boolean(region)
    }

    return Object.values(rest).some((v) => v)
  })

  const someValueGroupIsSelected = selectedValueGroupsStore.useSelector((s) =>
    Object.values(s.selectedValueGroups).some((vg) => vg),
  )

  const iServiceCenterActive = useIsServiceCenterActive()

  if (iServiceCenterActive) return false
  else return !someFieldAssetIsSelected || !someValueGroupIsSelected
}
