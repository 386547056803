import { ApiRequestQueue, ApiRequestType } from './api/queue'
import { OfflineRequestError } from '../../../utils/errorCodes'
import { NodeServiceIssueResolutionActionsAddMeta } from './api/serviceCenterNodeServiceIssueResolutionActionsAdd'

export async function addResolutionActionsToNodeServiceIssue(
  serviceIssueId: number,
  resolutionActionIds: number[],
  resolutionDescription?: string,
) {
  try {
    // Request may or may not have been completed by the time the promise resolves
    await ApiRequestQueue.createRequest(ApiRequestType.ADD_RESOLUTION_ACTIONS, {
      serviceIssueId,
      resolutionActionIds,
      resolutionDescription,
    } as NodeServiceIssueResolutionActionsAddMeta)
  } catch (error) {
    // If the request failed because the app is offline, don't throw an error
    if (!(error instanceof OfflineRequestError)) {
      // TODO: Return a user-friendly error message
      throw error
    }
  }
}
