import { apiFetch } from 'utils/apiFetch'
import { routes } from '@semios/app-platform-banyan-route-definitions'

export const serviceCenterNodeServiceIssuesGet = async (
  params: Partial<routes.ServiceCenterNodeServiceIssuesGet.Request>,
) => {
  const serviceIssues = await apiFetch<
    routes.ServiceCenterNodeServiceIssuesGet.Request,
    routes.ServiceCenterNodeServiceIssuesGet.Response
  >({
    url: routes.ServiceCenterNodeServiceIssuesGet.path,
    body: params,
    params: {
      method: 'POST',
    },
  })

  return serviceIssues
}
