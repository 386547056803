import { routes } from '@semios/app-platform-banyan-route-definitions'
import {
  TCurrentValuesMapCacheKeys,
  TCurrentValuesMapProcessedCaches,
  TGetCacheUpdatesFromResponseReturn,
} from '../_types'
import {
  TValuesCurrentHeatmapPointsValueTypes,
  TValuesCurrentPointsValueTypes,
  TValuesCurrentPropertiesValueTypes,
  TValuesCurrentRegionValueTypes,
} from 'stores/mapControlsStore/types'
import { TUnitConverterFunction } from 'utils/unitConverter/unitConverter'
import { colors, TRGBAColorWith1AtTheEnd } from 'settings/colors'
import { populateSCDsForBlocks } from './generateUsualStyleGetCacheUpdatesFromResponse'
import { smallStore } from 'stores/smallStore'
import { getPrimaryValueGroup } from 'stores/selectedValueGroupsStore/getPrimaryValueGroup'
import { getHeatmapCSS } from './getHeatmapCSS'
import { isEmpty, isNil } from 'lodash'
import { TFieldAssetKeyTypes } from 'App/Map/types'
import { CSSObject } from '@emotion/react'
import { getPropertyIdsForAllPropertiesInVisibleRegions } from './getPropertyIdsForAllPropertiesInVisibleRegions'
import { setHeatmapExtremesFromArrayOfValues } from './setHeatmapExtremesFromArrayOfValues'
import { IconWindDirection } from 'components/icons/IconWindDirection'

export type TGetWindCacheUpdatesFromResponseArgs = {
  cacheKeys: TCurrentValuesMapCacheKeys
  processedCaches: TCurrentValuesMapProcessedCaches
  response: routes.ValuesCurrent.Response
  inBlockPoint?: {
    decimalPlaces?: 0 | 1
    windSpeedValueType: TValuesCurrentPointsValueTypes | TValuesCurrentHeatmapPointsValueTypes
    windDirectionValueType?: TValuesCurrentPointsValueTypes | TValuesCurrentHeatmapPointsValueTypes
    unitConverterFunction: TUnitConverterFunction
  }
  outOfBlockPoint?: {
    decimalPlaces?: 0 | 1
    windSpeedValueType: TValuesCurrentPointsValueTypes
    windDirectionValueType?: TValuesCurrentPointsValueTypes
    unitConverterFunction: TUnitConverterFunction
  }
  property?: {
    decimalPlaces?: 0 | 1
    windSpeedValueType: TValuesCurrentPropertiesValueTypes
    windDirectionValueType?: TValuesCurrentPropertiesValueTypes
    unitConverterFunction: TUnitConverterFunction
  }
  region?: {
    decimalPlaces?: 0 | 1
    windSpeedValueType: TValuesCurrentRegionValueTypes
    windDirectionValueType?: TValuesCurrentRegionValueTypes
    unitConverterFunction: TUnitConverterFunction
  }
  heatmapColoring?: TRGBAColorWith1AtTheEnd[]
  heatmapExtremesForAllPropertiesInVisibleRegions: boolean
}

export type TWindPropertiesValueTypesToPropsDictionary = Record<
  string,
  {
    baseZIndex?: number
    children: React.ReactNode
    windDirectionAverageDegree?: number | null
    getContainerCSS: () => CSSObject
    onHover: boolean
  }
>

export type TWindStationsValueTypesToPropsDictionary = Record<
  string,
  {
    baseZIndex?: number
    children: React.ReactNode
    windDirection?: number | null
    getContainerCSS: () => CSSObject
    getSize?: () => number | null
    shouldAddDataSourceOverlay?: boolean
    isWeatherStation?: boolean
  }
>

export const generateGetWindCacheUpdatesFromResponse = ({
  cacheKeys,
  processedCaches: processedCachesArg,
  response,
  inBlockPoint,
  outOfBlockPoint,
  property: propertyArg,
  heatmapColoring,
  heatmapExtremesForAllPropertiesInVisibleRegions,
  region: regionArg,
}: TGetWindCacheUpdatesFromResponseArgs): TGetCacheUpdatesFromResponseReturn => {
  const returner: TGetCacheUpdatesFromResponseReturn = {}
  const processedCaches = populateSCDsForBlocks(response, processedCachesArg)
  const allValues: (number | null)[] = []
  const primaryValueGroup = getPrimaryValueGroup()

  if ((inBlockPoint || outOfBlockPoint) && !!processedCaches.stationValues.itemsWithinView.length) {
    const itemIdsWithinView: string[] = []

    const itemsWithinViewThatNowHaveValues = processedCaches.stationValues.itemsWithinView.flatMap(
      (station) => {
        let windSpeedKey = inBlockPoint?.windSpeedValueType
        let decimalPlacesToUse = inBlockPoint?.decimalPlaces
        let unitConverterToUse = inBlockPoint?.unitConverterFunction
        let windDirectionKey = inBlockPoint?.windDirectionValueType

        if (station.meta.isOutOfBlock && outOfBlockPoint) {
          windSpeedKey = outOfBlockPoint.windSpeedValueType

          windDirectionKey = outOfBlockPoint.windDirectionValueType

          decimalPlacesToUse = outOfBlockPoint.decimalPlaces ?? decimalPlacesToUse

          unitConverterToUse = outOfBlockPoint.unitConverterFunction
        }

        if (!windSpeedKey || !unitConverterToUse || !windDirectionKey) return []

        // @ts-ignore TODO: coordinate types a bit better
        const value = response?.points?.[station.meta.lngLat]?.[windSpeedKey]?.value as number | null

        // @ts-ignore
        const windDirectionValue = response?.points?.[station.meta.lngLat]?.[windDirectionKey]?.value as
          | number
          | null

        itemIdsWithinView.push(station.id)

        allValues.push(value)

        const children = (
          <div
            css={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {unitConverterToUse(value, { decimalPlaces: decimalPlacesToUse ?? 0 }).valueAsString()}
            <div
              css={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100%',
                marginTop: '4px',
                marginLeft: '4px',
              }}
            >
              {!isNil(windDirectionValue) && <IconWindDirection windDirection={windDirectionValue} />}
            </div>
          </div>
        )

        return {
          id: String(station.id),
          value: {
            [windSpeedKey]: {
              children,
              getContainerCSS: () => getHeatmapCSS({ value }),
              getSize: () => {
                let size = outOfBlockPoint ? 36 : 40

                if (!isNil(value) && value?.toString().length > 3) {
                  size = size + (value.toString().length - 3) * 2
                }

                return size
              },
              shouldAddDataSourceOverlay: false,
              isWeatherStation: true,
            },
          },
        }
      },
    )

    returner.stations = {
      itemsWithinViewThatNowHaveValues,
      cacheKey: cacheKeys.stationCacheKey,
      itemIdsWithinView,
    }
  }

  const willShowStations = !!returner.stations?.itemIdsWithinView.length
  const propertyIdsToValues: Record<TFieldAssetKeyTypes.TPropertyId, number | null> = {}
  const propertyIds = processedCaches.propertyValues.itemsWithinView.map((s) => Number(s.id))
  const regionIdsToValues: Record<TFieldAssetKeyTypes.TRegionId, number | null> = {}
  const regionIds = processedCaches.regionValues?.itemIdsWithinView || []

  if (regionArg && regionArg.windSpeedValueType && smallStore.getState()?.showRegionalData) {
    regionIds.forEach((regionId) => {
      const valueTypeForRegion = response?.regions?.[regionId]?.[regionArg.windSpeedValueType]

      let valueForRegion = null

      if (valueTypeForRegion && 'median_value' in valueTypeForRegion) {
        valueForRegion = valueTypeForRegion.median_value
      }

      regionIdsToValues[regionId] = valueForRegion
    })
  }

  if (propertyArg && propertyArg.windSpeedValueType && propertyArg.windDirectionValueType) {
    propertyIds.forEach((propertyId) => {
      const valueTypeForProperty = response?.properties?.[propertyId]?.[propertyArg.windSpeedValueType]

      let valueForProperty = null

      if (valueTypeForProperty && 'value' in valueTypeForProperty) {
        valueForProperty = valueTypeForProperty.value
      }

      if (valueTypeForProperty && 'median_value' in valueTypeForProperty) {
        valueForProperty = valueTypeForProperty.median_value
      }

      if (typeof valueForProperty !== 'string' && !Array.isArray(valueForProperty)) {
        // @ts-ignore
        propertyIdsToValues[propertyId] = valueForProperty
      }
    })
  }

  // TODO: not sure about this logic
  const showValuesForRegionRatherThanName = regionArg && !isEmpty(regionIdsToValues)

  const regionItemsWithinViewThatNowHaveValues = processedCaches.regionValues?.itemsWithinView.flatMap(
    (region) => {
      if (!showValuesForRegionRatherThanName) {
        return {
          id: region.id,
          value: {
            [String(regionArg?.windSpeedValueType)]: {
              children: null,
              onHover: false,
              getContainerCSS: () => ({}),
              baseZIndex: undefined,
            },
          },
        }
      }

      const value = regionIdsToValues[region.id]
      // @ts-ignore
      const windDirectionForRegion = response?.regions?.[region.id]?.[regionArg.windDirectionValueType]?.value

      allValues.push(value)

      const children = (
        <div
          css={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {regionArg
            .unitConverterFunction(value, { decimalPlaces: regionArg.decimalPlaces ?? 0 })
            .valueAsString()}
          <div
            css={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: '100%',
              marginLeft: '2px',
            }}
          >
            {!isNil(windDirectionForRegion) && <IconWindDirection windDirection={windDirectionForRegion} />}
          </div>
        </div>
      )

      return {
        id: region.id,
        value: {
          [String(regionArg?.windSpeedValueType)]: {
            children: children,
            onHover: true,
            getContainerCSS: () => {
              if (isNil(value)) {
                return { display: 'none' }
              }

              return getHeatmapCSS({ value })
            },
            baseZIndex: 1000,
          },
        },
      }
    },
  )

  const showValuesForPropertyRatherThanName =
    propertyArg && !isEmpty(propertyIdsToValues) && !willShowStations

  const itemsWithinViewThatNowHaveValues = processedCaches.propertyValues.itemsWithinView.flatMap(
    (property) => {
      if (!showValuesForPropertyRatherThanName) {
        return {
          id: String(property.id),
          value: {
            [String(propertyArg?.windSpeedValueType)]: {
              children: property.meta.propertyName,
              onHover: false,
              getContainerCSS: (): CSSObject => ({
                backgroundColor: colors.midnight,
                color: 'white',
              }),
              baseZIndex: undefined,
            },
          },
        }
      }

      const value = propertyIdsToValues[Number(property.id)]

      const windDirectionAverageDegree = // @ts-ignore
        response?.properties?.[property.id]?.[propertyArg.windDirectionValueType]?.value

      allValues.push(value)

      return {
        id: String(property.id),
        value: {
          [String(propertyArg?.windSpeedValueType)]: {
            children: (
              <div css={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                {propertyArg
                  .unitConverterFunction(value, { decimalPlaces: propertyArg.decimalPlaces ?? 0 })
                  .valueAsString()}
                <div
                  css={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100%',
                    marginTop: '4px',
                    marginLeft: '4px',
                  }}
                >
                  {!isNil(windDirectionAverageDegree) && (
                    <IconWindDirection windDirection={windDirectionAverageDegree} />
                  )}
                </div>
              </div>
            ),
            onHover: true,
            getContainerCSS: () => getHeatmapCSS({ value }),
            baseZIndex: isNil(value) ? -10 : undefined,
          },
        },
      }
    },
  )

  if (primaryValueGroup && regionArg) {
    returner.regions = {
      itemsWithinViewThatNowHaveValues: regionItemsWithinViewThatNowHaveValues ?? [],
      cacheKey: cacheKeys.regionValuesCacheKey,
      itemIdsWithinView: processedCaches.regionValues?.itemIdsWithinView ?? [],
    }
  }

  returner.properties = {
    itemsWithinViewThatNowHaveValues,
    cacheKey: cacheKeys.propertyCacheKey,
    itemIdsWithinView: processedCaches.propertyValues.itemIdsWithinView,
  }

  let valuesToUseForExtremes = allValues

  if (heatmapExtremesForAllPropertiesInVisibleRegions && propertyArg?.windSpeedValueType) {
    const visiblePropertyIds = processedCaches.propertyValues.itemsWithinView.map((s) => Number(s.id))
    const allPropertyIdsInVisibleRegions = getPropertyIdsForAllPropertiesInVisibleRegions(visiblePropertyIds)

    if (allPropertyIdsInVisibleRegions.length > 1) {
      const valuesForAllPropertiesInVisibleRegion: number[] = []

      allPropertyIdsInVisibleRegions.forEach((propertyId) => {
        const valueTypeResponseForProperty =
          response?.properties?.[propertyId]?.[propertyArg?.windSpeedValueType]

        if (!isNil(valueTypeResponseForProperty)) {
          const foundValueForProperty =
            'median_value' in valueTypeResponseForProperty
              ? valueTypeResponseForProperty.median_value
              : valueTypeResponseForProperty.value

          if (!isNil(foundValueForProperty) && typeof foundValueForProperty === 'number') {
            valuesForAllPropertiesInVisibleRegion.push(foundValueForProperty)
          }
        }
      })

      if (valuesForAllPropertiesInVisibleRegion.length > 1) {
        valuesToUseForExtremes = valuesForAllPropertiesInVisibleRegion
      }
    }
  }

  setHeatmapExtremesFromArrayOfValues({ values: valuesToUseForExtremes, heatmapColoring })

  return returner
}
