import { TFieldAssetKeyTypes } from '@semios/app-platform-banyan-route-definitions/src/routes/userAppStartup'
import { getIdAndEmitterTypeFromZoneEmitterTypeKey } from 'App/Map/_utils/irrigationZoneEmitterTypeKeyUtil'
import { TIrrigationEventSeries } from 'components/StackedChart/types'
import { Tooltip, TooltipFormatterContextObject } from 'highcharts/highstock'
import { translate } from 'i18n/i18n'
import { TRGBAColorWith1AtTheEnd } from 'settings/colors'
import { unitConverter } from 'utils/unitConverter/unitConverter'
import { checkIfDuplicateEmitters } from './checkIfDuplicateEmitters'
import { getEventStatus } from './getEventStatus'
import { getIrrigationEventForCurrentStamp } from './getIntervalForCurrentStamp'
import { getIrrigationEventColor } from './getIrrigationEventColor'

export function makeWaterEventsTooltip({
  ctx,
  firstForecastTimestamp,
  waterEventsSeries,
  rainSeries,
  timezone,
  tooltip,
}: {
  averageSelectedAwcData?: []
  averageSelectedTemperatureData?: []
  ctx: TooltipFormatterContextObject
  firstForecastTimestamp: number
  waterEventsSeries: TIrrigationEventSeries[]
  rainSeries: TIrrigationEventSeries
  timezone: string
  tooltip: Tooltip
}) {
  const stamp = Number(ctx.x)

  let template = tooltip.defaultFormatter.call(ctx, tooltip)[0]

  const mappedIntervalsForStamp = waterEventsSeries
    .map((emitter) => {
      if (emitter.id === rainSeries?.id) {
        const sum = rainSeries.data.find((ts) => {
          if (!ts.x || !ts.x2) return false

          return stamp >= ts.x && stamp <= ts.x2
        })?.sum

        return { emitter, sum }
      } else {
        const { duration, isFuture, publishStatus } = getIrrigationEventForCurrentStamp({
          stamp,
          timeseries: emitter.data,
          timezone,
        })

        return { emitter, duration, isFuture, publishStatus }
      }
    })
    .sort((a, b) => {
      if (!a?.emitter?.zoneName || !b?.emitter?.zoneName) return 1

      const emitterA = a.emitter.zoneName
      const emitterB = b.emitter.zoneName

      return emitterA.localeCompare(emitterB)
    })
    .sort((a, b) => {
      if (!a?.emitter?.irrigationZoneEmitterId || !b?.emitter?.irrigationZoneEmitterId) return 1

      const emitterA = getIdAndEmitterTypeFromZoneEmitterTypeKey(
        a.emitter?.irrigationZoneEmitterId,
      ).emitterType

      const emitterB = getIdAndEmitterTypeFromZoneEmitterTypeKey(
        b.emitter?.irrigationZoneEmitterId,
      ).emitterType

      return emitterA.localeCompare(emitterB)
    })
    .sort((a, b) => {
      // sort rainIntervals to bottom
      if (a.emitter.id === rainSeries.id) return 1

      if (b.emitter.id === rainSeries.id) return -1

      return 0
    })

  const mappedEmitterTypes = mappedIntervalsForStamp
    .map((e) => e.emitter?.irrigationZoneEmitterId)
    .filter(Boolean) as TFieldAssetKeyTypes.TIrrigationZoneEmitterId[]

  const hasDuplicateEmitters = checkIfDuplicateEmitters(mappedEmitterTypes)

  mappedIntervalsForStamp.forEach(({ emitter, duration, sum, isFuture, publishStatus }) => {
    template += '<tr>'

    if (!!duration) {
      template += `<td colspan="2" style="display: flex; align-items: center; margin-right: 10px;">
                    <div style="background:${getIrrigationEventColor(
                      emitter.color as TRGBAColorWith1AtTheEnd,
                      isFuture,
                      publishStatus,
                    )}; width: 20px; height: 5px; margin: 0 7px 0 0;"></div>
                    <div style="width:max-content">${emitter.name}${
        hasDuplicateEmitters ? ` (${emitter.zoneName})` : ''
      }</div>
                  </td>
                  <td colspan="2" style="text-align: right;">
                    <div style="font-size: 12px;">${getEventStatus(publishStatus)}</div>
                    <div style="font-size: 12px; font-weight: bold;">${duration}</div>
                  </td>`
    } else if (!!sum) {
      template += `<td colspan="2" style="display: flex; align-items: center; margin-right: 10px;">
                    <div style="background: ${getIrrigationEventColor(
                      emitter.color as TRGBAColorWith1AtTheEnd,
                      isFuture,
                      publishStatus,
                    )}; width: 20px; height: 5px; margin: 0 7px 0 0;"></div>
                  <div style="width:max-content">${emitter.name}</div>
                  </td>
                  <td colspan="2" style="text-align: right;">
                    <div style="font-size: 14px; font-weight: bold;">${unitConverter
                      .precipitation(sum)
                      .valueWithSuffix()}
                    </div>
                  </td>`
    }

    template += '</tr>'
  })

  if (Number(stamp) >= firstForecastTimestamp) {
    // the next little replace pattern is just an easy place to jack into the headerFormat
    return template
      .split('</strong><table')
      .join(` (${translate.phrases.banyanApp('Forecast')})</strong><table`)
  }

  template += '</table>'

  return template
}
