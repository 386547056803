import { isNil } from 'lodash'
import { getPrimaryValueGroup } from 'stores/selectedValueGroupsStore/getPrimaryValueGroup'
import {
  TCurrentValuesMapCacheKeys,
  TCurrentValuesMapProcessedCaches,
  TGetCacheUpdatesFromResponseParameters,
  TGetCacheUpdatesFromResponseReturn,
} from './by-domain/_types'
import * as almondBloom from './by-domain/almondBloom'
import * as almondHullSplit from './by-domain/almondHullSplit'
import * as alternaria from './by-domain/alternaria'
import * as beeHours from './by-domain/beeHours'
import * as chill from './by-domain/chill'
import * as dewPoint from './by-domain/dewPoint'
import * as evapotranspiration from './by-domain/evapotranspiration'
import * as fruitGrowth from './by-domain/fruitGrowth'
import * as plantStress from './by-domain/plantStress'
import * as humidity from './by-domain/humidity'
import * as insectDegreeDays from './by-domain/insectDegreeDays'
import * as insectTrapCatches from './by-domain/insectTrapCatches'
import * as irrigationActivity from './by-domain/irrigationActivity'
import * as precipitation from './by-domain/precipitation'
import * as soil from './by-domain/soil'
import * as sprayConditions from './by-domain/sprayConditions'
import * as temperature from './by-domain/temperature'
import * as wetBulb from './by-domain/wetBulb'
import * as wind from './by-domain/wind'
import * as windMachine from './by-domain/windMachine'

export const getCacheUpdatesFromResponse = async ({
  cacheKeys,
  processedCaches,
}: {
  cacheKeys: TCurrentValuesMapCacheKeys
  processedCaches: TCurrentValuesMapProcessedCaches
}): Promise<TGetCacheUpdatesFromResponseReturn> => {
  const primaryValueGroup = getPrimaryValueGroup()

  if (isNil(primaryValueGroup)) return {}

  const commonArgs: TGetCacheUpdatesFromResponseParameters = { cacheKeys, primaryValueGroup, processedCaches }

 /* eslint-disable prettier/prettier, padding-line-between-statements */
  if (primaryValueGroup === 'air_temperature') return temperature.getResponseAndShapeForCacheUpdate(commonArgs)
  if (primaryValueGroup === 'almond_bloom') return almondBloom.getResponseAndShapeForCacheUpdate(commonArgs)
  if (primaryValueGroup === 'almond_hull_split') return almondHullSplit.getResponseAndShapeForCacheUpdate(commonArgs)
  if (primaryValueGroup === 'alternaria') return alternaria.getResponseAndShapeForCacheUpdate(commonArgs)
  if (primaryValueGroup === 'bee_hours') return beeHours.getResponseAndShapeForCacheUpdate(commonArgs)
  if (primaryValueGroup === 'chill') return chill.getResponseAndShapeForCacheUpdate(commonArgs)
  if (primaryValueGroup.includes('degree_days_insect_id_')) return insectDegreeDays.getResponseAndShapeForCacheUpdate(commonArgs)
  if (primaryValueGroup === 'dew_point') return dewPoint.getResponseAndShapeForCacheUpdate(commonArgs)
  if (primaryValueGroup === 'evapotranspiration') return evapotranspiration.getResponseAndShapeForCacheUpdate(commonArgs)
  if (primaryValueGroup === 'fruit_growth') return fruitGrowth.getResponseAndShapeForCacheUpdate(commonArgs)
  if (primaryValueGroup === 'plant_stress') return plantStress.getResponseAndShapeForCacheUpdate(commonArgs)
  if (primaryValueGroup === 'humidity') return humidity.getResponseAndShapeForCacheUpdate(commonArgs)
  if (primaryValueGroup === 'irrigation_activity') return irrigationActivity.getResponseAndShapeForCacheUpdate(commonArgs)
  if (primaryValueGroup === 'precipitation') return precipitation.getResponseAndShapeForCacheUpdate(commonArgs)
  if (primaryValueGroup === 'soil') return soil.getResponseAndShapeForCacheUpdate(commonArgs)
  if (primaryValueGroup === 'spray_conditions') return sprayConditions.getResponseAndShapeForCacheUpdate(commonArgs)
  if (primaryValueGroup.includes('trap_catches_insect_id_')) return insectTrapCatches.getResponseAndShapeForCacheUpdate(commonArgs)
  if (primaryValueGroup === 'wet_bulb') return wetBulb.getResponseAndShapeForCacheUpdate(commonArgs)
  if (primaryValueGroup === 'wind') return wind.getResponseAndShapeForCacheUpdate(commonArgs)
  if (primaryValueGroup === 'wind_machine') return windMachine.getResponseAndShapeForCacheUpdate(commonArgs)
  /* eslint-enable prettier/prettier, padding-line-between-statements */

  return {}
}
