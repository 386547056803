import { routes } from '@semios/app-platform-banyan-route-definitions'
import { VIEW_PESTS_DEGREE_DAYS_ID_insectId } from '@semios/app-platform-value-type-definitions'
import { DropdownSelectorBlock } from 'App/Map/PanelDetails/SectionTitleBars/DropdownSelectorBlock/DropdownSelectorBlock'
import { propertyLacksPermissionSectionMaker } from 'App/Map/PanelDetails/_utils/propertyLacksPermissionSectionMaker'
import { selectedPropertyHasPermission } from 'App/Map/PanelDetails/_utils/selectedPropertyHasPermission'
import type { GridTableContentPestSection, GridTableContentSectionItem } from 'components/GridTable/types'
import { translate } from 'i18n/i18n'
import moment from 'moment-timezone'
import { fieldAssetStore } from 'stores/fieldAssetStore'
import { selectedFieldAssetsStore, TSelectedFieldAssetsStoreState } from 'stores/selectedFieldAssetsStore'
import {
  selectedValueGroupsStore,
  TValueGroup,
} from 'stores/selectedValueGroupsStore/selectedValueGroupsStore'
import { sortByKey } from 'utils/sortByKey'
import { unitConverter } from 'utils/unitConverter/unitConverter'
import {
  blockTimestampToValuesDictionaryMaker,
  defaultValuesRowHeight,
  regionsTimestampToValuesDictionaryMaker,
} from '../_utils'
import type { VV } from '@semios/app-platform-value-type-definitions'
import { EAggregationInterval } from 'App/Map/types'
import { doesSelectedBlockHaveValueTypes } from 'utils/doesSelectedFieldAssetHaveValueTypes'
import { INSECT_DEGREE_DAY_VALUE_KEY_PREFIX } from 'utils/insectRequestValueKeyPrefix'
import type { TPestSectionCategory } from 'App/Map/PanelDetails/_utils/sortPestSections'
import { selectedRegionHasPermission } from 'App/Map/PanelDetails/_utils/selectedRegionHasPermission'
import { getSelectedRegion } from 'utils/getSelectedRegion'
import { isUserOnlyAFreeRegionalUser } from 'utils/isUserOnlyAFreeRegionalUser'
import { regionLacksPermissionSectionMaker } from 'App/Map/PanelDetails/_utils/regionLacksPermissionSectionMaker'
import { smallStore } from 'stores/smallStore'

const preferredAggregationInterval = { preferredAggregationInterval: EAggregationInterval.DAILY } as const

const getValueTypeIdForInsectId = (insectId: number) => {
  return `${INSECT_DEGREE_DAY_VALUE_KEY_PREFIX}${insectId}` as VV.DomainTypes.DegreeDaysInsect.TTimeseriesValueTypeKeysMerged
}

const getValuesRequestedForRegions = ({
  selectedFieldAssets,
  selectedValueGroups,
}: {
  selectedValueGroups: ReturnType<typeof selectedValueGroupsStore.getState>['selectedValueGroups']
  selectedFieldAssets: TSelectedFieldAssetsStoreState
}): Partial<routes.Values.Request> => {
  const selectedProperty = selectedFieldAssets.property
  const selectedRegion = getSelectedRegion({ selectedFieldAssets, selectedProperty })

  if (!selectedRegion) return {}

  const valuesRequested: Partial<
    Record<
      VV.DomainTypes.DegreeDaysInsect.TTimeseriesValueTypeKeysMerged,
      typeof preferredAggregationInterval
    >
  > = {}

  const valuesForRegion = fieldAssetStore.getState()?.regions?.[selectedRegion]?.valuesTimeseries

  Object.entries(selectedValueGroups).forEach(([valueGroup, isActive]) => {
    if (!!isActive && valueGroup.includes('degree_days_insect_id_')) {
      const insectId = Number(valueGroup.split('_').slice(-1)[0])

      if (
        !selectedRegionHasPermission({
          permission: VIEW_PESTS_DEGREE_DAYS_ID_insectId(insectId),
          regionId: selectedRegion,
        })
      )
        return

      if (!valuesForRegion?.includes(getValueTypeIdForInsectId(insectId))) return

      valuesRequested[getValueTypeIdForInsectId(insectId)] = preferredAggregationInterval
    }
  })

  const valuesToBeRequested: Partial<routes.Values.Request> = {
    regions: {
      regionIds: [selectedRegion],
      valuesRequested,
    },
  }

  return valuesToBeRequested
}

const getValuesRequestedForBlocks = ({
  selectedValueGroups,
  selectedFieldAssets,
}: {
  selectedValueGroups: ReturnType<typeof selectedValueGroupsStore.getState>['selectedValueGroups']
  selectedFieldAssets: TSelectedFieldAssetsStoreState
}): Partial<routes.Values.Request> => {
  if (!selectedFieldAssets.block) return {}

  const valuesRequested: Partial<
    Record<
      VV.DomainTypes.DegreeDaysInsect.TTimeseriesValueTypeKeysMerged,
      typeof preferredAggregationInterval
    >
  > = {}

  Object.entries(selectedValueGroups).forEach(([valueGroup, isActive]) => {
    if (!!isActive && valueGroup.includes('degree_days_insect_id_')) {
      const insectId = Number(valueGroup.split('_').slice(-1)[0])

      if (!selectedPropertyHasPermission({ permission: VIEW_PESTS_DEGREE_DAYS_ID_insectId(insectId) })) return

      valuesRequested[getValueTypeIdForInsectId(insectId)] = preferredAggregationInterval
    }
  })

  if (Object.keys(valuesRequested).length === 0) return {}

  const valuesToBeRequested: Partial<routes.Values.Request> = {}

  if (
    Object.keys(valuesRequested).length &&
    doesSelectedBlockHaveValueTypes({ valuesTimeseries: Object.keys(valuesRequested) })
  )
    valuesToBeRequested.blocks = {
      blockIds: [selectedFieldAssets.block],
      valuesRequested,
    }

  return valuesToBeRequested
}

export const apiArgs = ({
  selectedValueGroups,
  selectedFieldAssets,
}: {
  selectedValueGroups: ReturnType<typeof selectedValueGroupsStore.getState>['selectedValueGroups']
  selectedFieldAssets: TSelectedFieldAssetsStoreState
}): Partial<routes.Values.Request> => {
  const blockValues = getValuesRequestedForBlocks({ selectedValueGroups, selectedFieldAssets })
  const regionValues = getValuesRequestedForRegions({ selectedValueGroups, selectedFieldAssets })

  return { ...blockValues, ...regionValues }
}

export const content = ({
  data,
  selectedValueGroups,
}: {
  data: routes.Values.Response
  selectedValueGroups: ReturnType<typeof selectedValueGroupsStore.getState>['selectedValueGroups']
}): GridTableContentPestSection[] => {
  const insects = fieldAssetStore.getState()?.insects

  if (!insects) return []

  const selectedFieldAssets = selectedFieldAssetsStore.getState()
  const selectedProperty = selectedFieldAssets.property
  const selectedRegion = getSelectedRegion({ selectedFieldAssets, selectedProperty })
  const blockId = String(selectedFieldAssetsStore.getState().block)
  const pestSectionCategory: TPestSectionCategory = 'degree-days'

  const timezone =
    fieldAssetStore.getState()?.properties?.[Number(selectedFieldAssetsStore.getState()?.property)]
      ?.timezone ?? moment.tz.guess()

  // TODO - this ends up with empty rows for properties that shouldn't have DD
  const degreeDaysContent: GridTableContentPestSection[] = Object.values(insects)
    .filter((insect) => selectedValueGroups[`degree_days_insect_id_${insect.insectId}` as TValueGroup])
    // TODO: sort based on t() of insect
    // TODO: rename to insectName
    .sort(sortByKey('name'))
    .map(({ insectId }) => {
      const typeKey = getValueTypeIdForInsectId(insectId)
      const permission = VIEW_PESTS_DEGREE_DAYS_ID_insectId(insectId)
      const regionHasPermission = selectedRegionHasPermission({ permission, regionId: selectedRegion })

      const hasPermission =
        selectedPropertyHasPermission({
          permission: VIEW_PESTS_DEGREE_DAYS_ID_insectId(insectId),
        }) ||
        (isUserOnlyAFreeRegionalUser() && regionHasPermission)

      const commonReturnItems = {
        title: unitConverter.insectDegreeDays(null, { insectId }).titleWithoutUnit(),
        titleChildren: <DropdownSelectorBlock valuesTimeseriesToFilterOn={[typeKey]} />,
        id: `summary-grid-insect-degree-days-insect-id-${insectId}-${blockId}`,
        pestSectionCategory,
        insectId,
      }

      if (!hasPermission && selectedProperty) {
        return { ...propertyLacksPermissionSectionMaker(commonReturnItems), pestSectionCategory, insectId }
      }

      if (!hasPermission && !selectedProperty) {
        return { ...regionLacksPermissionSectionMaker(commonReturnItems), pestSectionCategory, insectId }
      }

      const blockHasValueTypes = doesSelectedBlockHaveValueTypes({ valuesTimeseries: [typeKey] })
      const items: GridTableContentSectionItem[] = []

      if (blockHasValueTypes) {
        const biofixDates: string[] =
          data?.blocks?.[blockId]?.values?.[typeKey]?.[0]?.metadata?.biofixDates ?? []

        const formattedBiofixDates = biofixDates
          .sort((a, b) => +new Date(a) - +new Date(b))
          .map((bfd) => translate.dates.format(moment.tz(bfd, timezone), 'MMM D, YYYY'))

        const blockItem: GridTableContentSectionItem = {
          id: `insect-degree-days-cumulative-insect-id-${insectId}`,
          label: (
            <div>
              {formattedBiofixDates.length === 0 ? (
                translate.phrases.banyanApp('Since Biofix')
              ) : (
                <translate.Phrases.banyanApp
                  k="Since Biofix<br />({{formattedBiofixDates, list}})"
                  v={{ formattedBiofixDates }}
                  formatParams={{ formattedBiofixDates: { style: 'short', type: 'unit' } }}
                />
              )}
            </div>
          ),
          height: defaultValuesRowHeight,
          labelMinWidth: biofixDates.length > 1 ? 220 : 160,
          valueMinWidth: 130,
          render: (dataPoint: { timestamp: string; value: number | null } | undefined) => {
            if (dataPoint === undefined || dataPoint === null || dataPoint.value === null)
              return translate.phrases.templates('-')

            return <>{unitConverter.insectDegreeDaysCumulative(dataPoint.value).valueWithSuffix()}</>
          },
          values: blockTimestampToValuesDictionaryMaker(data, typeKey, blockId),
        }

        items.push(blockItem)
      }

      const showRegions = smallStore.getState().showRegionalData && regionHasPermission && selectedRegion

      if (showRegions) {
        const biofixDates: string[] =
          data?.regions?.[selectedRegion]?.values?.[typeKey]?.[0]?.metadata?.biofixDates ?? []

        const regionName = items.length ? fieldAssetStore.getState()?.regions?.[selectedRegion]?.name : ''

        const formattedBiofixDates = biofixDates
          .sort((a, b) => +new Date(a) - +new Date(b))
          .map((bfd) => translate.dates.format(moment.tz(bfd, timezone), 'MMM D, YYYY'))

        // TODO: this label isn't great...
        const label = (
          <div>
            {formattedBiofixDates.length === 0 ? (
              translate.phrases.templates('{{labelA}} {{labelB}}', {
                labelA: regionName,
                labelB: translate.phrases.banyanApp('Since Biofix'),
              })
            ) : (
              <>
                <span>{`${regionName} `}</span>
                <translate.Phrases.banyanApp
                  k="Since Biofix<br />({{formattedBiofixDates, list}})"
                  v={{ formattedBiofixDates }}
                  formatParams={{ formattedBiofixDates: { style: 'short', type: 'unit' } }}
                />
              </>
            )}
          </div>
        )

        const regionItem: GridTableContentSectionItem = {
          id: `insect-degree-days-regional-insect-id-${insectId}`,
          label,
          height: defaultValuesRowHeight,
          labelMinWidth: 160,
          valueMinWidth: 120,
          render: (dataPoint: { value: number | null | undefined }) => {
            if (dataPoint === undefined || dataPoint === null || dataPoint.value === null)
              return translate.phrases.templates('-')

            return <>{unitConverter.insectDegreeDays(dataPoint.value, { insectId }).valueWithSuffix()}</>
          },
          values: regionsTimestampToValuesDictionaryMaker(data, typeKey, selectedRegion),
        }

        items.push(regionItem)
      }

      return {
        ...commonReturnItems,
        items: items.filter(Boolean),
      }
    })

  return degreeDaysContent
}
