import { Tooltip, useMantineTheme } from '@mantine/core'
import React from 'react'
import { translate } from 'i18n/i18n'
import { BATTERY_BARS_COLORS, BATTERY_THRESHOLDS, getBatteryLevel } from '../../utils/thresholds'
import { TNodeType } from '@semios/app-platform-banyan-route-definitions/src/shared-types'

interface BatteryStrengthProps {
  voltage: number
  nodeType: TNodeType
}

export const BatteryStrength: React.FC<BatteryStrengthProps> = ({ voltage, nodeType }) => {
  const theme = useMantineTheme()
  const batteryLevel = getBatteryLevel(nodeType, voltage)
  const batteryBars = BATTERY_THRESHOLDS[nodeType]

  return (
    <Tooltip label={translate.phrases.placeholder('Battery: {{battery}}', { battery: voltage.toString() })}>
      <div
        css={{
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
          position: 'relative',
          height: '1em',
          top: '2px',
        }}
      >
        {batteryBars?.map((threshold, index) => (
          <div
            key={index}
            css={{
              width: 5,
              height: 15,
              borderRadius: 2,
              marginRight: 5,
              backgroundColor:
                threshold <= voltage ? BATTERY_BARS_COLORS[batteryLevel] : theme.colors.grey[2],
            }}
          />
        ))}
      </div>
    </Tooltip>
  )
}
