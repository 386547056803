import { mapControlsStore } from 'stores/mapControlsStore/mapControlsStore'
import { MAP_VISUAL } from 'stores/mapControlsStore/types'
import { mapStore } from 'stores/mapStore'

export enum ZOOM_VISIBILITY {
  OK = 'OK',
  TOO_FAR = 'TOO_FAR',
  NOT_IMPLEMENTED = 'NOT_IMPLEMENTED',
}

export const getZoomThresholds = (currentZoom: number): Record<MAP_VISUAL, ZOOM_VISIBILITY> => {
  // zoom: the higher the number, the closer to the earth
  return {
    [MAP_VISUAL.PROPERTY]: (() => {
      return ZOOM_VISIBILITY.OK
    })(),
    [MAP_VISUAL.POINT]: (() => {
      if (currentZoom >= 13) return ZOOM_VISIBILITY.OK
      else return ZOOM_VISIBILITY.TOO_FAR
    })(),
    [MAP_VISUAL.BLOCK]: (() => {
      if (currentZoom > 13) return ZOOM_VISIBILITY.OK

      return ZOOM_VISIBILITY.TOO_FAR
    })(),
    [MAP_VISUAL.SCD]: (() => {
      if (currentZoom > 13) return ZOOM_VISIBILITY.OK

      return ZOOM_VISIBILITY.TOO_FAR
    })(),
    [MAP_VISUAL.TRAP]: (() => {
      if (currentZoom >= 13) return ZOOM_VISIBILITY.OK
      else return ZOOM_VISIBILITY.TOO_FAR
    })(),
    [MAP_VISUAL.REGION]: (() => {
      // if (currentZoom > 13) return ZOOM_VISIBILITY.TOO_FAR

      // if (currentZoom < 6) return ZOOM_VISIBILITY.TOO_FAR

      return ZOOM_VISIBILITY.OK
    })(),
    [MAP_VISUAL.IRRIGATION_ZONE]: (() => {
      if (currentZoom >= 13) return ZOOM_VISIBILITY.OK
      else return ZOOM_VISIBILITY.TOO_FAR
    })(),
  }
}

// a bit of UX for somewhat intelligently deciding when to view blocks vs heatmap points
mapStore.subscribeToChanges(
  (s) => s.zoom,
  (newZoom, oldZoom) => {
    if (newZoom <= 14) {
      mapControlsStore.setState((s) => ({ ...s, useAggregation: true }))
    }

    if (newZoom >= 15 && oldZoom <= 14) {
      mapControlsStore.setState((s) => ({ ...s, useAggregation: false }))
    }
  },
)
