import { routes } from '@semios/app-platform-banyan-route-definitions'
import { TFieldAssetKeyTypes } from 'App/Map/types'
import { TCurrentValuesMapProcessedCaches } from '../_types'
import { getPropertyIdsForAllPropertiesInVisibleRegions } from './getPropertyIdsForAllPropertiesInVisibleRegions'
import { smallStore } from 'stores/smallStore'
import { VC } from '@semios/app-platform-value-type-definitions'

export const generateWindAPIArgs = ({
  heatmapExtremesForAllPropertiesInVisibleRegions,
  processedCaches,
  inBlockPoint,
  outOfBlockPoint,
  property,
  region,
}: {
  heatmapExtremesForAllPropertiesInVisibleRegions: boolean
  processedCaches: TCurrentValuesMapProcessedCaches
  inBlockPoint?: {
    windSpeedValueType: keyof VC.DomainTypes.Wind.TCurrentValuesReturnIgnoringKeying['points']
    windDirectionValueType: keyof VC.DomainTypes.Wind.TCurrentValuesReturnIgnoringKeying['points']
  }
  outOfBlockPoint?: {
    windSpeedValueType: keyof VC.DomainTypes.Wind.TCurrentValuesReturnIgnoringKeying['points']
    windDirectionValueType: keyof VC.DomainTypes.Wind.TCurrentValuesReturnIgnoringKeying['points']
  }
  property?: {
    windSpeedValueType: keyof VC.DomainTypes.Wind.TCurrentValuesReturnIgnoringKeying['properties']
    windDirectionValueType: keyof VC.DomainTypes.Wind.TCurrentValuesReturnIgnoringKeying['properties']
  }
  region?: {
    windSpeedValueType: keyof VC.DomainTypes.Wind.TCurrentValuesReturnIgnoringKeying['regions']
    windDirectionValueType: keyof VC.DomainTypes.Wind.TCurrentValuesReturnIgnoringKeying['regions']
  }
}): routes.ValuesCurrent.Request => {
  const returner: routes.ValuesCurrent.Request = {}

  if (property && !!processedCaches.propertyValues.itemsWithinView.length) {
    const propertyIds = heatmapExtremesForAllPropertiesInVisibleRegions
      ? getPropertyIdsForAllPropertiesInVisibleRegions(
          processedCaches.propertyValues.itemsWithinView.map((p) => Number(p.id)),
        )
      : processedCaches.propertyValues.itemsWithinView.map((p) => Number(p.id))

    returner.properties = {
      propertyIds,
      values: { [property.windSpeedValueType]: true, [property.windDirectionValueType]: true },
    }
  }

  if (region && smallStore.getState()?.showRegionalData) {
    const regionIds = processedCaches.regionValues.itemsWithinView.map((r) => r.id)

    if (regionIds.length) {
      returner.regions = {
        regionIds: regionIds,
        values: {
          [region.windSpeedValueType]: true,
          [region.windDirectionValueType]: true,
        },
      }
    }
  }

  const shouldFetchPoints = inBlockPoint || outOfBlockPoint
  const lngLatsToFetch: TFieldAssetKeyTypes.TLngLat[] = []
  const pointsValuesToFetch: NonNullable<routes.ValuesCurrent.Request['points']>['values'] = {}

  const addPointsFromCache = (
    pointData: {
      windSpeedValueType: keyof NonNullable<routes.ValuesCurrent.Request['points']>['values']
      windDirectionValueType: keyof NonNullable<routes.ValuesCurrent.Request['points']>['values']
    },
    itemIdsWithinView: typeof processedCaches.stationValues.itemsWithinView,
  ) => {
    itemIdsWithinView.forEach((c) => {
      lngLatsToFetch.push(c.meta.lngLat)
    })

    pointsValuesToFetch[pointData.windSpeedValueType] = true

    pointsValuesToFetch[pointData.windDirectionValueType] = true
  }

  if (shouldFetchPoints && processedCaches.stationValues.itemsWithinView.length) {
    if (inBlockPoint) {
      addPointsFromCache(inBlockPoint, processedCaches.stationValues.itemsWithinView)
    }

    if (outOfBlockPoint) {
      addPointsFromCache(outOfBlockPoint, processedCaches.stationValues.itemsWithinView)
    }

    if (lngLatsToFetch.length) {
      returner.points = {
        lngLats: lngLatsToFetch,
        values: pointsValuesToFetch,
      }
    }
  }

  return returner
}
