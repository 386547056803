import { apiFetch } from 'utils/apiFetch'
import { routes } from '@semios/app-platform-banyan-route-definitions'
import { serviceCenterStore } from 'App/ServiceCenter/store/serviceCenterStore'

export type PlannedGatewayRepositionMeta = routes.ServiceCenterPlannedGatewayReposition.Request

const request = async (meta: PlannedGatewayRepositionMeta): Promise<PlannedGatewayRepositionMeta> => {
  await apiFetch<
    routes.ServiceCenterPlannedGatewayReposition.Request,
    routes.ServiceCenterPlannedGatewayReposition.Response
  >({
    url: routes.ServiceCenterPlannedGatewayReposition.path,
    body: meta,
    params: {
      method: 'PUT',
    },
  })

  return meta
}

const callback = (meta: PlannedGatewayRepositionMeta): void => {
  serviceCenterStore.actions.repositionPseudoNodes({ [meta.pseudogatewayId]: meta.location })
}

export const serviceCenterPlannedGatewayReposition = {
  request,
  callback,
}
