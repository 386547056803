import { Select } from '@mantine/core'
import { MANTINE_DATE_RANGE_PICKER_WIDTH } from 'components/DateRangePicker/MantineDateRangePickerWithFooter/MantineDateRangePickerWithFooter'
import { translate } from 'i18n/i18n'
import moment from 'moment-timezone'
import { useCallback, useMemo } from 'react'
import { colors } from 'settings/colors'
import { HUB_CONNECTOR_ENVIRONMENT_IS_SWAGWORLD } from 'settings/hubConnectorEnvironment'
import { SharedSettings } from 'settings/SharedSettings'
import { detailsPanelStore } from 'stores/detailsPanelStore'
import { useTimezoneForSelectedPropertyOrRegion } from '../../_utils/useTimezoneForSelectedPropertyOrRegion'
import { makeDateRangeTextFromCompareSeasonsInterval } from '../_utils/makeDateRangeTextFromCompareSeasonsInterval'
import { smallStore } from 'stores/smallStore'

export const CompareSeasonsSelect = () => {
  const handleOnChange = useCallback(
    (value: string | null) => {
      detailsPanelStore.setState((prev) => ({
        ...prev,
        compareSeasonsInterval: Number(value),
      }))
    },
    [detailsPanelStore],
  )

  const { compareSeasonsInterval, dateFrom, dateTo } = detailsPanelStore.useSelector((s) => ({
    dateFrom: s.dateFrom,
    dateTo: s.dateTo,
    compareSeasonsInterval: s.compareSeasonsInterval,
  }))

  const showRegionalData = smallStore.useSelector((s) => s.showRegionalData)
  const timezone = useTimezoneForSelectedPropertyOrRegion()

  const data = useMemo(() => {
    const earliestDateOfData = moment.tz(SharedSettings.FIRST_DAY_OF_DATA_FOR_MANY_THINGS, timezone)
    const yearsToGoBack = moment.tz(timezone).diff(earliestDateOfData, 'years')
    const returner: { value: string; label: string }[] = []

    if (compareSeasonsInterval)
      returner.push({ value: '', label: translate.phrases.banyanApp("Don't Compare") })

    for (let yearsAgo = 1; yearsAgo <= yearsToGoBack; yearsAgo++) {
      if (+moment.tz(dateFrom, timezone).subtract(yearsAgo, 'years') >= +earliestDateOfData) {
        returner.push({
          value: String(yearsAgo),
          label: makeDateRangeTextFromCompareSeasonsInterval({
            dateFrom,
            dateTo,
            interval: yearsAgo,
            timezone,
          }),
        })
      }
    }

    return returner
  }, [compareSeasonsInterval, dateFrom, dateTo, timezone])

  return (
    <div css={{ display: 'flex', position: 'relative' }}>
      {!!compareSeasonsInterval && (
        <span
          css={{
            pointerEvents: 'none',
            position: 'absolute',
            top: 3,
            left: 14,
            zIndex: 1,
            color: colors.grey800,
            fontSize: 12,
          }}
        >
          {translate.phrases.banyanApp('Compared With:')}
        </span>
      )}
      <Select
        styles={{
          ...SharedSettings.MANTINE_SELECT_RIGHT_ICON_CHANGER,
          input: {
            ...SharedSettings.MANTINE_SELECT_RIGHT_ICON_CHANGER.input,
            width: MANTINE_DATE_RANGE_PICKER_WIDTH,
            height: 42,
            paddingTop: compareSeasonsInterval ? 12 : 0,
            border: compareSeasonsInterval ? `1px solid ${colors.grey800}` : undefined,
          },
        }}
        placeholder={translate.phrases.banyanApp('Select to Compare')}
        value={String(compareSeasonsInterval)}
        data={data}
        onChange={handleOnChange}
        disabled={showRegionalData}
        withinPortal={HUB_CONNECTOR_ENVIRONMENT_IS_SWAGWORLD}
      />
    </div>
  )
}
