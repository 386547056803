import { selectedValueGroupsStore } from 'stores/selectedValueGroupsStore/selectedValueGroupsStore'
import { DetailsPanelDateRange } from '../../DetailsPanelDateRange/DetailsPanelDateRange'
import { CompareSeasonsSelect } from '../CompareSeasonsSelect/CompareSeasonsSelect'
import React from 'react'

export const DateRangeRow = (props: {
  selectedValueGroups: ReturnType<typeof selectedValueGroupsStore.getState>['selectedValueGroups']
}) => {
  return (
    <React.Fragment>
      <DetailsPanelDateRange selectedValueGroups={props.selectedValueGroups} />
      <CompareSeasonsSelect />
    </React.Fragment>
  )
}
