import React, { useCallback, useEffect, useState } from 'react'
import { closeModal, openModal } from '@mantine/modals'
import { translate } from 'i18n/i18n'
import * as Sentry from '@sentry/react'
import { TActiveNode, TNodeServiceIssueSummary, TServiceIssueStatus } from '../types'
import { WideHeader } from 'components/ModalDrawer/WideHeader/WideHeader'
import { getNodeTypeLabel } from '../utils/getNodeTypeLabel'
import { ServiceIssueList } from './List/List'
import { ServiceIssueDetail } from './IssueDetail/IssueDetail'
import { serviceCenterNodeServiceIssuesGet } from '../utils/api/serviceCenterNodeServiceIssuesGet'
import { showNotification } from '../../../utils/showNotification'
import { serviceCenterStore } from '../store/serviceCenterStore'
import { selectedPropertyHasPermission } from '../../Map/PanelDetails/_utils/selectedPropertyHasPermission'
import { Menu } from '@mantine/core'
import { openNodeSwapModal } from '../NodeSwapModal/NodeSwapModal'
import { openNodeRemoveModal } from '../NodeRemoveModal/NodeRemoveModal'

const MODAL_ID = 'node-maintenance'

export const checkHasIssueUpdatePermission = () =>
  selectedPropertyHasPermission({ permission: 'EDIT_SSC_NODE_ISSUE_STATUS' })

export function openNodeMaintenanceModal(node: TActiveNode, serviceIssueId?: number) {
  openModal({
    modalId: MODAL_ID,
    fullScreen: true,
    withCloseButton: false,
    padding: 0,
    children: <NodeMaintenanceModal node={node} serviceIssueId={serviceIssueId} />,
    styles: {
      content: {
        marginLeft: 'env(safe-area-inset-left)',
        marginRight: 'env(safe-area-inset-right)',
        boxShadow: 'none',
        transform: 'none !important',
      },
    },
  })
}

interface NodeMaintenanceModalProps {
  node: TActiveNode
  serviceIssueId?: number
}

export const NodeMaintenanceModal: React.FC<NodeMaintenanceModalProps> = ({
  node,
  serviceIssueId: initialSelectedServiceIssueId = null,
}) => {
  const equipmentTypeLabel = getNodeTypeLabel(node.nodeType)

  const [selectedServiceIssueId, setSelectedServiceIssueId] = useState<number | null>(
    initialSelectedServiceIssueId,
  )

  const allServiceIssues = serviceCenterStore.useSelector(serviceCenterStore.selectors.getServiceIssues)

  const [nodeServiceIssues, setNodeServiceIssues] = useState<TNodeServiceIssueSummary[]>(
    allServiceIssues[node.nodeIdentifier] || [],
  )

  const selectedServiceIssue = nodeServiceIssues.find(
    (issue) => issue.serviceIssueId === selectedServiceIssueId,
  )

  const handleOnClose = () => {
    if (selectedServiceIssue) {
      return setSelectedServiceIssueId(null)
    } else {
      closeModal(MODAL_ID)
    }
  }

  let title = translate.phrases.placeholder('{{equipmentType}} Maintenance', {
    equipmentType: equipmentTypeLabel,
  })

  if (selectedServiceIssue) {
    title = translate.phrases.placeholder('View Issue')

    if (
      checkHasIssueUpdatePermission() &&
      (selectedServiceIssue.serviceIssueStatus === TServiceIssueStatus.CREATED ||
        selectedServiceIssue.serviceIssueStatus === TServiceIssueStatus.FLAGGED_FOR_SERVICE)
    ) {
      title = translate.phrases.placeholder('Resolve Issue')
    }
  }

  const loadServiceIssues = useCallback(async (nodeIdentifier: string) => {
    try {
      const nodeServiceIssues = await serviceCenterNodeServiceIssuesGet({
        entityIdentifiers: [nodeIdentifier],
      })

      setNodeServiceIssues(nodeServiceIssues[nodeIdentifier] || [])
    } catch (error) {
      Sentry.captureException(error)

      showNotification({
        type: 'error',
        message: translate.phrases.placeholder('Error: could not load service issues for the selected node!'),
      })
    }
  }, [])

  useEffect(() => {
    loadServiceIssues(node.nodeIdentifier)
  }, [node.nodeIdentifier])

  const DropdownMenuItems: React.FC = () => {
    return (
      <>
        <Menu.Item
          onClick={() => {
            openNodeSwapModal(() => closeModal(MODAL_ID))
          }}
        >
          {translate.phrases.placeholder('Swap Node')}
        </Menu.Item>
        <Menu.Item
          onClick={() => {
            openNodeRemoveModal(() => closeModal(MODAL_ID))
          }}
        >
          {translate.phrases.placeholder('Remove Node')}
        </Menu.Item>
      </>
    )
  }

  return (
    <>
      <WideHeader
        DropdownMenuItems={DropdownMenuItems}
        title={title}
        onClose={handleOnClose}
        isSecondaryModal={!!selectedServiceIssue}
      />

      {selectedServiceIssue ? (
        <ServiceIssueDetail
          serviceIssueId={selectedServiceIssue.serviceIssueId}
          issueTypeId={selectedServiceIssue.nodeServiceIssueTypeId}
          onIssueChange={() => loadServiceIssues(node.nodeIdentifier)}
        />
      ) : (
        <ServiceIssueList
          node={node}
          serviceIssues={nodeServiceIssues}
          onSelectIssue={setSelectedServiceIssueId}
        />
      )}
    </>
  )
}
