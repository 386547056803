import { routes } from '@semios/app-platform-banyan-route-definitions'
import { StackedChartPestSection } from 'components/StackedChart/types'
import { fieldAssetStore } from 'stores/fieldAssetStore'
import { selectedFieldAssetsStore, TSelectedFieldAssetsStoreState } from 'stores/selectedFieldAssetsStore'
import { selectedValueGroupsStore } from 'stores/selectedValueGroupsStore/selectedValueGroupsStore'
import {
  insectTrapCatchesContent,
  insectTrapCatchesValues,
} from 'App/Map/PanelDetails/StackemCharts/_utils/by-domain/insectTrapCatches/by-scope/_utils/insectTrapCatches'
import { DropdownSelectorBlock } from 'App/Map/PanelDetails/SectionTitleBars/DropdownSelectorBlock/DropdownSelectorBlock'
import { unitConverter } from 'utils/unitConverter/unitConverter'
import { doesSelectedBlockHaveValueTypes } from 'utils/doesSelectedFieldAssetHaveValueTypes'
import type { TPestSectionCategory } from 'App/Map/PanelDetails/_utils/sortPestSections'
import { smallStore } from 'stores/smallStore'

export const apiArgs = ({
  selectedValueGroups,
  selectedFieldAssets,
}: {
  selectedValueGroups: ReturnType<typeof selectedValueGroupsStore.getState>['selectedValueGroups']
  selectedFieldAssets: TSelectedFieldAssetsStoreState
}): Partial<routes.Values.Request> => {
  const targetScope = selectedFieldAssets.block

  if (!targetScope) return {}

  const valuesRequested = insectTrapCatchesValues({
    selectedValueGroups,
    doesTargetHaveValueTypes: doesSelectedBlockHaveValueTypes,
  })

  if (Object.keys(valuesRequested).length === 0) return {}

  const valuesToBeRequested: Partial<routes.Values.Request> = {}

  valuesToBeRequested.blocks = {
    blockIds: [targetScope],
    valuesRequested: valuesRequested,
  }

  return valuesToBeRequested
}

export const content = ({
  data,
  selectedValueGroups,
  selectedFieldAssets,
  compareSeasonsData,
  compareRegionsData,
  regionName,
}: {
  data: routes.Values.Response
  compareSeasonsData: routes.Values.Response
  compareRegionsData?: NonNullable<routes.Values.Response['regions']>['string']
  regionName: string | null
  selectedValueGroups: ReturnType<typeof selectedValueGroupsStore.getState>['selectedValueGroups']
  selectedFieldAssets: TSelectedFieldAssetsStoreState
}): StackedChartPestSection[] => {
  const { insects } = fieldAssetStore.getState()
  const targetScopeId = String(selectedFieldAssetsStore.getState().block)
  const pestSectionCategory: TPestSectionCategory = 'trap-catches-block-level'
  const showRegionalData = smallStore.getState().showRegionalData

  return insectTrapCatchesContent({
    insects,
    selectedValueGroups,
    selectedFieldAssets,
    targetScopeId,
    doesTargetHaveValueTypes: doesSelectedBlockHaveValueTypes,
    targetScope: 'BLOCK',
    targetScopeData: data?.blocks,
    compareSeasonsTargetScopeData: compareSeasonsData?.blocks,
    compareRegionName: regionName,
    compareRegionsData,
    insectTrapCatchesConverter: unitConverter.insectTrapCatches,
    CommonReturnItemsTitleChildren: DropdownSelectorBlock,
    commonReturnItemsIdPrefix: 'insect-trap-catches-insect-id',
    pestSectionCategory,
    showRegionalData,
  })
}
