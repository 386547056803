import { apiFetch } from 'utils/apiFetch'
import { routes } from '@semios/app-platform-banyan-route-definitions'
import { serviceCenterStore } from 'App/ServiceCenter/store/serviceCenterStore'

export type GatewayRepositionMeta = routes.ServiceCenterGatewayReposition.Request

const request = async (meta: GatewayRepositionMeta): Promise<GatewayRepositionMeta> => {
  await apiFetch<
    routes.ServiceCenterGatewayReposition.Request,
    routes.ServiceCenterGatewayReposition.Response
  >({
    url: routes.ServiceCenterGatewayReposition.path,
    body: meta,
    params: {
      method: 'PUT',
    },
  })

  return meta
}

const callback = (param: GatewayRepositionMeta) => {
  serviceCenterStore.actions.repositionActiveNode({
    gatewayIdentifier: param.gatewayIdentifier,
    location: param.location,
  })
}

export const serviceCenterGatewayReposition = {
  request,
  callback,
}
