import { useState } from 'react'
import { regions } from './regionsStartup'
import { Select } from 'components/Select/Select'
import { SidekickStatements } from './SidekickStatements'

export const LandingPageExamples = () => {
  const [activeRegion, setActiveRegion] = useState<string | null>(regions[0].regionId)
  const [activeFeature, setActiveFeature] = useState<'WEATHER' | 'PEST'>('WEATHER')

  return (
    <div>
      <div css={{ display: 'flex', flexWrap: 'wrap', gap: '10' }}>
        <Select
          label="Region"
          data={regions.map((r) => ({
            value: r.regionId,
            label: r.name,
          }))}
          value={activeRegion ?? ''}
          handleOnChange={(newValue) => {
            setActiveRegion(newValue)
          }}
        />
        <Select
          label="Feature"
          data={[
            { value: 'WEATHER', label: 'Weather' },
            { value: 'PEST', label: 'Pests' },
          ]}
          value={activeFeature}
          handleOnChange={(newValue) => {
            newValue && setActiveFeature(newValue as 'WEATHER' | 'PEST')
          }}
        />
      </div>

      <SidekickStatements type={activeFeature} regionId={activeRegion ?? ''} />
    </div>
  )
}
