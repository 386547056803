import { routes } from '@semios/app-platform-banyan-route-definitions'
import { selectedPropertyHasPermission } from 'App/Map/PanelDetails/_utils/selectedPropertyHasPermission'
import { StackedChartSection } from 'components/StackedChart/types'
import {
  selectedFieldAssetsStore,
  TPointCategory,
  TSelectedFieldAssetsStoreState,
} from 'stores/selectedFieldAssetsStore'
import { selectedValueGroupsStore } from 'stores/selectedValueGroupsStore/selectedValueGroupsStore'
import { chooseAmongstUnAggHourlyAndDaily } from '../../chooseAmongstUnAggHourlyAndDaily'
import { awcHeatmap } from './awcHeatmap'
import { awcLineAndWaterEvents } from './awcLineAndWaterEvents'
import { ionicContent } from './ionicContent'
import { soilTemperature } from './soilTemperature'
import type { EAggregationInterval, VV } from '@semios/app-platform-value-type-definitions'
import { doesSelectedPointHaveValueTypes } from 'utils/doesSelectedFieldAssetHaveValueTypes'
import { detailsPanelStore } from 'stores/detailsPanelStore'

const soilPointCategory: TPointCategory = 'soilPoint'
const precipitationPointCategory: TPointCategory = 'outOfBlockPoint'

export type SoilValueType = VV.DomainTypes.Soil.TSoil | VV.DomainTypes.Soil.TSoilAggregated

export const checkPermission = () =>
  selectedPropertyHasPermission({ permission: 'VIEW_SOIL_MOISTURE_DETAILS' })

export const apiArgs = ({
  selectedValueGroups,
  selectedFieldAssets,
}: {
  selectedValueGroups: ReturnType<typeof selectedValueGroupsStore.getState>['selectedValueGroups']
  selectedFieldAssets: TSelectedFieldAssetsStoreState
}): Partial<routes.Values.Request> => {
  if (!checkPermission()) return {}

  if (!selectedValueGroups.soil || !selectedFieldAssets[soilPointCategory]) return {}

  const preferredAggregationIntervalPrecipitation = {
    preferredAggregationInterval: detailsPanelStore.getState().precipitationAggregation as
      | EAggregationInterval.DAILY
      | EAggregationInterval.HOURLY,
  }

  const preferredAggregationInterval = {
    preferredAggregationInterval: chooseAmongstUnAggHourlyAndDaily(),
  }

  const valuesRequested: Record<string, typeof preferredAggregationInterval> = {
    soilMoisture: preferredAggregationInterval,
    soilTemperature: preferredAggregationInterval,
    soilIonicContent: preferredAggregationInterval,
    precipitation: preferredAggregationIntervalPrecipitation,
  }

  if (
    !doesSelectedPointHaveValueTypes({
      valuesTimeseries: Object.keys(valuesRequested),
      pointCategory: soilPointCategory,
    })
  )
    return {}

  const uniqueLngLatsToRequest = [selectedFieldAssets[soilPointCategory]]

  if (selectedFieldAssets[precipitationPointCategory]) {
    uniqueLngLatsToRequest.push(selectedFieldAssets[precipitationPointCategory])
  }

  return {
    points: {
      lngLats: uniqueLngLatsToRequest,
      valuesRequested,
    },
  }
}

// TODO: At the moment we are only making line series with the average value. We are not yet making area series out of the min/max values.
// TODO: We should probably have selectedDepths as checkboxes (outside of Highcharts) instead of as a Highcharts legend
export const content = ({
  data,
  compareSeasonsData,
}: {
  data: routes.Values.Response
  compareSeasonsData: routes.Values.Response
}): StackedChartSection[] => {
  const soilStationLngLat = selectedFieldAssetsStore.getState()[soilPointCategory]
  const precipitationStationLngLat = selectedFieldAssetsStore.getState()[precipitationPointCategory]
  const soilCharts = []

  soilCharts.push(
    awcLineAndWaterEvents({ data, precipitationStationLngLat, soilStationLngLat, compareSeasonsData }),
  )

  soilCharts.push(awcHeatmap({ data, soilStationLngLat }))

  soilCharts.push(ionicContent({ data, soilStationLngLat }))

  soilCharts.push(soilTemperature({ data, soilStationLngLat, compareSeasonsData }))

  return soilCharts
}
