import { routes } from '@semios/app-platform-banyan-route-definitions'
import { sortPestSections } from 'App/Map/PanelDetails/_utils/sortPestSections'
import { GridTableContentPestSection, GridTableContentSection } from 'components/GridTable/types'
import { TSelectedFieldAssetsStoreState } from 'stores/selectedFieldAssetsStore'
import { selectedValueGroupsStore } from 'stores/selectedValueGroupsStore/selectedValueGroupsStore'
import * as almondBloom from '../by-domain/almondBloom/almondBloom'
import * as almondHullSplit from '../by-domain/almondHullSplit/almondHullSplit'
import * as alternaria from '../by-domain/alternaria/alternaria'
import * as beeHours from '../by-domain/beeHours/beeHours'
import * as chill from '../by-domain/chill/chill'
import * as conditions from '../by-domain/conditions/conditions'
import * as dewPoint from '../by-domain/dewPoint/dewPoint'
import * as evapotranspiration from '../by-domain/evapotranspiration/evapotranspiration'
import * as fruitGrowth from '../by-domain/fruitGrowth/fruitGrowth'
import * as humidity from '../by-domain/humidity/humidity'
import * as insectDegreeDays from '../by-domain/insectDegreeDays/insectDegreeDays'
import * as insectTrapCatches from '../by-domain/insectTrapCatches/insectTrapCatches'
import * as plantStress from '../by-domain/plantStress/plantStress'
import * as irrigationActivity from '../by-domain/irrigationActivity/irrigationActivity'
import * as precipitation from '../by-domain/precipitation/precipitation'
import * as soil from '../by-domain/soil/soil'
import * as sprayConditions from '../by-domain/sprayConditions/sprayConditions'
import * as temperature from '../by-domain/temperature/temperature'
import * as walnutBlight from '../by-domain/walnutBlight/walnutBlight'
import * as wetBulb from '../by-domain/wetBulb/wetBulb'
import * as wind from '../by-domain/wind/wind'
import * as windMachine from '../by-domain/windMachine/windMachine'

export const createContent = ({
  data,
  selectedValueGroups,
  selectedFieldAssets,
  currentValuesData,
  updateData,
  isWideScreen,
}: {
  data: routes.Values.Response
  selectedValueGroups: ReturnType<typeof selectedValueGroupsStore.getState>['selectedValueGroups']
  selectedFieldAssets: TSelectedFieldAssetsStoreState
  currentValuesData: routes.ValuesCurrent.Response
  updateData: (pathToSet: string, dataToSet: unknown) => void
  isWideScreen: boolean
}): GridTableContentSection[] => {
  const content: GridTableContentSection[] = []

  if (!data) return content

  /* ENVIRONMENTAL */
  if (!!selectedValueGroups.conditions) content.push(conditions.content({ data }))

  if (!!selectedValueGroups.dew_point) content.push(dewPoint.content({ data }))

  if (!!selectedValueGroups.humidity) content.push(humidity.content({ data }))

  if (!!selectedValueGroups.precipitation) content.push(precipitation.content({ data }))

  if (!!selectedValueGroups.air_temperature) content.push(temperature.content({ data }))

  if (!!selectedValueGroups.wet_bulb) content.push(wetBulb.content({ data }))

  if (!!selectedValueGroups.wind) content.push(wind.content({ data }))

  /* CROP DEVELOPMENT */

  if (!!selectedValueGroups.almond_bloom) content.push(almondBloom.content({ data }))

  if (!!selectedValueGroups.almond_hull_split) content.push(almondHullSplit.content({ data, isWideScreen }))

  if (!!selectedValueGroups.bee_hours) content.push(beeHours.content({ data }))

  if (!!selectedValueGroups.chill) content.push(chill.content({ data }))

  /* WATER */
  // TODO: this soilStation lookup is a little simplistic, since we could check for the presence of soilStations within the zone
  if (!!selectedValueGroups.evapotranspiration) content.push(evapotranspiration.content({ data }))

  if (!!selectedValueGroups.soil) content.push(soil.content({ data }))

  if (!!selectedValueGroups.fruit_growth) content.push(fruitGrowth.content({ data }))

  if (!!selectedValueGroups.plant_stress) content.push(plantStress.content({ data }))

  /* SPRAYS */
  if (!!selectedValueGroups.spray_conditions) content.push(sprayConditions.content({ data }))

  /* DISEASES */
  if (!!selectedValueGroups.alternaria) content.push(alternaria.content({ data, updateData }))

  if (!!selectedValueGroups.walnut_blight) content.push(walnutBlight.content({ data }))

  /* Pests */
  const pestContent: GridTableContentPestSection[] = []

  if (
    Object.entries(selectedValueGroups).some(
      ([valueGroup, isActive]) => !!isActive && valueGroup.startsWith('degree_days_insect_id_'),
    )
  )
    pestContent.push(...insectDegreeDays.content({ data, selectedValueGroups }))

  if (
    Object.entries(selectedValueGroups).some(
      ([valueGroup, isActive]) => !!isActive && valueGroup.startsWith('trap_catches_insect_id_'),
    )
  )
    pestContent.push(...insectTrapCatches.content({ data, selectedFieldAssets, selectedValueGroups }))

  // it's nice to keep pest information grouped together by pest
  pestContent.sort(sortPestSections)

  content.push(...pestContent)

  if (
    Object.entries(selectedValueGroups).some(
      ([valueGroup, isActive]) => !!isActive && valueGroup.startsWith('irrigation_activity'),
    )
  ) {
    content.push(...irrigationActivity.content({ data, selectedFieldAssets, currentValuesData }))
  }

  if (!!selectedValueGroups.wind_machine) content.push(windMachine.content({ data }))

  // TODO: sort based on translated name?
  return content.sort((a, b) => {
    // sort here will put items with no data down at the bottom
    return Number(!!a.defaultCollapsed) - Number(!!b.defaultCollapsed)
  })
}
