import { TRGBAColorWith1AtTheEnd, alphaColorReplacer, colors } from 'settings/colors'

export const getIrrigationEventColor = (
  emitterColor: TRGBAColorWith1AtTheEnd,
  isFuture?: boolean | null,
  status?: string | null,
) => {
  if (status === 'DRAFT') {
    return colors.draftCreate
  }

  if (isFuture) return alphaColorReplacer(emitterColor, 0.5)

  return emitterColor
}
