import { routes } from '@semios/app-platform-banyan-route-definitions'
import type {
  TChartSeries,
  TChartSeriesWithTRGBAColorWith1AtTheEndColor,
} from 'components/StackedChart/types'
import { translate } from 'i18n/i18n'
import { isNil } from 'lodash'
import { colors, TRGBAColorWith1AtTheEnd } from 'settings/colors'
import { detailsPanelStore } from 'stores/detailsPanelStore'
import { sortNumber } from 'utils/sortNumber'
import { unitConverter } from 'utils/unitConverter/unitConverter'
import { makeCompareSeasonsSeriesFromRegularSeries } from '../../_utils/makeCompareSeasonsSeriesFromRegularSeries'
import { isAggregatedType } from '../../_utils/isAggregatedValueType'
import { roundToDecimalPlaces } from '@semios/app-platform-common'
import { PREDICTION_KEY } from './predictionKey'
import { getAverageSeriesData } from './getAverageSeriesData'
import { getSoilMoistureHeightColor } from './getSoilMoistureHeightColor'
import { getFormattedSoilDepthValue } from './getFormattedSoilDepthValue'

export const makeSoilSeries: ({
  data,
  compareSeasonsData,
  soilStationLngLat,
  valueType,
}: {
  data: routes.Values.Response
  compareSeasonsData: routes.Values.Response
  soilStationLngLat: string | null
  valueType: 'soilMoisture' | 'soilTemperature'
}) => TChartSeries[] = ({ data, compareSeasonsData, soilStationLngLat, valueType }) => {
  const soilData = data?.points?.[String(soilStationLngLat)]?.values?.[valueType]

  if (!soilData || !soilData.length) return []

  const { showSelectedSoilDepths, compareSeasonsInterval, soilVisibility } = detailsPanelStore.getState()
  const depthUnitType = unitConverter.soilDepth().suffix()
  const unitConverterToUse = valueType === 'soilMoisture' ? 'soilAwc' : 'soilTemperature'
  const seriesZIndex = 100
  const series: TChartSeries[] = []
  const averageRegularSeriesDataDictionary: Record<number, number[]> = {}
  const averageCompareSeasonsSeriesDataDictionary: Record<number, number[]> = {}

  soilData
    .filter(({ metadata }) => metadata.depth !== PREDICTION_KEY)
    .forEach(({ metadata, timeseries }) => {
      const formattedDepthValue = getFormattedSoilDepthValue(metadata.depth)
      const fixedDepth = Math.abs(Number(metadata.depth))
      const visible = !!soilVisibility[fixedDepth]
      const seriesName = `${formattedDepthValue} ${depthUnitType}`

      const regularSeries: TChartSeriesWithTRGBAColorWith1AtTheEndColor = {
        color: getSoilMoistureHeightColor(fixedDepth) as TRGBAColorWith1AtTheEnd,
        type: 'line',
        data: timeseries.map((d) => {
          const epoch = +new Date(d.timestamp)

          const value = unitConverter[unitConverterToUse](
            isAggregatedType(d.value) ? d.value?.average : d.value,
          ).value()

          if (visible) {
            averageRegularSeriesDataDictionary[epoch] = averageRegularSeriesDataDictionary[epoch] ?? []

            if (!isNil(value)) averageRegularSeriesDataDictionary[epoch].push(value)
          }

          return [epoch, value]
        }),
        lineWidth: showSelectedSoilDepths ? 2 : 0,
        name: seriesName,
        enableMouseTracking: showSelectedSoilDepths,
        hideFromTooltip: !showSelectedSoilDepths || !visible, // hide from tooltip when show selected is off
        tooltip: {
          valueDecimals: unitConverter[unitConverterToUse]?.().defaultNumberOfDecimalPlaces(),
          valueSuffix: unitConverter[unitConverterToUse]?.().suffix(),
        },
        visible,
        yAxis: 0,
        zIndex: seriesZIndex,
        states: {
          inactive: {
            opacity: showSelectedSoilDepths ? 1 : 0,
          },
        },
        id: `${seriesName}-${valueType}`,
      }

      series.push(regularSeries)

      if (compareSeasonsInterval) {
        const compareSeasonsTimeseriesForThisDepth =
          compareSeasonsData?.points?.[String(soilStationLngLat)]?.values?.[valueType]?.find(
            (s) => s.metadata.depth === metadata.depth,
          )?.timeseries ?? []

        series.push(
          makeCompareSeasonsSeriesFromRegularSeries(regularSeries, {
            data: compareSeasonsTimeseriesForThisDepth.map(
              (d: typeof soilData[number]['timeseries'][number]) => {
                const epoch = +new Date(d.timestamp)

                const value = unitConverter[unitConverterToUse](
                  isAggregatedType(d.value) ? d.value?.average : d.value,
                ).value()

                if (visible) {
                  averageCompareSeasonsSeriesDataDictionary[epoch] =
                    averageCompareSeasonsSeriesDataDictionary[epoch] ?? []

                  if (!isNil(value)) averageCompareSeasonsSeriesDataDictionary[epoch].push(value)
                }

                return [epoch, value]
              },
            ),
          }),
        )
      }
    })

  series.sort((a, b) => {
    const aDepth = a.name?.split(' ')[0]
    const bDepth = b.name?.split(' ')[0]

    return Number(aDepth) - Number(bDepth)
  })

  const predictionSeries = soilData.find(({ metadata }) => metadata.depth === PREDICTION_KEY)

  const forecastedSeriesData = (predictionSeries?.timeseries ?? []).map((d) => {
    const epoch = +new Date(d.timestamp)

    const value = unitConverter[unitConverterToUse](
      isAggregatedType(d.value) ? d.value?.average : d.value,
    ).value()

    return [epoch, value]
  })

  const averageRegularSeriesData = getAverageSeriesData(
    averageRegularSeriesDataDictionary,
    unitConverter[unitConverterToUse]().defaultNumberOfDecimalPlaces(),
  ).sort(([a], [b]) => sortNumber(a, b))

  const commonChartProperties = {
    isAverage: true,
    yAxis: 0,
    zoneAxis: 'x',
    showInLegend: false,
    visible: true,
    zIndex: seriesZIndex,
    color: colors.midnight,
    compareSeasonsColor: colors.firebrick,
    enableMouseTracking: true,
    showInNavigator: false,
    lineWidth: 2,
    states: {
      // don't fade out these series when hovering over the chart
      inactive: {
        opacity: 1,
      },
    },
  }

  const forecastedSeries: TChartSeriesWithTRGBAColorWith1AtTheEndColor = {
    ...commonChartProperties,
    id: `forecasted-${valueType}`,
    data: forecastedSeriesData.sort(([a], [b]) => sortNumber(a, b)),
    name: translate.phrases.banyanApp('Prediction for favorite depths'),
    tooltip: {
      valueDecimals: unitConverter[unitConverterToUse]?.().defaultNumberOfDecimalPlaces(),
      valueSuffix: unitConverter[unitConverterToUse]?.().suffix(),
      // @ts-ignore
      dashed: true,
    },
    type: 'line',
  }

  const averageRegularSeries: TChartSeriesWithTRGBAColorWith1AtTheEndColor = {
    ...commonChartProperties,
    id: `averageSelected-${valueType}`,
    data: averageRegularSeriesData,
    name: translate.phrases.banyanApp('Average Selected'),
    tooltip: {
      valueDecimals: unitConverter[unitConverterToUse]?.().defaultNumberOfDecimalPlaces(),
      valueSuffix: unitConverter[unitConverterToUse]?.().suffix(),
    },
    type: 'line',
  }

  series.push(averageRegularSeries)

  if (valueType === 'soilMoisture') series.push(forecastedSeries)

  if (compareSeasonsInterval) {
    const compareSeasonSeriesData = Object.entries(averageCompareSeasonsSeriesDataDictionary)
      .map(([epoch, values]) => {
        const averageForThisEpoch = roundToDecimalPlaces(
          values.reduce((a, b) => a + b, 0) / values.length,
          unitConverter[unitConverterToUse]().defaultNumberOfDecimalPlaces(),
        )

        return [+epoch, averageForThisEpoch]
      })
      .sort(([a], [b]) => sortNumber(a, b))

    series.push(
      makeCompareSeasonsSeriesFromRegularSeries(averageRegularSeries, {
        data: compareSeasonSeriesData,
      }),
    )
  }

  return series
}
