import { TFieldAssetKeyTypes } from 'App/Map/types'
import { fieldAssetStore } from 'stores/fieldAssetStore'
import { TSelectedFieldAssetsStoreState } from 'stores/selectedFieldAssetsStore'
import { getArgsForAnchorCoordinates } from '../getArgsForAnchorCoordinates'

export type TSetByArg = { property: TFieldAssetKeyTypes.TPropertyId }

export const setBy = ({
  property: propertyArg,
}: TSetByArg): Partial<TSelectedFieldAssetsStoreState> | null => {
  const allProperties = fieldAssetStore.getState()?.properties
  const property = allProperties?.[propertyArg]

  if (!property || !property?.centroid?.coordinates) {
    return null
  }

  const { lng, lat } = property.centroid.coordinates
  const anchorCoordinates = { lng, lat }

  return getArgsForAnchorCoordinates({ anchorCoordinates, property })
}
