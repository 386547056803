import { apiFetch } from 'utils/apiFetch'
import { routes } from '@semios/app-platform-banyan-route-definitions'

export type NodeServiceIssueStatusUpdateMeta = routes.ServiceCenterNodeServiceIssueStatusUpdate.Request

export const request = async (meta: NodeServiceIssueStatusUpdateMeta) => {
  return await apiFetch<
    routes.ServiceCenterNodeServiceIssueStatusUpdate.Request,
    routes.ServiceCenterNodeServiceIssueStatusUpdate.Response
  >({
    url: routes.ServiceCenterNodeServiceIssueStatusUpdate.path,
    body: meta,
    params: {
      method: 'POST',
    },
  })
}

export const serviceCenterNodeServiceIssueStatusUpdate = {
  request,
}
