import { selectedFieldAssetsStore } from 'stores/selectedFieldAssetsStore'
import { fieldAssetStore } from 'stores/fieldAssetStore'
import { serviceCenterStore } from 'App/ServiceCenter/store/serviceCenterStore'
import { getIdentifier } from 'App/ServiceCenter/utils/getIdentifier'
import {
  TActiveGateway,
  TActiveNode,
  TNodeDevice,
  TNodeServiceIssueSummary,
  TPlannedGateway,
  TPlannedNode,
} from 'App/ServiceCenter/types'
import { flatNodeDevices } from 'App/ServiceCenter/utils/flatNodeDevices'
import { getDeviceInstallationHeightLabel } from 'App/ServiceCenter/utils/getDeviceInstallationHeightLabel'

type IntercomCustomMessageProps = {
  serviceIssue?: TNodeServiceIssueSummary
  node?: TActiveNode | TActiveGateway | TPlannedNode | TPlannedGateway
}

const getPrePopulatedServiceIssueMessage = (
  serviceIssue: TNodeServiceIssueSummary,
  propertyName: string,
): string => {
  const activeNodes = serviceCenterStore.selectors.getActiveNodes(serviceCenterStore.getState())
  const node = activeNodes.find((n) => getIdentifier(n) === serviceIssue.nodeIdentifier) as TActiveNode
  const lineSeparator = '\n'
  const bullet = '\u2022'
  const lines: string[] = []
  const propertyStr = `${bullet} Property: ${propertyName} (${node.propertyId})`

  lines.push(propertyStr)

  const nodeIdentifierStr = `${bullet} Node ID: ${node.nodeIdentifier}`

  lines.push(nodeIdentifierStr)

  const serviceIssueTypeStr = `${bullet} Service Issue Type: ${serviceIssue.nodeServiceIssueTypeTitle}`

  lines.push(serviceIssueTypeStr, '') // Add an extra line break after Service Issue Type

  const apiUrlMatches = import.meta.env.VITE_API_URL?.match(/api-(\w+)-pr|(localhost):/)

  let env = apiUrlMatches ? `-${apiUrlMatches[1] || apiUrlMatches[2]}` : ''

  if (env !== '-testing') {
    env = ['-stage', '-replica'].includes(env) ? env : ''

    const nodeHistoryUrl = `${bullet} Node History: https://noc2${env}.semios.com/fp/properties/${node.propertyId}/nodes/detail/${node.nodeIdentifier}/history`

    lines.push(nodeHistoryUrl, '') // Add an extra line break after Node History

    const serviceIssueUrl = `${bullet} Service Issue Details: https://noc2${env}.semios.com/service-issues/edit/${serviceIssue.serviceIssueId}`

    lines.push(serviceIssueUrl)
  }

  return lines.join(lineSeparator)
}

const getPrePopulatedNodeMessage = (
  node: TActiveNode | TActiveGateway | TPlannedNode | TPlannedGateway,
  propertyName: string,
): string => {
  const lineSeparator = '\n'
  const bullet = '\u2022'
  const lines: string[] = []

  const propertyId =
    (node as TActiveNode).propertyId ||
    (node as TActiveGateway).propertyIds?.[0] ||
    (node as TPlannedNode).propertyId ||
    (node as TPlannedGateway).propertyId

  const nodeType =
    (node as TActiveNode).nodeType ||
    (node as TActiveGateway).gatewayType ||
    (node as TPlannedNode).nodeType ||
    (node as TPlannedGateway).nodeType

  const nodeIdentifier = (node as TActiveNode).nodeIdentifier || (node as TActiveGateway).gatewayIdentifier
  const propertyIdStr = propertyId ? `(${propertyId})` : ''

  lines.push(`${bullet} Property: ${propertyName} ${propertyIdStr}`)

  lines.push(`${bullet} Node Type: ${nodeType.toUpperCase()}`)

  if (nodeIdentifier) {
    lines.push(`${bullet} Node ID: ${nodeIdentifier}`)
  }

  if (node.name) {
    lines.push(`${bullet} Name: ${node.name}`)
  }

  if ((node as TActiveNode).devices) {
    lines.push(`${bullet} Device(s):${lineSeparator}${formatDevicesString(node as TActiveNode)}`)
  }

  const apiUrlMatches = import.meta.env.VITE_API_URL?.match(/api-(\w+)|(localhost):/)

  let env = apiUrlMatches ? `-${apiUrlMatches[1] || apiUrlMatches[2]}` : ''

  const plannedNodeId = (node as TPlannedNode).id || (node as TPlannedGateway).id

  if (env !== '-testing') {
    env = ['-stage', '-replica'].includes(env) ? env : ''

    if (plannedNodeId && propertyId) {
      // Remove the prefix "gtwy-" if it exists
      const cleanPlannedNodeId = plannedNodeId.replace(/^gtwy-/, '')

      lines.push(
        `${bullet} Planned Node: https://noc2${env}.semios.com/fp/properties/${propertyId}/pnodes/edit/${cleanPlannedNodeId}`,
      )
    }

    if (propertyId && nodeIdentifier) {
      lines.push(
        `${bullet} Node History: https://noc2${env}.semios.com/fp/properties/${propertyId}/nodes/detail/${nodeIdentifier}/history`,
      )
    }
  }

  return lines.join(lineSeparator)
}

function formatDevicesString(activeNode: TActiveNode): string {
  const lineSeparator = '\n'
  const nodeDeviceArray = flatNodeDevices(activeNode.devices)

  const formatDeviceString = (device: TNodeDevice, index: number): string => {
    return `${index + 1}. ${device.connector} | ${device.channel} | ${
      device.source
    } | ${getDeviceInstallationHeightLabel(device.height)}${lineSeparator}`
  }

  return nodeDeviceArray.map((device, index) => formatDeviceString(device, index)).join('')
}

export function getIntercomPrePopulatedMessage(customMessage: IntercomCustomMessageProps): string | null {
  const propertyId = Number(selectedFieldAssetsStore.getState().property)
  const propertyName = fieldAssetStore.getState()?.properties?.[propertyId]?.propertyName ?? ''

  if (customMessage.serviceIssue) {
    return getPrePopulatedServiceIssueMessage(customMessage.serviceIssue, propertyName)
  } else if (customMessage.node) {
    return getPrePopulatedNodeMessage(customMessage.node, propertyName)
  } else return null
}
