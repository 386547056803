import { ModalDrawer } from 'components/ModalDrawer/ModalDrawer'
import { translate } from 'i18n/i18n'
import { useMemo, useCallback, useEffect, useState } from 'react'
import { SharedSettings } from 'settings/SharedSettings'
import { smallStore } from 'stores/smallStore'
import { arrayOfObjectsSearch } from 'utils/arrayOfObjectsSearch'
import { checkAuthorization } from 'utils/checkAuthorization'
import { useScreenSize } from 'utils/useScreenSize'
import { UserSettingsMenuNarrowScreen } from './NarrowScreen/UserSettingsMenuNarrowScreen'
import { CurrentTabSelectionType, SettingItemsType } from './types'
import { UserSettingsMenuWideScreen } from './WideScreen/UserSettingsMenuWideScreen'
import { userCanCreateAlerts } from 'utils/userCanCreateAlerts'
import { userCanCreateReports } from 'utils/userCanCreateReports'

const DURATION_MILLISECOND = 3000

export const UserSettingsMenu = () => {
  const onClose = useCallback(() => {
    smallStore.setState((s) => ({
      ...s,
      showSettingsModal: false,
    }))

    // to prevent the settings menu changing as it's on its way out
    setTimeout(() => {
      smallStore.setState((s) => ({
        ...s,
        settingsModalSelectedTab: 'mainMenu',
      }))
    }, 150)
  }, [])

  const setCurrentTab = useCallback(
    (newTab: CurrentTabSelectionType) =>
      smallStore.setState((s) => ({ ...s, settingsModalSelectedTab: newTab })),
    [],
  )

  const opened = smallStore.useSelector((s) => s.showSettingsModal)
  const currentTab = smallStore.useSelector((s) => s.settingsModalSelectedTab)
  const [searchText, setSearchText] = useState('')
  const { isWideScreen } = useScreenSize()

  useEffect(() => {
    if (opened) {
      setSearchText('')
    }
  }, [opened])

  const settingItems: SettingItemsType[] = [
    {
      tabKey: 'myDetails',
      label: translate.phrases.banyanApp('Account Details'),
    },
  ]

  const includeAlerts = userCanCreateAlerts()
  const includeReports = userCanCreateReports()

  if (includeAlerts || includeReports) {
    settingItems.push({
      tabKey: 'contactsAndGroups',
      label: translate.phrases.banyanApp('Contacts'),
    })
  }

  settingItems.push({
    tabKey: 'unitSettings',
    label: translate.phrases.banyanApp('Unit Settings'),
  })

  settingItems.push({
    tabKey: 'presets',
    label: translate.phrases.banyanApp('Views'),
  })

  if (checkAuthorization({ permission: 'EDIT_IRRIGATION_PLANNER', entity: 'FOR_ANY_ENTITY' })) {
    settingItems.push({
      tabKey: 'irrigationScheduler',
      label: translate.phrases.banyanApp('Irrigation Scheduler'),
    })
  }

  if (checkAuthorization({ permission: 'EDIT_EVAPOTRANSPIRATION_COEFFICIENTS', entity: 'FOR_ANY_ENTITY' })) {
    settingItems.push({
      tabKey: 'evapotranspiration',
      label: translate.phrases.banyanApp('Evapotranspiration'),
    })
  }

  if (includeAlerts) {
    settingItems.push({
      tabKey: 'alerts',
      label: translate.phrases.banyanApp('Alerts'),
    })
  }

  if (includeReports) {
    settingItems.push({
      tabKey: 'reports',
      label: translate.phrases.banyanApp('Reports'),
    })
  }

  if (checkAuthorization({ permission: 'API_TOKEN_MANAGE_AND_USE', entity: '*' })) {
    settingItems.push({
      tabKey: 'semiosApi',
      label: translate.phrases.banyanApp('Semios API'),
    })
  }

  const filteredSettingItems = useMemo(() => {
    return arrayOfObjectsSearch(settingItems, searchText, ['label'])
  }, [searchText, settingItems])

  return (
    <ModalDrawer
      opened={opened}
      onClose={onClose}
      title={translate.phrases.banyanApp('Settings')}
      size={'70%'}
      zIndex={SharedSettings.DEFAULT_MODAL_DRAWER_Z_INDEX}
    >
      {isWideScreen ? (
        <UserSettingsMenuWideScreen
          currentTab={currentTab}
          setCurrentTab={setCurrentTab}
          durationMillisecond={DURATION_MILLISECOND}
          searchText={searchText}
          setSearchText={setSearchText}
          filteredSettingItems={filteredSettingItems}
        />
      ) : (
        <UserSettingsMenuNarrowScreen
          currentTab={currentTab}
          setCurrentTab={setCurrentTab}
          durationMillisecond={DURATION_MILLISECOND}
          searchText={searchText}
          setSearchText={setSearchText}
          filteredSettingItems={filteredSettingItems}
        />
      )}
    </ModalDrawer>
  )
}
