import { routes } from '@semios/app-platform-banyan-route-definitions'
import { DropdownSelectorPoint } from 'App/Map/PanelDetails/SectionTitleBars/DropdownSelectorPoint/DropdownSelectorPoint'
import { propertyLacksPermissionSectionMaker } from 'App/Map/PanelDetails/_utils/propertyLacksPermissionSectionMaker'
import { selectedPropertyHasPermission } from 'App/Map/PanelDetails/_utils/selectedPropertyHasPermission'
import { EAggregationInterval } from 'App/Map/types'
import { GridTableContentSection, GridTableContentSectionItem } from 'components/GridTable/types'
import { IconWindDirection } from 'components/icons/IconWindDirection'
import { translate } from 'i18n/i18n'
import {
  selectedFieldAssetsStore,
  TPointCategory,
  TSelectedFieldAssetsStoreState,
} from 'stores/selectedFieldAssetsStore'
import { selectedValueGroupsStore } from 'stores/selectedValueGroupsStore/selectedValueGroupsStore'
import { unitConverter } from 'utils/unitConverter/unitConverter'
import { defaultValuesRowHeight } from '../_utils'
import {
  doesSelectedPointHaveValueTypes,
  doesSelectedRegionHaveValueTypes,
} from 'utils/doesSelectedFieldAssetHaveValueTypes'
import { VV } from '@semios/app-platform-value-type-definitions'
import { isUserOnlyAFreeRegionalUser } from 'utils/isUserOnlyAFreeRegionalUser'
import { selectedRegionHasPermission } from 'App/Map/PanelDetails/_utils/selectedRegionHasPermission'
import { getSelectedRegion } from 'utils/getSelectedRegion'
import { smallStore } from 'stores/smallStore'
import { fieldAssetStore } from 'stores/fieldAssetStore'

/* eslint-disable @typescript-eslint/no-explicit-any */
type CommonValueType = {
  timestamp: string
  windSpeed?:
    | number
    | {
        min?: any
        max?: any
        average?: any
      }
    | null
  windDirection?:
    | number
    | {
        min?: any
        max?: any
        average?: any
      }
    | null
}
/* eslint-enable @typescript-eslint/no-explicit-any */

const permissionRequired = 'VIEW_WEATHER_DETAILS'

const checkPermission = () =>
  selectedPropertyHasPermission({ permission: permissionRequired }) ||
  (isUserOnlyAFreeRegionalUser() && selectedRegionHasPermission({ permission: permissionRequired }))

const pointCategory: TPointCategory = 'outOfBlockPoint'
const preferredAggregationInterval = { preferredAggregationInterval: EAggregationInterval.DAILY }

const possibleValueTypesToRequest: VV.DomainTypes.Weather.TTimeseriesValueTypeKeysMerged[] = [
  'windDirection',
  'windSpeed',
]

const getRegionalValuesRequested = ({
  selectedFieldAssets,
}: {
  selectedFieldAssets: TSelectedFieldAssetsStoreState
}): Partial<routes.Values.Request> => {
  const selectedProperty = selectedFieldAssets.property
  const selectedRegion = getSelectedRegion({ selectedFieldAssets, selectedProperty })

  const valuesRequested: Partial<
    Record<typeof possibleValueTypesToRequest[number], typeof preferredAggregationInterval>
  > = {
    windDirection: preferredAggregationInterval,
    windSpeed: preferredAggregationInterval,
  }

  if (
    !selectedRegion ||
    !doesSelectedRegionHaveValueTypes({ valuesTimeseries: Object.keys(valuesRequested) })
  ) {
    return {}
  }

  return {
    regions: {
      regionIds: [selectedRegion],
      valuesRequested,
    },
  }
}

const getValuesRequestedForPoints = ({
  selectedFieldAssets,
}: {
  selectedFieldAssets: TSelectedFieldAssetsStoreState
}) => {
  if (!selectedFieldAssets[pointCategory] || !selectedFieldAssets.property) return {}

  const valuesRequested: Partial<
    Record<typeof possibleValueTypesToRequest[number], typeof preferredAggregationInterval>
  > = {
    windDirection: preferredAggregationInterval,
    windSpeed: preferredAggregationInterval,
  }

  if (
    !doesSelectedPointHaveValueTypes({
      valuesTimeseries: Object.keys(valuesRequested),
      pointCategory,
    })
  ) {
    return {}
  }

  return {
    points: {
      lngLats: [selectedFieldAssets[pointCategory]],
      valuesRequested,
    },
  }
}

export const apiArgs = ({
  selectedValueGroups,
  selectedFieldAssets,
}: {
  selectedValueGroups: ReturnType<typeof selectedValueGroupsStore.getState>['selectedValueGroups']
  selectedFieldAssets: TSelectedFieldAssetsStoreState
}): Partial<routes.Values.Request> => {
  if (!checkPermission()) return {}

  if (!selectedValueGroups.wind) return {}

  const showRegionalData = smallStore.getState().showRegionalData
  const pointsToRequest = getValuesRequestedForPoints({ selectedFieldAssets })
  const regionsToRequest = showRegionalData ? getRegionalValuesRequested({ selectedFieldAssets }) : {}

  return { ...pointsToRequest, ...regionsToRequest }
}

const generateItem = ({
  id,
  label,
  values,
}: {
  id: string
  label: React.ReactNode
  values: Record<string, CommonValueType>
}): {
  height: GridTableContentSectionItem['height']
  id: string
  label: React.ReactNode
  labelMinWidth: GridTableContentSectionItem['labelMinWidth']
  render: GridTableContentSectionItem['render']
  valueMinWidth: GridTableContentSectionItem['valueMinWidth']
  values: Record<string, CommonValueType>
} => ({
  id,
  label,
  height: defaultValuesRowHeight,
  labelMinWidth: 90,
  valueMinWidth: 120,
  render: (dataPoint: typeof values[keyof typeof values]) => {
    // TODO: maybe the route definitions can be more sophisticated in its return?
    if (
      dataPoint === undefined ||
      typeof dataPoint?.windSpeed === 'number' ||
      dataPoint?.windSpeed === null ||
      typeof dataPoint?.windDirection === 'number' ||
      dataPoint?.windDirection === null
    ) {
      return translate.phrases.templates('-')
    }

    return (
      <>
        {unitConverter.windSpeed(dataPoint.windSpeed?.average).valueWithSuffix()}
        <IconWindDirection windDirection={dataPoint.windDirection?.average} />
      </>
    )
  },
  values,
})

export const content = ({ data }: { data: routes.Values.Response }): GridTableContentSection => {
  const commonReturnItems = {
    title: unitConverter.windSpeed().categoryTitleWithoutUnit(),
    titleChildren: (
      <DropdownSelectorPoint
        pointCategory={pointCategory}
        valuesTimeseriesToFilterOn={possibleValueTypesToRequest}
      />
    ),
    id: 'summary-grid-wind',
  }

  if (!checkPermission()) return propertyLacksPermissionSectionMaker(commonReturnItems)

  // TODO: get this type from route-definitions when available
  const values: Record<string, CommonValueType> = {}
  const stationLngLat = String(selectedFieldAssetsStore.getState()[pointCategory])
  const selectedProperty = Number(selectedFieldAssetsStore.getState().property)
  const selectedRegion = getSelectedRegion({ selectedProperty })
  const windSpeedToIterate = data?.points?.[stationLngLat]?.values?.windSpeed?.[0]?.timeseries ?? []

  windSpeedToIterate.forEach((ts) => {
    values[ts.timestamp] = values[ts.timestamp] ?? { timestamp: ts.timestamp }

    values[ts.timestamp] = { ...(values[ts.timestamp] ?? {}), windSpeed: ts.value }
  })

  const windDirectionToIterate = data?.points?.[stationLngLat]?.values?.windDirection?.[0]?.timeseries ?? []

  windDirectionToIterate.forEach((ts) => {
    values[ts.timestamp] = values[ts.timestamp] ?? { timestamp: ts.timestamp }

    values[ts.timestamp] = {
      ...(values[ts.timestamp] ?? {}),
      windDirection: ts.value,
    }
  })

  const showRegionalData =
    smallStore.getState().showRegionalData &&
    doesSelectedRegionHaveValueTypes({
      valuesTimeseries: possibleValueTypesToRequest,
    })

  const items: Array<ReturnType<typeof generateItem>> = []

  if (doesSelectedPointHaveValueTypes({ valuesTimeseries: possibleValueTypesToRequest, pointCategory })) {
    items.push(
      generateItem({
        id: 'wind',
        label: translate.phrases.banyanApp('Wind'),
        values,
      }),
    )
  }

  if (showRegionalData && selectedRegion) {
    const regionLabel = items.length
      ? fieldAssetStore.getState().regions?.[selectedRegion]?.name
      : translate.phrases.banyanApp('Wind')

    const regionValues: Record<string, CommonValueType> = {}
    const regionWindSpeedArray = data?.regions?.[selectedRegion]?.values?.windSpeed?.[0]?.timeseries ?? []

    regionWindSpeedArray.forEach((ts) => {
      regionValues[ts.timestamp] = regionValues[ts.timestamp] ?? { timestamp: ts.timestamp }

      regionValues[ts.timestamp] = { ...(regionValues[ts.timestamp] ?? {}), windSpeed: ts.value }
    })

    const regionWindDirectionArray =
      data?.regions?.[selectedRegion]?.values?.windDirection?.[0]?.timeseries ?? []

    regionWindDirectionArray.forEach((ts) => {
      regionValues[ts.timestamp] = regionValues[ts.timestamp] ?? { timestamp: ts.timestamp }

      regionValues[ts.timestamp] = {
        ...(regionValues[ts.timestamp] ?? {}),
        windDirection: ts.value,
      }
    })

    items.push(
      generateItem({
        id: 'wind-regional',
        label: regionLabel,
        values: regionValues,
      }),
    )
  }

  return {
    ...commonReturnItems,
    items,
  }
}
