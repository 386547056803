import { routes } from '@semios/app-platform-banyan-route-definitions'
import { getTranslatableTemplateForValueTypeGroup } from 'components/AlmaSummary/getTranslatableTemplateForValueTypeGroup'
import React from 'react'
import { translate } from 'i18n/i18n'
import { unitConverter } from 'utils/unitConverter/unitConverter'
import { userDetailsStore } from 'stores/userDetailsStore'
import { WeatherForecast } from './WeatherForecast'

export const renderFunctions = {
  air_temperature_sidekick: (
    templatedValue: routes.LandingPageValuesCurrent.Response['regions'][string]['air_temperature_sidekick'],
  ) => {
    if (templatedValue) {
      const {
        lastYearDiff,
        lastYearIsWarmer,
        lastYearMean,
        nextWeekMax,
        nextWeekMean,
        nextWeekMin,
        nextWeekThreshold,
        thisWeekMax,
        thisWeekMin,
        thisWeekMean,
        thisWeekThreshold,
      } = templatedValue.value.statements.values

      const templateToUse = getTranslatableTemplateForValueTypeGroup('air_temperature_sidekick')

      if (!templateToUse) return null

      const roundingOptions = { decimalPlaces: 0 }

      return (
        <div>
          {translate.phrases.sidekick(templateToUse, {
            lastYearDiff: unitConverter
              .temperatureInversionAboveMinusInCanopy(lastYearDiff, roundingOptions)
              .valueWithSuffix(),
            lastYearIsWarmer: lastYearIsWarmer
              ? translate.phrases.sidekick('warmer')
              : translate.phrases.sidekick('cooler'),
            lastYearMean: unitConverter.temperatureInCanopy(lastYearMean, roundingOptions).valueWithSuffix(),
            nextWeekMax: unitConverter.temperatureInCanopy(nextWeekMax, roundingOptions).valueWithSuffix(),
            nextWeekMin: unitConverter.temperatureInCanopy(nextWeekMin, roundingOptions).valueWithSuffix(),
            nextWeekMean: unitConverter.temperatureInCanopy(nextWeekMean, roundingOptions).valueWithSuffix(),
            nextWeekThreshold: translate.phrases.sidekick(nextWeekThreshold),
            thisWeekMax: unitConverter.temperatureInCanopy(thisWeekMax, roundingOptions).valueWithSuffix(),
            thisWeekMin: unitConverter.temperatureInCanopy(thisWeekMin, roundingOptions).valueWithSuffix(),
            thisWeekMean: unitConverter.temperatureInCanopy(thisWeekMean, roundingOptions).valueWithSuffix(),
            thisWeekThreshold: thisWeekThreshold,
          })}
        </div>
      )
    }
  },
  trap_catches_insect_id_1_sidekick: (
    templateValue: routes.LandingPageValuesCurrent.Response['regions'][string]['trap_catches_insect_id_1_sidekick'],
  ) => {
    if (templateValue) {
      return <div>{templateValue.value.statements.statement}</div>
    }
  },
  degree_days_insect_id_1_sidekick: (
    templateValue: routes.LandingPageValuesCurrent.Response['regions'][string]['degree_days_insect_id_1_sidekick'],
  ) => {
    if (templateValue) {
      const userTemperatureUnits = userDetailsStore.getState()?.temperature

      if (userTemperatureUnits === 'IMPERIAL') {
        return <div>{templateValue.value.statements.imperialStatement}</div>
      }

      return <div>{templateValue.value.statements.metricStatement}</div>
    }
  },
  weatherForecastDaily: (
    dailyWeatherForecast: routes.LandingPageValuesCurrent.Response['regions'][string]['weatherForecastDaily'],
  ) => {
    if (dailyWeatherForecast) {
      return <WeatherForecast dailyWeatherData={dailyWeatherForecast} />
    }
  },
}
