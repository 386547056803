import { apiFetch } from 'utils/apiFetch'
import { routes } from '@semios/app-platform-banyan-route-definitions'

export const serviceCenterNodeServiceIssueDetailGet = async (serviceIssueId: number) => {
  const serviceIssue = await apiFetch<
    routes.ServiceCenterNodeServiceIssueDetailGet.Request,
    routes.ServiceCenterNodeServiceIssueDetailGet.Response
  >({
    url: routes.ServiceCenterNodeServiceIssueDetailGet.path,
    body: {
      serviceIssueId,
    },
    params: {
      method: 'POST',
    },
  })

  return serviceIssue
}
