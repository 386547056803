import { routes } from '@semios/app-platform-banyan-route-definitions'
import {
  getValueTypeFromEmitterType,
  IRRIGATION_ACTIVITY_APPLIED_VALUE_TYPE_PREFIX,
  IRRIGATION_ACTIVITY_PLANNED_VALUE_TYPE_PREFIX,
  TEmitterType,
} from '@semios/app-platform-common'
import { EAggregationInterval, VV } from '@semios/app-platform-value-type-definitions'
import { DropdownSelectorIrrigationZone } from 'App/Map/PanelDetails/SectionTitleBars/DropdownSelectorIrrigationZone/DropdownSelectorIrrigationZone'
import { getTimezoneForSelectedPropertyOrRegion } from 'App/Map/PanelDetails/_utils/getTimezoneForSelectedPropertyOrRegion'
import { getVolumeUnit } from 'App/Map/PanelDetails/_utils/getVolumeUnit'
import { propertyLacksPermissionSectionMaker } from 'App/Map/PanelDetails/_utils/propertyLacksPermissionSectionMaker'
import { selectedPropertyHasPermission } from 'App/Map/PanelDetails/_utils/selectedPropertyHasPermission'
import {
  getIdAndEmitterTypeFromZoneEmitterTypeKey,
  makeDeviceNameAndEmitterTypeLabel,
} from 'App/Map/_utils/irrigationZoneEmitterTypeKeyUtil'
import { StackedChartSection } from 'components/StackedChart/types'
import { TooltipFormatterContextObject } from 'highcharts'
import { translate } from 'i18n/i18n'
import moment from 'moment-timezone'
import { colors } from 'settings/colors'
import { detailsPanelStore } from 'stores/detailsPanelStore'
import { fieldAssetStore } from 'stores/fieldAssetStore'
import { TSelectedFieldAssetsStoreState } from 'stores/selectedFieldAssetsStore'
import { userDetailsStore } from 'stores/userDetailsStore'
import { getWaterVolumeStringWithUnits } from 'utils/getWaterVolumeStringWithUnits'
import { minutesToHoursAndMinutes } from 'utils/minutesToHoursAndMinutes'
import { getAppliedWater } from '../_utils/getAppliedWater'
import { getIntervalForCurrentStamp } from '../_utils/getIntervalForCurrentStamp'
import { getTooltipSectionForExternalIntervalsChart } from '../_utils/getTooltipSectionForExternalIntervalsChart'
import {
  EnumIrrigationActivityStatus,
  getIrrigationActivityChartSettings,
} from '../_utils/irrigationActivityChartSettings'
import { IrrigationChartLegendWithPlannedActivity } from '../_utils/IrrigationChartLegendWithPlannedActivity'
import { zoneEmitterLacksPermissionSectionMaker } from '../_utils/zoneEmitterLacksPermissionSectionMaker'
import { chooseAmongstUnAggHourlyAndDaily } from '../../../chooseAmongstUnAggHourlyAndDaily'

type TSplineSeriesData = { x: number; y: number }

interface TXRangeSeriesType {
  color: string
  key: string
  status: string | null
  x: number
  x2: number
  y: number
  pointWidth: number
  name: 'Applied' | 'Planned'
}

const checkPermission = () => selectedPropertyHasPermission({ permission: 'VIEW_IRRIGATION_DETAILS' })

const getBlankSeriesInterval = () => {
  let interval = 60 * 60 * 1000

  const intervalToRequest = chooseAmongstUnAggHourlyAndDaily()

  switch (intervalToRequest) {
    case EAggregationInterval.HOURLY:
      interval = 60 * 60 * 1000

      break

    case EAggregationInterval.DAILY:
      interval = 24 * 60 * 60 * 1000

      break

    case EAggregationInterval.UNAGGREGATED:
      interval = 10 * 60 * 1000

      break

    default:
      break
  }

  return interval
}

export const zonesActivitySemiosIntervalsChart = ({
  data,
  selectedFieldAssets,
}: {
  data: routes.Values.Response
  selectedFieldAssets: TSelectedFieldAssetsStoreState
}): StackedChartSection | null => {
  // TODO: this needs to be handled better.

  const title = translate.phrases.banyanApp('Irrigation Activity by Zone Emitter Type - Semios')

  const commonReturnItems = {
    title,
    titleChildren: [<DropdownSelectorIrrigationZone key={'irrigationZonesDropdown'} />],
    id: 'stackem-irrigation-activity-chart-semios',
  }

  if (!checkPermission() || !selectedFieldAssets.irrigationZoneEmitter || !data.irrigationZones)
    return propertyLacksPermissionSectionMaker(commonReturnItems)

  const { dateFrom, dateTo } = detailsPanelStore.getState()

  const { irrigationZoneId, emitterType } = getIdAndEmitterTypeFromZoneEmitterTypeKey(
    selectedFieldAssets.irrigationZoneEmitter,
  )

  const values = selectedFieldAssets.irrigationZoneEmitter
    ? data?.irrigationZones?.[irrigationZoneId]?.values
    : {}

  const appliedValueType = getValueTypeFromEmitterType(
    IRRIGATION_ACTIVITY_APPLIED_VALUE_TYPE_PREFIX,
    emitterType as TEmitterType,
  ) as VV.DomainTypes.Irrigation.TTimeseriesValueTypeKeysForIrrigationZoneSemiosIntervals

  const plannedValueType = getValueTypeFromEmitterType(
    IRRIGATION_ACTIVITY_PLANNED_VALUE_TYPE_PREFIX,
    emitterType as TEmitterType,
  ) as VV.DomainTypes.Irrigation.TTimeseriesValueTypeKeysForIrrigationZoneSemiosIntervals

  const appliedIntervals = values?.[appliedValueType]?.[0]
  const plannedIntervals = values?.[plannedValueType]?.[0]

  if (!appliedIntervals && !plannedIntervals)
    return zoneEmitterLacksPermissionSectionMaker({
      ...commonReturnItems,
      name: 'zone',
    })

  const { property } = selectedFieldAssets
  const propertyData = fieldAssetStore.getState()?.properties?.[property as number]
  const waterDepthUnit = propertyData?.propertySettings?.waterDepthUnit
  const { rain: rainUnitFromUserSetting, pressure: pressureUnitFromUserSetting } = userDetailsStore.getState()
  const irrigationActivitySettings = getIrrigationActivityChartSettings({ pressureUnitFromUserSetting })
  const metadata = appliedIntervals?.metadata

  const timezone = getTimezoneForSelectedPropertyOrRegion({
    properties: fieldAssetStore.getState()?.properties,
    propertyId: selectedFieldAssets.property,
  })

  const zoneName = metadata?.name ?? translate.phrases.banyanApp('Unnamed Zone')
  const zoneEmitterData = propertyData?.irrigationZoneEmitters?.[selectedFieldAssets.irrigationZoneEmitter]
  const irrigationZoneName = zoneEmitterData?.name || ''
  const blankSeries: TSplineSeriesData[] = [] // an invisible series so that we get a tooltip even when no data
  const endStamp = moment.tz(dateTo, timezone).valueOf()

  let onDurationMinutes = 0
  let stamp = moment.tz(dateFrom, timezone).valueOf()

  while (stamp <= endStamp) {
    blankSeries.push({ x: stamp, y: 10 })

    stamp = stamp + getBlankSeriesInterval()
  }

  const appliedIntervalsSeries =
    (appliedIntervals?.timeseries || []).map((s, index: number) => {
      if (s.status === 'on') {
        const periodMinutes = moment.tz(s.endTime, timezone).diff(s.startTime, 'minutes')

        onDurationMinutes += periodMinutes
      }

      return {
        key: `applied_${index}`,
        x: s.startTime ? moment.tz(s.startTime, timezone).valueOf() : moment.tz(timezone).valueOf(),
        x2: s.endTime ? moment.tz(s.endTime, timezone).valueOf() : moment.tz(timezone).valueOf(),
        y: 3,
        color: irrigationActivitySettings[(s.status || 'noData') as EnumIrrigationActivityStatus].color,
        status: s.status,
        pointWidth: s.status === 'on' ? 24 : 14,
      }
    }) || []

  const plannedIntervalsSeries =
    plannedIntervals?.timeseries?.reduce((acc: TXRangeSeriesType[], s, index: number) => {
      if (s.status === 'on') {
        const periodMinutes = moment.tz(s.endTime, timezone).diff(s.startTime, 'minutes')

        onDurationMinutes += periodMinutes
      }

      acc.push({
        key: `planned_${index}`,
        x: s.startTime ? moment.tz(s.startTime, timezone).valueOf() : moment.tz(timezone).valueOf(),
        x2: s.endTime ? moment.tz(s.endTime, timezone).valueOf() : moment.tz(timezone).valueOf(),
        y: 10,
        color: colors.lightGreen,
        status: s.status,
        pointWidth: s.status === 'on' ? 24 : 14,
        name: 'Planned',
      })

      return acc
    }, []) || []

  if (!metadata) return null

  const flowRate = metadata?.flowRate
  const flowUnitPerHour = metadata?.flowUnitPerHour
  const totalWaterApplied = onDurationMinutes && flowRate ? +((onDurationMinutes / 60) * flowRate) : 0
  const volumeUnitToDisplay = getVolumeUnit({ waterDepthUnit, rainUnitFromUserSetting })

  const allChartSeries: {
    type: string
    name: string
    showInLegend: boolean
    data: TXRangeSeriesType[] | TSplineSeriesData[]
    enableMouseTracking?: boolean
    color?: string
    minPointLength?: number
    id: string
    marker?: {
      enabled: boolean
    }
  }[] = [
    {
      color: 'rgba(0, 0, 0, 0)',
      type: 'spline',
      name: 'invisible',
      showInLegend: false,
      data: blankSeries as TSplineSeriesData[],
      enableMouseTracking: true,
      id: 'blank',
      marker: {
        enabled: false,
      },
    },
    {
      name: irrigationZoneName,
      showInLegend: false,
      minPointLength: 3,
      data: [...appliedIntervalsSeries, ...plannedIntervalsSeries],
      enableMouseTracking: true,
      type: 'xrange',
      id: irrigationZoneId,
    },
  ]

  const activityChart = {
    chartConfig: {
      semiosHighchartsAdditions: {
        id: 'Irrigation Schedule',
        firstForecastTimestamp: +new Date(),
      },
      chart: {
        type: 'spline',
        height: 200,
      },

      tooltip: {
        xDateFormat: '%Z',
        outside: true,
        formatter: function (this: TooltipFormatterContextObject) {
          const timestamp = this.x

          const zoneLabel = makeDeviceNameAndEmitterTypeLabel({
            deviceName: zoneName,
            emitterType,
          })

          const {
            start: appliedStart,
            end: appliedEnd,
            status,
          } = getIntervalForCurrentStamp({
            currentTimeStamp: timestamp as number,
            activityIntervals: appliedIntervals || {},
            timezone,
          })

          const formatToUse = 'ddd, MMM D, YYYY h:mm A (z)'

          let appliedSection = ''
          let plannedSection = ''

          if (appliedStart) {
            const appliedIntervalData: {
              startTime: string
              endTime: string | null
              status: string
            } = {
              startTime: appliedStart?.toISOString(),
              endTime: appliedEnd?.toISOString() || null,
              status,
            }

            const { color: statusColor, text: statusText } =
              irrigationActivitySettings[(status || 'noData') as EnumIrrigationActivityStatus]

            const toolTipParams = {
              currentTimeStamp: moment.tz(timestamp, timezone).format(formatToUse),
              title: statusText,
              chartColor: statusColor,
              appliedWater: getAppliedWater({
                flowRatePerHour: metadata?.flowRate,
                flowUnitPerHour: metadata?.flowUnitPerHour,
                intervalData: appliedIntervalData,
                timezone,
                volumeUnitToDisplay,
              }),
              periodDurationText: appliedEnd
                ? minutesToHoursAndMinutes(moment.tz(appliedEnd, timezone).diff(appliedStart, 'minutes'))
                : translate.phrases.templates('-'),
              periodFromDateText: appliedStart.format(formatToUse) || translate.phrases.templates('-'),
              periodToDateText: appliedEnd?.format(formatToUse) || translate.phrases.templates('-'),
              isScheduled: false,
            }

            appliedSection = getTooltipSectionForExternalIntervalsChart(toolTipParams)
          }

          if (plannedIntervals) {
            const { start: plannedStart, end: plannedEnd } = getIntervalForCurrentStamp({
              currentTimeStamp: timestamp as number,
              activityIntervals: plannedIntervals,
              timezone,
            })

            if (plannedStart) {
              const plannedIntervalData: {
                startTime: string
                endTime: string | null
                status: string
              } = {
                startTime: plannedStart?.toISOString(),
                endTime: plannedEnd?.toISOString() || null,
                status: 'on',
              }

              const toolTipParams = {
                currentTimeStamp: moment.tz(timestamp, timezone).format(formatToUse),
                title: translate.phrases.banyanApp('Scheduled'),
                chartColor: colors.lightGreen,
                appliedWater: getAppliedWater({
                  flowRatePerHour: metadata?.flowRate,
                  flowUnitPerHour: metadata?.flowUnitPerHour,
                  intervalData: plannedIntervalData,
                  timezone,
                  volumeUnitToDisplay,
                }),
                periodDurationText: plannedEnd
                  ? minutesToHoursAndMinutes(moment.tz(plannedEnd, timezone).diff(plannedStart, 'minutes'))
                  : translate.phrases.templates('-'),
                periodFromDateText: plannedStart.format(formatToUse) || translate.phrases.templates('-'),
                periodToDateText: plannedEnd?.format(formatToUse) || translate.phrases.templates('-'),
                isScheduled: true,
              }

              plannedSection = getTooltipSectionForExternalIntervalsChart(toolTipParams)
            }
          }

          if (!plannedSection && !appliedSection) return false

          return `
          <div style="padding-bottom: 4px;">${zoneLabel}</div>
          <div style="font-size:16px;display:flex;flex-direction:column;gap:4px 12px">
            ${plannedSection}
            ${appliedSection}
          </div>`
        },
      },

      yAxis: {
        min: 0,
        visible: false,
        labels: {
          style: {
            fontSize: '14px',
          },
        },
      },
      plotOptions: {
        series: {
          groupPadding: 0,
          pointPadding: 0,
          states: {
            inactive: {
              opacity: 1,
            },
          },
        },
      },
      series: allChartSeries,
    },
    childrenLower: (
      <div
        css={{
          display: 'flex',
          justifyContent: 'flex-end',
          flexWrap: 'wrap',
        }}
      >
        <div css={{ flex: 1 }}>
          <IrrigationChartLegendWithPlannedActivity
            pressureUnitFromUserSetting={pressureUnitFromUserSetting}
          />
        </div>
        <div
          css={{
            textAlign: 'center',
            fontSize: '14px',
            color: colors.grey900,
            margin: '10px 10px 15px',
            display: 'flex',
          }}
        >
          <div>
            {translate.phrases.templates('{{label}}: {{value}}', {
              label: translate.phrases.banyanApp('On Duration'),
              value: minutesToHoursAndMinutes(onDurationMinutes),
            })}
          </div>
          <div css={{ padding: '0 10px' }}>{'|'}</div>
          <div>
            {translate.phrases.templates('{{label}}: {{value}}', {
              label: translate.phrases.banyanApp('Total Volume'),
              value: getWaterVolumeStringWithUnits({
                waterApplied: totalWaterApplied,
                volumeUnitToDisplay,
                flowUnitPerHour,
              }),
            })}
          </div>
        </div>
      </div>
    ),
  }

  return {
    title,
    titleChildren: [<DropdownSelectorIrrigationZone key={'irrigationZonesDropdown'} />],
    id: 'stackem-irrigation-activity-chart-semios',
    //@ts-ignore - this is a mixed chart of type xrange and spline
    // type property conflicts because of that
    items: [activityChart],
  }
}
