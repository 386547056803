import { routes } from '@semios/app-platform-banyan-route-definitions'
import { isNil } from '@semios/app-platform-common'
import { getMapOverlaysPadding } from 'App/Map/CurrentValuesMap/_utils/getMapOverlaysPadding'
import { BlockScdsValuesCache } from 'App/Map/CurrentValuesMap/caches/BlockScdsValuesCache/BlockScdsValuesCache'
import { BlocksDefaultPolygonsCache } from 'App/Map/CurrentValuesMap/caches/BlocksDefaultPolygonsCache/BlocksDefaultPolygonsCache'
import { BlockValuesCache } from 'App/Map/CurrentValuesMap/caches/BlockValuesCache/BlockValuesCache'
import { PropertyValuesCache } from 'App/Map/CurrentValuesMap/caches/PropertyValuesCache/PropertyValuesCache'
import { StationValuesCache } from 'App/Map/CurrentValuesMap/caches/StationValuesCache/StationValuesCache'
import { translate } from 'i18n/i18n'
import { isEmpty } from 'lodash'
import polylabel from 'polylabel'
import { HUB_CONNECTOR_ENVIRONMENT_IS_SWAGWORLD } from 'settings/hubConnectorEnvironment'
import { hubConnectorSettings } from 'settings/hubConnectorSettings'
import { fieldAssetStore } from 'stores/fieldAssetStore'
import { mapStore } from 'stores/mapStore'
import { selectedFieldAssetsStore } from 'stores/selectedFieldAssetsStore'
import { userDetailsStore } from 'stores/userDetailsStore'
import { isUserOnlyAFreeRegionalUser } from 'utils/isUserOnlyAFreeRegionalUser'
import { lngLatStringToLngLatObject } from 'utils/lngLatStringToLngLatObject'
import { setSelectedFieldAsset } from 'utils/setSelectedFieldAsset/setSelectedFieldAsset'
import { getIdForLngLat } from '../CurrentValuesMap/_utils/getIdForLngLat'
import { IrrigationZonesPolygonsCache } from '../CurrentValuesMap/caches/IrrigationZonesPolygonsCache/IrrigationZonesPolygonsCache'
import { IrrigationZoneValuesCache } from '../CurrentValuesMap/caches/IrrigationZoneValuesCache/IrrigationZoneValuesCache'
import { RegionValuesCache } from '../CurrentValuesMap/caches/RegionsValuesCache/RegionsValuesCache'
import { TrapValuesCache } from '../CurrentValuesMap/caches/TrapValuesCache/TrapValuesCache'
import { TFieldAssetKeyTypes, TFieldAssetType } from '../types'

const euclideanDistance = <T extends { lat: number; lng: number }>(pointA: T, pointB: T) => {
  return Math.sqrt(Math.pow(pointA.lng - pointB.lng, 2) + Math.pow(pointA.lat - pointB.lat, 2))
}

export const populateMapFromUserAppStartup = (o: {
  maps: typeof google.maps
  map: google.maps.Map
  blocksDefaultPolygonsCache: BlocksDefaultPolygonsCache
  blockScdsValuesCache: BlockScdsValuesCache
  blockValuesCache: BlockValuesCache
  properties: routes.UserAppStartup.Response['properties']
  propertyValuesCache: PropertyValuesCache
  stationValuesCache: StationValuesCache
  trapValuesCache: TrapValuesCache
  irrigationZonePolygonsCache: IrrigationZonesPolygonsCache
  irrigationZoneValuesCache: IrrigationZoneValuesCache
  regions: routes.UserAppStartup.Response['regions']
  regionValuesCache: RegionValuesCache
}) => {
  // TODO: Note: all this should be doing is prepping the cache with location stuff: latLngs, bounds, etc.
  const allPropertyBounds = new o.maps.LatLngBounds()

  Object.entries(o.properties ?? {}).forEach(([propertyId, property]) => {
    const propertyBounds = new o.maps.LatLngBounds()

    if (property.blocks) {
      Object.values(property.blocks).forEach((block) => {
        const { blockId } = block
        const blockIdString = String(blockId)
        const { coordinates } = JSON.parse(block.geometry) as { coordinates: Array<Array<number[]>> }
        const blockBounds = new o.maps.LatLngBounds()

        const blockLatLngs = coordinates[0].map(([lng, lat]) => {
          blockBounds.extend({ lat, lng })

          return new o.maps.LatLng({ lat, lng })
        })

        const [lng, lat] = polylabel([coordinates[0]], 1.0)

        o.blockValuesCache.addItem({
          id: blockIdString,
          value: {},
          meta: {
            blockId: Number(blockId),
            blockName: block.name ?? translate.phrases.banyanApp('Unnamed Block'),
            bounds: blockBounds,
            latLng: new o.maps.LatLng({ lng, lat }),
            latLngs: blockLatLngs,
            propertyId: Number(propertyId),
          },
        })

        if (hubConnectorSettings.enableBlockPolygons) {
          o.blocksDefaultPolygonsCache.addItem({
            id: blockIdString,
            value: {},
            meta: {
              blockId,
              bounds: blockBounds,
              latLngs: blockLatLngs,
            },
          })
        }

        o.blockScdsValuesCache.addItem({
          id: blockId.toString(),
          meta: {
            bounds: blockBounds,
            blockId: blockIdString,
            scds: [],
          },
          value: {},
        })

        blockLatLngs.forEach((latLng) => {
          propertyBounds.extend(latLng)

          allPropertyBounds.extend(latLng)
        })
      })
    }

    if (property.points) {
      Object.values(property.points).forEach((point) => {
        const { lngLat } = point
        const { lng, lat } = lngLatStringToLngLatObject(lngLat)
        const isTrap = Boolean(point.configuration.trapInsectIds)

        if (isTrap) {
          const fieldAssetType: TFieldAssetType = 'traps'

          o.trapValuesCache.addItem({
            id: getIdForLngLat({ fieldAssetType, lngLat: lngLat }),
            meta: {
              type: fieldAssetType,
              insectId: point.configuration.trapInsectIds?.[0],
              propertyId: propertyId.toString(),
              blockId: point.blockIds?.[0],
              lngLat: lngLat,
              name: point.name ?? translate.phrases.banyanApp('Unnamed Trap'),
              latLng: new o.maps.LatLng({ lng, lat }),
            },
            value: {},
          })
        } else {
          o.stationValuesCache.addItem({
            id: getIdForLngLat({ fieldAssetType: 'points', lngLat: lngLat }),
            value: {},
            meta: {
              lngLat: lngLat,
              name: point.name ?? translate.phrases.banyanApp('Unnamed Station'), // TODO station name should maybe have crop name
              propertyId: property.propertyId,
              isOutOfBlock: point.valuesCurrent.some((vt) => vt.includes('_out_')),
              latLng: new o.maps.LatLng({ lng, lat }),
            },
          })
        }
      })
    }

    if (property.irrigationZoneEmitters) {
      Object.entries(property.irrigationZoneEmitters).forEach(([zoneIdEmitterTypeKey, irrigationZone]) => {
        const { coordinates } = JSON.parse(irrigationZone.geometry) as {
          coordinates: Array<Array<number[][]>>
        }

        const irrigationZoneBounds = new o.maps.LatLngBounds()

        const irrigationZoneLatLngs = coordinates[0][0].map(([lng, lat]) => {
          irrigationZoneBounds.extend({ lat, lng })

          return new o.maps.LatLng({ lat, lng })
        })

        const [lng, lat] = polylabel([coordinates[0][0]], 1.0)

        o.irrigationZoneValuesCache.addItem({
          id: zoneIdEmitterTypeKey,
          value: {},
          meta: {
            irrigationZoneEmitterId: irrigationZone.irrigationZoneEmitterId,
            irrigationZoneEmitterType: irrigationZone.emitterType as TFieldAssetKeyTypes.TEmitterType,
            irrigationZoneName: irrigationZone.name ?? translate.phrases.banyanApp('Unnamed Zone'),
            irrigationZoneId: irrigationZone.irrigationZoneId,
            propertyId: property.propertyId,
            bounds: irrigationZoneBounds,
            latLng: new o.maps.LatLng({ lng, lat }),
            latLngs: irrigationZoneLatLngs,
            onClick: () =>
              setSelectedFieldAsset({
                irrigationZoneEmitter: irrigationZone.irrigationZoneEmitterId,
              }),
          },
        })

        if (hubConnectorSettings.enableIrrigationZonePolygons) {
          o.irrigationZonePolygonsCache.addItem({
            id: zoneIdEmitterTypeKey,
            value: {},
            meta: {
              irrigationZoneEmitterId: irrigationZone.irrigationZoneEmitterId,
              irrigationZoneEmitterType: irrigationZone.emitterType as TFieldAssetKeyTypes.TEmitterType,
              irrigationZoneId: irrigationZone.irrigationZoneId,
              propertyId: property.propertyId,
              bounds: irrigationZoneBounds,
              latLngs: irrigationZoneLatLngs,
              onClick: () =>
                setSelectedFieldAsset({
                  irrigationZoneEmitter: irrigationZone.irrigationZoneEmitterId,
                }),
            },
          })
        }
      })
    }

    if (hubConnectorSettings.enablePropertyValues) {
      o.propertyValuesCache.addItem({
        id: propertyId.toString(),
        value: {},
        meta: {
          propertyId: propertyId.toString(),
          propertyName: property.propertyName,
          bounds: propertyBounds,
        },
      })
    }
  })

  if (hubConnectorSettings.enableRegionValues && isUserOnlyAFreeRegionalUser()) {
    Object.entries(o.regions ?? {}).forEach(([regionId, region]) => {
      const { labelLngLat } = region
      const { lng, lat } = lngLatStringToLngLatObject(labelLngLat)

      o.regionValuesCache.addItem({
        id: regionId,
        value: {},
        meta: {
          regionId: regionId,
          regionName: region.name,
          latLng: new o.maps.LatLng({ lat, lng }),
        },
      })
    })
  }

  // Have the map fit all the user's properties!
  if (!HUB_CONNECTOR_ENVIRONMENT_IS_SWAGWORLD && !mapStore.getState().bounds) {
    if (!isEmpty(o.properties)) {
      o.map.fitBounds(allPropertyBounds, getMapOverlaysPadding())
    } else {
      const Fresno = { lat: 36.7403, lng: -119.7883 }
      const currentLocation = mapStore.getState().currentLocation
      const initialLocation = userDetailsStore.getState().location ?? Fresno
      const centre = currentLocation ? new o.maps.LatLng(currentLocation) : new o.maps.LatLng(initialLocation)
      const polygonThatsAGoodSize = new o.maps.Circle({ center: centre, radius: 100000 }).getBounds()
      const storedRegions = fieldAssetStore.getState().regions

      if (!isNil(storedRegions) && isNil(selectedFieldAssetsStore.getState().region)) {
        const regionalLngLats = Object.values(storedRegions).reduce(
          (acc: { lat: number; lng: number; regionId: string }[], region) => {
            if (!region?.labelLngLat) return acc

            const parsedLngLat = region.labelLngLat.replace('POINT(', '').replace(')', '').split(' ')
            const parsedLng = Number(parsedLngLat[0])
            const parsedLat = Number(parsedLngLat[1])

            const lngLatForRegion = {
              lng: parsedLng,
              lat: parsedLat,
              regionId: region.regionId,
            }

            acc.push(lngLatForRegion)

            return acc
          },
          [],
        )

        regionalLngLats.sort((a, b) => {
          return euclideanDistance(initialLocation, a) - euclideanDistance(initialLocation, b)
        })

        setSelectedFieldAsset({ region: regionalLngLats[0].regionId })
      }

      if (polygonThatsAGoodSize) o.map.fitBounds(polygonThatsAGoodSize, getMapOverlaysPadding())
    }
  }
}
