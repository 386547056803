import { selectedFieldAssetsStore, TSelectedFieldAssetsStoreState } from 'stores/selectedFieldAssetsStore'
import * as block from './_utils/by-field-asset/block'
import * as irrigationZoneEmitter from './_utils/by-field-asset/irrigationZoneEmitter'
import * as property from './_utils/by-field-asset/property'
import * as lngLat from './_utils/by-field-asset/lngLat'
import * as pointForValueGroup from './_utils/by-field-asset/pointForValueGroup'
import { TFieldAssetKeyTypes } from 'App/Map/types'

type TSetSelectedFieldAssetArg =
  | block.TSetByArg
  | irrigationZoneEmitter.TSetByArg
  | lngLat.TSetByArg
  | property.TSetByArg
  | pointForValueGroup.TSetByArg
  | { region: TFieldAssetKeyTypes.TRegionId }

export const setSelectedFieldAsset = (arg: TSetSelectedFieldAssetArg) => {
  selectedFieldAssetsStore.setState((s) => {
    let fieldAssetsToSelect: Partial<TSelectedFieldAssetsStoreState> | null = null

    if ('block' in arg) fieldAssetsToSelect = block.setBy(arg)

    if ('irrigationZoneEmitter' in arg) fieldAssetsToSelect = irrigationZoneEmitter.setBy(arg)

    if ('property' in arg) fieldAssetsToSelect = property.setBy(arg)

    if ('lngLat' in arg) fieldAssetsToSelect = lngLat.setBy(arg)

    if (pointForValueGroup.argIsForPoint(arg)) fieldAssetsToSelect = pointForValueGroup.setBy(arg, s)

    if ('region' in arg) {
      return { ...s, region: arg.region }
    }

    return { ...s, ...(fieldAssetsToSelect ?? {}) }
  })
}
