import { Accordion } from '@mantine/core'
import { ButtonExamples } from './ButtonExamples/ButtonExamples'
import { CheckboxExamples } from './CheckboxExamples'
import { DevUtils } from './DevUtils/DevUtils'
import { InputExamples } from './InputExamples'
import { LayersNavMenu } from './LayersNavMenu'
import { ModalDrawerExample } from './ModalDrawerExamples'
import { RadioExamples } from './RadioExamples'
import { ToggleButtonExamples } from './ToggleButtonExamples/ToggleButtonExamples'
import { LandingPageExamples } from './LandingPageExamples/LandingPageExamples'

export const UIExamples = () => {
  /*
   * Component Implementation Examples
   * Add the components here to display in the component accordion
   */
  const components = [
    {
      name: 'buttons',
      title: 'Buttons',
      component: <ButtonExamples />,
    },
    {
      name: 'toggle-button',
      title: 'Toggle Button',
      component: <ToggleButtonExamples />,
    },
    {
      name: 'checkbox',
      title: 'Checkbox',
      component: <CheckboxExamples />,
    },
    {
      name: 'radio',
      title: 'Radio',
      component: <RadioExamples />,
    },
    {
      name: 'input',
      title: 'Input',
      component: <InputExamples />,
    },
    {
      name: 'modalDrawer',
      title: 'Modal Drawer',
      component: <ModalDrawerExample />,
    },
    {
      name: 'navigationMenu',
      title: 'Navigation Menu',
      component: <LayersNavMenu />,
    },
    {
      name: 'dev-utils',
      title: 'Dev Utils',
      component: <DevUtils />,
    },
    { name: 'landing-page', title: 'Landing Page', component: <LandingPageExamples /> },
  ]

  return (
    <div css={{ padding: 12 }}>
      <Accordion>
        {components.map(({ name, title, component }, index) => {
          return (
            <Accordion.Item value={name} key={index}>
              <Accordion.Control>{title}</Accordion.Control>
              <Accordion.Panel>{component}</Accordion.Panel>
            </Accordion.Item>
          )
        })}
      </Accordion>
    </div>
  )
}
