import { routes } from '@semios/app-platform-banyan-route-definitions'
import { translate } from 'i18n/i18n'
import { forwardRef } from 'react'
import { countryDictionary } from './utils/countryDictionary'

export const CountryLabel = forwardRef<
  HTMLDivElement,
  { value: routes.SelfRegistrationSubmission.Request['country'] }
>(({ value: countryCode, ...others }, ref) => {
  return (
    <div ref={ref} {...others}>
      {countryCode === 'Other' ? (
        translate.phrases.banyanApp('Other')
      ) : (
        <div css={{ display: 'flex', alignItems: 'center', gap: 12, fontSize: 15 }}>
          <div css={{ fontSize: 20 }}>{countryDictionary[countryCode].flag}</div>
          <div>{countryDictionary[countryCode].name}</div>
        </div>
      )}
    </div>
  )
})

CountryLabel.displayName = 'CountryLabel'
