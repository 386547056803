import { roundToDecimalPlaces } from '@semios/app-platform-common'
import { routes } from '@semios/app-platform-banyan-route-definitions'
import type { VV } from '@semios/app-platform-value-type-definitions'
import { keyBy } from 'lodash'

export const defaultValuesRowHeight = 70

export const evapotranspirationTimestampToValuesDictionaryMaker = (
  etcTimeseries: ({ timestamp: string; value: number | null } & object)[],
  kcTimeseries: ({ timestamp: string; value: number | null } & object)[],
) => {
  const etc = timestampToValuesDictionaryMaker(etcTimeseries) as unknown as Record<
    string,
    { timestamp: string; value: number | null } & object
  >

  const etcWithKc = Object.entries(etc).reduce<
    Record<string, { timestamp: string; value: number | null; kc: number }>
  >((acc, [timestamp, etcValue]) => {
    const kcValue = kcTimeseries.find((kc) => kc.timestamp === timestamp)

    if (kcValue && kcValue.value) {
      acc[timestamp] = { ...etcValue, kc: roundToDecimalPlaces(kcValue.value, 2) || kcValue.value }
    }

    return acc
  }, {})

  return etcWithKc
}

export const timestampToValuesDictionaryMaker = (data: ({ timestamp: string } & object)[]) => {
  return keyBy(data ?? [], 'timestamp')
}

// in-block orchard weather stations
export const weatherTimestampToValuesDictionaryMaker = (
  data: routes.Values.Response,
  valueType: WeatherValueTypes,
  stationLngLat: keyof NonNullable<routes.Values.Response['points']>,
) => {
  return timestampToValuesDictionaryMaker(
    data?.points?.[stationLngLat]?.values?.[valueType]?.[0]?.timeseries ?? [],
  )
}

export const regionsTimestampToValuesDictionaryMaker = (
  data: routes.Values.Response,
  valueType: RegionValuesTypes,
  regionId: keyof NonNullable<routes.Values.Response['regions']>,
) => {
  return timestampToValuesDictionaryMaker(
    data?.regions?.[regionId]?.values?.[valueType]?.[0]?.timeseries ?? [],
  )
}

export const soilStationTimestampToValuesDictionaryMaker = (
  data: routes.Values.Response,
  valueType: SoilValueTypes,
  stationLngLat: keyof NonNullable<routes.Values.Response['points']>,
) => {
  return timestampToValuesDictionaryMaker(
    data?.points?.[stationLngLat]?.values?.[valueType]?.[0]?.timeseries ?? [],
  )
}

type TPressureSensorValueTypes = VV.DomainTypes.Irrigation.TTimeseriesValueTypeKeysMergedForByPointIrrigation

export const pressureSensorTimestampToValuesDictionaryMaker = (
  data: routes.Values.Response,
  valueType: TPressureSensorValueTypes,
  pressureSensorEmitterId: keyof NonNullable<routes.Values.Response['points']>,
) => {
  const rows = data?.points?.[pressureSensorEmitterId]?.values?.[valueType]

  return rows?.map(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (r: any) =>
      ({
        metadata: { pressureSensorEmitterId, ...(r?.metadata || {}) },
        timeseries: timestampToValuesDictionaryMaker(r?.timeseries),
      } ?? []),
  )
}

export type TIrrigationZoneValueTypes = keyof NonNullable<
  routes.Values.Response['irrigationZones']
>[string]['values']

export const irrigationZoneTimestampToValuesDictionaryMaker = (
  data: routes.Values.Response,
  valueType: TIrrigationZoneValueTypes,
  irrigationZoneId: keyof NonNullable<routes.Values.Response['irrigationZones']>,
) => {
  const rows = data?.irrigationZones?.[irrigationZoneId]?.values?.[valueType]

  return rows?.map(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (r: any) =>
      ({
        metadata: { irrigationZoneId, ...(r?.metadata || {}) },
        timeseries: timestampToValuesDictionaryMaker(r?.timeseries),
      } ?? []),
  )
}

export const blockTimestampToValuesDictionaryMaker = (
  data: routes.Values.Response,
  valueType: BlockValuesTypes,
  blockId: keyof NonNullable<routes.Values.Response['blocks']>,
) => {
  return timestampToValuesDictionaryMaker(data?.blocks?.[blockId]?.values?.[valueType]?.[0]?.timeseries ?? [])
}

type WeatherValueTypes =
  | VV.DomainTypes.Weather.TTimeseriesValueTypeKeysMerged
  | VV.DomainTypes.Chill.TTimeseriesValueTypeKeysMerged

type SoilValueTypes = VV.DomainTypes.Soil.TTimeseriesValueTypeKeysMerged

type BlockValuesTypes =
  | VV.DomainTypes.Alternaria.TTimeseriesValueTypeKeysMerged
  | VV.DomainTypes.WalnutBlight.TTimeseriesValueTypeKeysMerged
  | VV.DomainTypes.DegreeDaysInsect.TTimeseriesValueTypeKeysMerged

type RegionValuesTypes =
  | keyof VV.DomainTypes.Weather.TValuesReturnWithMetaIgnoringKeying['regions']
  | keyof VV.DomainTypes.DegreeDaysInsect.TValuesReturnWithMetaIgnoringKeying['regions']
