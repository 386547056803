import { Select } from '@mantine/core'
import { translate } from 'i18n/i18n'
import moment from 'moment-timezone'
import { useMemo } from 'react'
import { SharedSettings } from 'settings/SharedSettings'
import { fieldAssetStore } from 'stores/fieldAssetStore'
import { mapControlsStore } from 'stores/mapControlsStore/mapControlsStore'
import {
  MAP_VISUAL,
  TValuesCurrentPointsValueTypes,
  TValuesCurrentPropertiesValueTypes,
  TValuesCurrentRegionValueTypes,
} from 'stores/mapControlsStore/types'

const updateMapVisuals = (newValueType: TValuesCurrentPointsValueTypes) => {
  mapControlsStore.setState((s) => ({
    ...s,
    mapVisualValueGroup: {
      ...s.mapVisualValueGroup,
      [MAP_VISUAL.POINT]: {
        ...s.mapVisualValueGroup[MAP_VISUAL.POINT],
        precipitation: {
          ...(s.mapVisualValueGroup[MAP_VISUAL.POINT].precipitation ?? { mItem: 'M_OutOfBlockPoint' }),
          valueType: newValueType,
        },
      },
      [MAP_VISUAL.PROPERTY]: {
        ...s.mapVisualValueGroup[MAP_VISUAL.PROPERTY],
        precipitation: {
          ...(s.mapVisualValueGroup[MAP_VISUAL.PROPERTY].precipitation ?? { mItem: 'M_Property' }),
          valueType: newValueType.replace('Sum_', 'SumMinMedianMax_') as TValuesCurrentPropertiesValueTypes,
        },
      },
      [MAP_VISUAL.REGION]: {
        ...s.mapVisualValueGroup[MAP_VISUAL.REGION],
        precipitation: {
          ...(s.mapVisualValueGroup[MAP_VISUAL.REGION].precipitation ?? { mItem: 'M_Region' }),
          valueType: newValueType.replace('Sum_', 'SumMinMedianMax_') as TValuesCurrentRegionValueTypes,
        },
      },
    },
  }))
}

export const PrecipitationSecondaryDropdown = () => {
  const value = mapControlsStore.useSelector(
    (s) => s.mapVisualValueGroup?.[MAP_VISUAL.POINT]?.precipitation?.valueType,
  )

  const properties = fieldAssetStore.useSelector((s) => s.properties)

  const data = useMemo(() => {
    const hasSouthernHemisphereProperties = Object.values(properties ?? {}).some(
      (p) => p.isSouthernHemisphere,
    )

    const hasNorthernHemisphereProperties = Object.values(properties ?? {}).some(
      (p) => !p.isSouthernHemisphere,
    )

    const returner: { value: TValuesCurrentPointsValueTypes; label: string }[] = [
      {
        value: 'precipitation_following7DaysSum_mm',
        label: translate.phrases.templates('{{labelA}} ({{labelB}})', {
          labelA: translate.phrases.banyanApp('Rain'),
          labelB: translate.phrases.banyanApp('Next {{count}} Days', { count: 7 }),
        }),
      },
      {
        value: 'precipitation_last24HoursSum_mm',
        label: translate.phrases.templates('{{labelA}} ({{labelB}})', {
          labelA: translate.phrases.banyanApp('Rain'),
          labelB: translate.phrases.banyanApp('Last {{count}} Hours', { count: 24 }),
        }),
      },
      {
        value: 'precipitation_next24HoursSum_mm',
        label: translate.phrases.templates('{{labelA}} ({{labelB}})', {
          labelA: translate.phrases.banyanApp('Rain'),
          labelB: translate.phrases.banyanApp('Next {{count}} Hours', { count: 24 }),
        }),
      },
      {
        value: 'precipitation_previous7DaysSum_mm',
        label: translate.phrases.templates('{{labelA}} ({{labelB}})', {
          labelA: translate.phrases.banyanApp('Rain'),
          labelB: translate.phrases.banyanApp('Last {{count}} Days', { count: 7 }),
        }),
      },
    ]

    if (hasNorthernHemisphereProperties)
      returner.push({
        value: 'precipitation_sinceJan1Sum_mm',
        label: translate.phrases.templates('{{labelA}} ({{labelB}})', {
          labelA: translate.phrases.banyanApp('Rain'),
          labelB: translate.phrases.banyanApp('Since {{date}}', {
            date: translate.dates.format(moment.tz('UTC').month(0).date(1), 'MMM D'), // Jan 1
          }),
        }),
      })

    if (hasSouthernHemisphereProperties)
      returner.push({
        value: 'precipitation_sinceApr1Sum_mm',
        label: translate.phrases.templates('{{labelA}} ({{labelB}})', {
          labelA: translate.phrases.banyanApp('Rain'),
          labelB: translate.phrases.banyanApp('Since {{date}}', {
            date: translate.dates.format(moment.tz('UTC').month(3).date(1), 'MMM D'), // Apr 1
          }),
        }),
      })

    if (hasSouthernHemisphereProperties)
      returner.push({
        value: 'precipitation_sinceJul1Sum_mm',
        label: translate.phrases.templates('{{labelA}} ({{labelB}})', {
          labelA: translate.phrases.banyanApp('Rain'),
          labelB: translate.phrases.banyanApp('Since {{date}}', {
            date: translate.dates.format(moment.tz('UTC').month(6).date(1), 'MMM D'), // Jul 1
          }),
        }),
      })

    if (hasNorthernHemisphereProperties)
      returner.push({
        value: 'precipitation_sinceOct1Sum_mm',
        label: translate.phrases.templates('{{labelA}} ({{labelB}})', {
          labelA: translate.phrases.banyanApp('Rain'),
          labelB: translate.phrases.banyanApp('Since {{date}}', {
            date: translate.dates.format(moment.tz('UTC').month(9).date(1), 'MMM D'), // Oct 1
          }),
        }),
      })

    return returner
  }, [properties, value])

  return (
    <Select
      data={data}
      value={value}
      onChange={updateMapVisuals}
      styles={SharedSettings.MANTINE_SELECT_RIGHT_ICON_CHANGER}
      withinPortal
    />
  )
}
