import { routes } from '@semios/app-platform-banyan-route-definitions'
import { TSelectedFieldAssetsStoreState } from 'stores/selectedFieldAssetsStore'
import { selectedValueGroupsStore } from 'stores/selectedValueGroupsStore/selectedValueGroupsStore'
import { getSelectedRegion } from 'utils/getSelectedRegion'
import { EAggregationInterval, VV } from '@semios/app-platform-value-type-definitions'
import { fieldAssetStore } from 'stores/fieldAssetStore'
import { INSECT_TRAP_CATCHES_VALUE_KEY_PREFIX } from 'utils/insectRequestValueKeyPrefix'
import { StackedChartPestSection } from 'components/StackedChart/types'
import { insectTrapCatchesContent } from './_utils/insectTrapCatches'
import { doesSelectedRegionHaveValueTypes } from 'utils/doesSelectedFieldAssetHaveValueTypes'
import { unitConverter } from 'utils/unitConverter/unitConverter'
import { TPestSectionCategory } from 'App/Map/PanelDetails/_utils/sortPestSections'
import { smallStore } from 'stores/smallStore'

const getValueTypeIdForInsectId = (insectId: number) => {
  return `${INSECT_TRAP_CATCHES_VALUE_KEY_PREFIX}${insectId}` as keyof VV.DomainTypes.TrapCatchesInsect.TValuesReturnWithMetaIgnoringKeying['properties']
}

const preferredAggregationInterval = { preferredAggregationInterval: EAggregationInterval.DAILY } as const

export const apiArgs = ({
  selectedValueGroups,
  selectedFieldAssets,
}: {
  selectedValueGroups: ReturnType<typeof selectedValueGroupsStore.getState>['selectedValueGroups']
  selectedFieldAssets: TSelectedFieldAssetsStoreState
}): Partial<routes.Values.Request> => {
  const targetScope = getSelectedRegion({ selectedProperty: selectedFieldAssets.property })
  const region = fieldAssetStore.getState()?.regions?.[String(targetScope)]

  if (!region) return {}

  const valuesRequested: Partial<
    Record<
      VV.DomainTypes.TrapCatchesInsect.TTimeseriesValueTypeKeysMerged,
      typeof preferredAggregationInterval
    >
  > = {}

  Object.entries(selectedValueGroups).forEach(([valueGroup, isActive]) => {
    if (!!isActive && valueGroup.includes('trap_catches_insect_id_')) {
      const insectId = Number(valueGroup.split('_').slice(-1)[0])

      if (region.valuesTimeseries.includes(getValueTypeIdForInsectId(insectId))) {
        valuesRequested[getValueTypeIdForInsectId(insectId)] = preferredAggregationInterval
      }
    }
  })

  const valuesToBeRequested: Partial<routes.Values.Request> = {}

  valuesToBeRequested.regions = {
    regionIds: [region.regionId],
    valuesRequested: valuesRequested,
  }

  return valuesToBeRequested
}

export const content = ({
  data,
  selectedValueGroups,
  selectedFieldAssets,
  regionId,
}: {
  data: routes.Values.Response
  selectedValueGroups: ReturnType<typeof selectedValueGroupsStore.getState>['selectedValueGroups']
  selectedFieldAssets: TSelectedFieldAssetsStoreState
  regionId: string
}): StackedChartPestSection[] => {
  const { insects } = fieldAssetStore.getState()
  const targetScopeId = regionId
  const pestSectionCategory: TPestSectionCategory = 'trap-catches-property-level'

  return insectTrapCatchesContent({
    insects,
    selectedValueGroups,
    selectedFieldAssets,
    targetScopeId,
    doesTargetHaveValueTypes: doesSelectedRegionHaveValueTypes,
    targetScope: 'REGION',
    targetScopeData: data?.regions,
    insectTrapCatchesConverter: unitConverter.insectTrapCatchesRegionLevel,
    commonReturnItemsIdPrefix: 'insect-trap-catches-property-level-insect-id',
    pestSectionCategory,
    showRegionalData: smallStore.getState().showRegionalData,
  })
}
