import { routes } from '@semios/app-platform-banyan-route-definitions'
import { DropdownSelectorBlock } from 'App/Map/PanelDetails/SectionTitleBars/DropdownSelectorBlock/DropdownSelectorBlock'
import { propertyLacksPermissionSectionMaker } from 'App/Map/PanelDetails/_utils/propertyLacksPermissionSectionMaker'
import { selectedPropertyHasPermission } from 'App/Map/PanelDetails/_utils/selectedPropertyHasPermission'
import { StackedChartSection, TChartSeries } from 'components/StackedChart/types'
import { colors, TRGBAColorWith1AtTheEnd } from 'settings/colors'
import { detailsPanelStore } from 'stores/detailsPanelStore'
import { selectedFieldAssetsStore, TSelectedFieldAssetsStoreState } from 'stores/selectedFieldAssetsStore'
import { selectedValueGroupsStore } from 'stores/selectedValueGroupsStore/selectedValueGroupsStore'
import { unitConverter } from 'utils/unitConverter/unitConverter'
import { getXDateFormat } from '../_utils/getXDateFormat'
import { makeCompareSeasonsSeriesFromRegularSeries } from '../_utils/makeCompareSeasonsSeriesFromRegularSeries'
import { EAggregationInterval } from 'App/Map/types'
import { doesSelectedBlockHaveValueTypes } from 'utils/doesSelectedFieldAssetHaveValueTypes'
import { getTimezoneForSelectedPropertyOrRegion } from 'App/Map/PanelDetails/_utils/getTimezoneForSelectedPropertyOrRegion'
import { generateSpraysPlotLines } from './generateSpraysPlotLines'
import type { YAxisPlotLinesOptions } from 'highcharts'
import { AlternariaSettings } from 'App/Map/PanelDetails/_utils/by-domain/alternaria/AlternariaSettings/AlternariaSettings'
import { blockLacksCropSectionMaker } from 'App/Map/PanelDetails/_utils/blockLacksCropSectionMaker'
import { translate } from 'i18n/i18n'
import { QuestionToolTip } from 'components/QuestionToolTip/QuestionToolTip'

const checkPermission = () => selectedPropertyHasPermission({ permission: 'VIEW_ALTERNARIA_DETAILS' })
const preferredAggregationInterval = { preferredAggregationInterval: EAggregationInterval.DAILY }

const valuesRequested: Record<string, typeof preferredAggregationInterval> = {
  alternaria: preferredAggregationInterval,
  alternariaCumulativeRolling7Days: preferredAggregationInterval,
}

export const apiArgs = ({
  selectedValueGroups,
  selectedFieldAssets,
}: {
  selectedValueGroups: ReturnType<typeof selectedValueGroupsStore.getState>['selectedValueGroups']
  selectedFieldAssets: TSelectedFieldAssetsStoreState
}): Partial<routes.Values.Request> => {
  if (!checkPermission()) return {}

  if (!selectedValueGroups.alternaria || !selectedFieldAssets.block) return {}

  if (!doesSelectedBlockHaveValueTypes({ valuesTimeseries: Object.keys(valuesRequested) })) return {}

  return {
    blocks: {
      blockIds: [selectedFieldAssets.block],
      valuesRequested,
    },
  }
}

export const content = ({
  data,
  compareSeasonsData,
  updateData,
}: {
  data: routes.Values.Response
  compareSeasonsData: routes.Values.Response
  updateData: (pathToSet: string, dataToSet: unknown) => void
}): StackedChartSection => {
  const blockId = String(selectedFieldAssetsStore.getState().block)

  const commonReturnItems = {
    title: unitConverter.alternariaCumulativeRolling7Days().categoryTitleWithoutUnit(),
    titleChildren: (
      <>
        <QuestionToolTip>{translate.phrases.banyanApp('Disease Severity Value')}</QuestionToolTip>
        <DropdownSelectorBlock valuesTimeseriesToFilterOn={Object.keys(valuesRequested)} />
        <div>
          <AlternariaSettings
            handleAfterSave={(blockIdToNewThreshold) => {
              if (blockIdToNewThreshold[Number(blockId)]) {
                const path =
                  'blocks.' +
                  blockId +
                  '.values.alternariaCumulativeRolling7Days.0.metadata.blockDSVSprayThreshold'

                updateData(path, blockIdToNewThreshold[Number(blockId)])
              }
            }}
          />
        </div>
      </>
    ),
    id: 'stackem-alternaria',
  }

  if (!checkPermission()) return propertyLacksPermissionSectionMaker(commonReturnItems)

  if (!doesSelectedBlockHaveValueTypes({ valuesTimeseries: Object.keys(valuesRequested) })) {
    return blockLacksCropSectionMaker({
      ...commonReturnItems,
      translatedCropName: translate.phrases.banyanApp('Alternaria model data'),
    })
  }

  const { compareSeasonsInterval } = detailsPanelStore.getState()
  const { dateFrom, dateTo } = detailsPanelStore.getState()
  const timezone = getTimezoneForSelectedPropertyOrRegion()

  const dsvSprayThreshold =
    data?.blocks?.[blockId]?.values?.alternariaCumulativeRolling7Days?.[0]?.metadata?.blockDSVSprayThreshold

  const firstForecastTimestampDate =
    data?.blocks?.[blockId]?.values?.alternaria?.[0]?.metadata?.forecastStartsAt ?? new Date().toISOString()

  const firstForecastTimestamp = +new Date(firstForecastTimestampDate)
  const series: TChartSeries[] = []

  const alternariaDSVSeries: TChartSeries & { color: TRGBAColorWith1AtTheEnd } = {
    color: colors.compareSeasonsGreen,
    name: unitConverter.alternaria().shortTitle(),
    tooltip: {
      valueDecimals: unitConverter.alternaria().defaultNumberOfDecimalPlaces(),
    },
    yAxis: 0,
    data: (data?.blocks?.[blockId]?.values?.alternaria?.[0]?.timeseries ?? []).map((d) => [
      +new Date(d.timestamp),
      unitConverter.alternaria(d.value).value(),
    ]),
    type: 'column',
    id: 'alternariaDSV',
  }

  series.push(alternariaDSVSeries)

  if (compareSeasonsInterval) {
    series.push(
      makeCompareSeasonsSeriesFromRegularSeries(alternariaDSVSeries, {
        data: (compareSeasonsData?.blocks?.[blockId]?.values?.alternaria?.[0]?.timeseries ?? []).map((d) => [
          +new Date(d.timestamp),
          unitConverter.alternaria(d.value).value(),
        ]),
      }),
    )
  }

  const alternariaDSVCumulativeRolling7DaysSeries: TChartSeries & { color: TRGBAColorWith1AtTheEnd } = {
    color: colors.midnight,
    name: unitConverter.alternariaCumulativeRolling7Days().shortTitle(),
    tooltip: {
      valueDecimals: unitConverter.alternariaCumulativeRolling7Days().defaultNumberOfDecimalPlaces(),
    },
    yAxis: 0,
    data: (data?.blocks?.[blockId]?.values?.alternariaCumulativeRolling7Days?.[0]?.timeseries ?? []).map(
      (d) => [+new Date(d.timestamp), unitConverter.alternariaCumulativeRolling7Days(d.value).value()],
    ),
    type: 'line',
    id: 'alternariaDSVCumulativeRolling7Days',
  }

  series.push(alternariaDSVCumulativeRolling7DaysSeries)

  if (compareSeasonsInterval) {
    series.push(
      makeCompareSeasonsSeriesFromRegularSeries(alternariaDSVCumulativeRolling7DaysSeries, {
        data: (
          compareSeasonsData?.blocks?.[blockId]?.values?.alternariaCumulativeRolling7Days?.[0]?.timeseries ??
          []
        ).map((d) => [
          +new Date(d.timestamp),
          unitConverter.alternariaCumulativeRolling7Days(d.value).value(),
        ]),
      }),
    )
  }

  const sprayThresholdPlotLine: YAxisPlotLinesOptions[] = [
    {
      color: colors.red,
      value: dsvSprayThreshold,
      id: 'alternariaBlockDSVSprayThreshold',
      zIndex: 4,
      label: {
        text: unitConverter.alternariaBlockDSVSprayThreshold().shortTitle(),
      },
    },
  ]

  const { sprayPlotLines, spraySeriesForAddingToggle } = generateSpraysPlotLines({
    sprays: {
      baseSeason:
        data?.blocks?.[blockId]?.values?.alternariaCumulativeRolling7Days?.[0]?.metadata?.sprays ?? [],
      comparisonSeason: !compareSeasonsInterval
        ? []
        : compareSeasonsData?.blocks?.[blockId]?.values?.alternariaCumulativeRolling7Days?.[0]?.metadata
            ?.sprays ?? [],
    },
    dateFrom,
    dateTo,
    timezone,
    intervalForCompareSeasonsOffset: compareSeasonsInterval,
  })

  if (spraySeriesForAddingToggle) series.push(spraySeriesForAddingToggle)

  return {
    ...commonReturnItems,
    items: [
      {
        chartConfig: {
          semiosHighchartsAdditions: {
            id: commonReturnItems.id,
            firstForecastTimestamp,
          },
          chart: {
            type: 'line',
          },
          series,
          tooltip: {
            xDateFormat: getXDateFormat(),
          },
          xAxis: {
            plotLines: sprayPlotLines,
          },
          yAxis: {
            plotLines: sprayThresholdPlotLine,
            min: 0,
            softMax: dsvSprayThreshold ? dsvSprayThreshold * 1.1 : undefined,
          },
        },
      },
    ],
  }
}
