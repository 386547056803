import { translate } from 'i18n/i18n'
import { isNil } from 'lodash'
import { getInsectNameFromId } from 'utils/getInsectNameFromId'
import { TUnitConverterTypicalReturn } from '../types'

const DEFAULT_DECIMAL_PLACES = 0

const makeTrapCatchesUnitConverter = (fieldAssetType: 'PROPERTY' | 'BLOCK' | 'REGION') => {
  const trapCatches = (
    numberOfCatches: number | null = null,
    {
      insectId = -1,
      decimalPlaces = DEFAULT_DECIMAL_PLACES,
    }: {
      insectId?: number
      decimalPlaces?: number
    } = {},
  ): TUnitConverterTypicalReturn => {
    let convertedValue: number | null

    if (isNil(numberOfCatches)) {
      convertedValue = null
    } else {
      convertedValue = Number(numberOfCatches)
    }

    let title: string

    switch (fieldAssetType) {
      case 'PROPERTY':
        title = translate.phrases.banyanApp('{{insectName}} Trap Catches for Property', {
          insectName: getInsectNameFromId(insectId),
        })

        break

      case 'REGION':
        title = translate.phrases.banyanApp('{{insectName}} Trap Catches', {
          insectName: getInsectNameFromId(insectId),
        })

        break

      default: // BLOCK
        title = translate.phrases.banyanApp('{{insectName}} Trap Catches for Block', {
          insectName: getInsectNameFromId(insectId),
        })
    }

    return {
      categoryTitle: () => translate.phrases.banyanApp('Trap Catches'),
      categoryTitleWithoutUnit: () => translate.phrases.banyanApp('Trap Catches'),
      defaultNumberOfDecimalPlaces: () => DEFAULT_DECIMAL_PLACES,
      suffix: () => '',
      shortTitle: () => {
        return title
      },
      title: () => {
        return title
      },
      titleWithoutUnit: () => {
        return title
      },
      value: () => {
        if (isNil(convertedValue)) return null

        return +convertedValue?.toFixed(decimalPlaces)
      },
      valueAsString: () => `${convertedValue?.toFixed(decimalPlaces) ?? translate.phrases.templates('-')}`,
      valueWithSuffix: () => {
        if (isNil(convertedValue)) return translate.phrases.templates('-')

        return convertedValue.toFixed(decimalPlaces)
      },
      valueWithNoRounding: () => convertedValue,
    }
  }

  return trapCatches
}

export const insectTrapCatches = {
  insectTrapCatches: makeTrapCatchesUnitConverter('BLOCK'),
  insectTrapCatchesPropertyLevel: makeTrapCatchesUnitConverter('PROPERTY'),
  insectTrapCatchesRegionLevel: makeTrapCatchesUnitConverter('REGION'),
}
