import { persistentInit } from 'stores/_utils/persistentStore/persistentStore'
import { mapSearchStore } from './mapSearchStore'
import type { TFieldAssetKeyTypes } from 'App/Map/types'
import { equipmentStatusPanelStore } from '../App/ServiceCenter/store/equipmentStatusPanelStore'

const storeName = 'selectedFieldAssetsStore'

export const pointCategories = [
  'fruitGrowthPoint',
  'plantStressPoint',
  'outOfBlockPoint',
  'soilPoint',
  'trapPoint',
  'weatherPoint',
  'pressureTransducerPoint',
  'windMachinePoint',
] as const

export type TPointCategory = typeof pointCategories[number]

export type TSelectedFieldAssetsStoreState = {
  block: TFieldAssetKeyTypes.TBlockId | null
  irrigationZoneEmitter: TFieldAssetKeyTypes.TIrrigationZoneEmitterId | null
  lngLat: TFieldAssetKeyTypes.TLngLat | null
  property: TFieldAssetKeyTypes.TPropertyId | null
  region: TFieldAssetKeyTypes.TRegionId | null
} & Record<TPointCategory, TFieldAssetKeyTypes.TLngLat | null>

export const selectedFieldAssetsStore = persistentInit<TSelectedFieldAssetsStoreState>({
  initialState: {
    block: null,
    fruitGrowthPoint: null,
    plantStressPoint: null,
    irrigationZoneEmitter: null,
    lngLat: null,
    outOfBlockPoint: null,
    property: null,
    soilPoint: null,
    trapPoint: null,
    weatherPoint: null,
    pressureTransducerPoint: null,
    windMachinePoint: null,
    region: null,
  },
  keysToPutInPersistentStorage: {
    block: true,
    fruitGrowthPoint: true,
    plantStressPoint: true,
    irrigationZoneEmitter: true,
    lngLat: true,
    outOfBlockPoint: true,
    property: true,
    soilPoint: true,
    trapPoint: true,
    weatherPoint: true,
    pressureTransducerPoint: true,
    windMachinePoint: true,
    region: true,
  },
  keysToPutInURL: {
    block: true,
    fruitGrowthPoint: true,
    plantStressPoint: true,
    irrigationZoneEmitter: true,
    lngLat: true,
    outOfBlockPoint: true,
    property: true,
    soilPoint: true,
    trapPoint: true,
    weatherPoint: true,
    pressureTransducerPoint: true,
    windMachinePoint: true,
    region: true,
  },
  storeName,
})

selectedFieldAssetsStore.subscribeToChanges(
  (s) => s.property,
  (selectedProperty) => {
    const { highlightedPropertyIds } = mapSearchStore.getState()

    if (!highlightedPropertyIds[Number(selectedProperty)]) {
      mapSearchStore.setState((s) => ({ ...s, highlightedPropertyIds: {} }))
    }

    if (selectedProperty) equipmentStatusPanelStore.actions.setPropertyId(selectedProperty)
  },
)
