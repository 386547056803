import { SelectedMapCard } from 'App/Map/CurrentValuesMap/components/SelectedMapCard/SelectedMapCard'
import { MapBase } from 'App/Map/MapContext/MapBase'
import { populateMapFromUserAppStartup } from 'App/Map/_utils/populateMapFromUserAppStartup'
import { fieldAssetStore } from 'stores/fieldAssetStore'
import { mapControlsStore } from 'stores/mapControlsStore/mapControlsStore'
import { selectedFieldAssetsStore } from 'stores/selectedFieldAssetsStore'
import { selectedValueGroupsStore } from 'stores/selectedValueGroupsStore/selectedValueGroupsStore'
import { smallStore } from 'stores/smallStore'
import { userDetailsStore } from 'stores/userDetailsStore'
import { updateMapWithAnyNeededCurrentValues } from './_utils/updateMapWithAnyNeededCurrentValues'
import { hubConnectorSettings } from 'settings/hubConnectorSettings'
import { BlockScdsValuesCache } from './caches/BlockScdsValuesCache/BlockScdsValuesCache'
import { BlocksDefaultPolygonsCache } from './caches/BlocksDefaultPolygonsCache/BlocksDefaultPolygonsCache'
import { BlockValuesCache } from './caches/BlockValuesCache/BlockValuesCache'
import { IrrigationZonesPolygonsCache } from './caches/IrrigationZonesPolygonsCache/IrrigationZonesPolygonsCache'
import { IrrigationZoneValuesCache } from './caches/IrrigationZoneValuesCache/IrrigationZoneValuesCache'
import { PropertyValuesCache } from './caches/PropertyValuesCache/PropertyValuesCache'
import { RegionValuesCache } from './caches/RegionsValuesCache/RegionsValuesCache'
import { StationValuesCache } from './caches/StationValuesCache/StationValuesCache'
import { TrapValuesCache } from './caches/TrapValuesCache/TrapValuesCache'

export const initCurrentValuesMap = (map: google.maps.Map, maps: typeof google.maps) => {
  const blocksDefaultPolygonsCache = new BlocksDefaultPolygonsCache({ maps, map })
  const blockScdsValuesCache = new BlockScdsValuesCache({ maps, map })
  const blockValuesCache = new BlockValuesCache({ maps, map })
  const propertyValuesCache = new PropertyValuesCache({ maps, map })
  const stationValuesCache = new StationValuesCache({ maps, map })
  const trapValuesCache = new TrapValuesCache({ maps, map })
  const regionValuesCache = new RegionValuesCache({ maps, map })
  const irrigationZonePolygonsCache = new IrrigationZonesPolygonsCache({ maps, map })
  const irrigationZoneValuesCache = new IrrigationZoneValuesCache({ maps, map })
  const { properties, regions } = fieldAssetStore.getState()

  // TODO: proper try catching... but really this is all going to be moved soon anyways.
  populateMapFromUserAppStartup({
    map,
    maps,
    blocksDefaultPolygonsCache,
    blockScdsValuesCache,
    blockValuesCache,
    properties,
    propertyValuesCache,
    stationValuesCache,
    trapValuesCache,
    irrigationZonePolygonsCache,
    irrigationZoneValuesCache,
    regions,
    regionValuesCache,
  })

  const refreshMap = async () => {
    if (mapControlsStore.getState().loadingCurrentValues) return

    mapControlsStore.setState((s) => ({ ...s, loadingCurrentValues: true }))

    await updateMapWithAnyNeededCurrentValues({
      blocksDefaultPolygonsCache,
      blockScdsValuesCache,
      blockValuesCache,
      propertyValuesCache,
      stationValuesCache,
      trapValuesCache,
      irrigationZonePolygonsCache,
      irrigationZoneValuesCache,
      regionValuesCache,
    }).finally(() => {
      mapControlsStore.setState((s) => ({ ...s, loadingCurrentValues: false }))
    })
  }

  const mapIdleEventListener = map.addListener('idle', () => {
    refreshMap()
  })

  const selectedValueGroupsListener = selectedValueGroupsStore.subscribeToChanges(
    (s) => [s.primaryValueGroup, s.selectedValueGroups],
    () => {
      refreshMap()
    },
  )

  const smallListener = smallStore.subscribeToChanges(
    (s) => [s.appliedIrrigationActivityDisplay, s.showDataSource],
    () => {
      refreshMap()
    },
  )

  const mapControlsListener = mapControlsStore.subscribeToChanges(
    ({
      heatmapExtremes,
      loadingCurrentValues,
      showMapBadgeNames,
      showBlockPolygons,
      showRegionPolygons,
      ...rest
    }) => rest,
    () => {
      refreshMap()
    },
  )

  const userDetailsStoreListener = userDetailsStore.subscribeToChanges(
    (s) => [s.depth, s.rain, s.speed, s.temperature],
    () => {
      refreshMap()
    },
  )

  const selectedBlockListener = hubConnectorSettings.enableBlockPolygons
    ? selectedFieldAssetsStore.subscribeToChanges(
        (s) => s.block,
        (selectedBlock, oldSelectedBlock) => {
          const selectedBlockPolygonDefault = selectedBlock
            ? blocksDefaultPolygonsCache.items[selectedBlock]?.overlay
            : undefined

          const unSelectedBlockPolygonDefault = oldSelectedBlock
            ? blocksDefaultPolygonsCache.items[oldSelectedBlock]?.overlay
            : undefined

          selectedBlockPolygonDefault?.set('strokeColor', 'yellow')

          selectedBlockPolygonDefault?.set('strokeWeight', 4)

          unSelectedBlockPolygonDefault?.set('strokeColor', 'white')

          unSelectedBlockPolygonDefault?.set('strokeWeight', 2)

          const selectedBlockPolygonValue = selectedBlock
            ? blockValuesCache.items[selectedBlock].overlay?.polygon
            : undefined

          const unSelectedBlockPolygonValue = oldSelectedBlock
            ? blockValuesCache.items[oldSelectedBlock].overlay?.polygon
            : undefined

          selectedBlockPolygonValue?.set('strokeColor', 'yellow')

          selectedBlockPolygonValue?.set('strokeWeight', 4)

          unSelectedBlockPolygonValue?.set('strokeColor', 'white')

          unSelectedBlockPolygonValue?.set('strokeWeight', 2)
        },
      )
    : undefined

  const selectedIrrigationZoneListener = hubConnectorSettings.enableIrrigationZonePolygons
    ? selectedFieldAssetsStore.subscribeToChanges(
        (s) => s.irrigationZoneEmitter,
        (selectedIrrigationZone, oldSelectedIrrigationZone) => {
          const selectedIrrigationZonePolygon =
            irrigationZonePolygonsCache.items && selectedIrrigationZone
              ? irrigationZonePolygonsCache.items[selectedIrrigationZone]?.overlay
              : undefined

          const unSelectedIrrigationZonePolygon =
            irrigationZonePolygonsCache.items && oldSelectedIrrigationZone
              ? irrigationZonePolygonsCache.items[oldSelectedIrrigationZone]?.overlay
              : undefined

          selectedIrrigationZonePolygon?.set('strokeColor', 'yellow')

          selectedIrrigationZonePolygon?.set('strokeWeight', 4)

          unSelectedIrrigationZonePolygon?.set('strokeColor', 'white')

          unSelectedIrrigationZonePolygon?.set('strokeWeight', 2)

          const selectedIrrigationZonePolygonValue = selectedIrrigationZone
            ? irrigationZoneValuesCache.items[selectedIrrigationZone]?.overlay?.polygon
            : undefined

          const unSelectedIrrigationZonePolygonValue = oldSelectedIrrigationZone
            ? irrigationZoneValuesCache.items[oldSelectedIrrigationZone]?.overlay?.polygon
            : undefined

          selectedIrrigationZonePolygonValue?.set('strokeColor', 'yellow')

          selectedIrrigationZonePolygonValue?.set('strokeWeight', 4)

          unSelectedIrrigationZonePolygonValue?.set('strokeColor', 'white')

          unSelectedIrrigationZonePolygonValue?.set('strokeWeight', 2)
        },
      )
    : undefined

  return () => {
    mapIdleEventListener.remove()

    selectedBlockListener?.() // unsubscribes

    selectedValueGroupsListener() // unsubscribes

    smallListener() // unsubscribes

    userDetailsStoreListener() // unsubscribes

    selectedIrrigationZoneListener?.() // unsubscribes

    mapControlsListener() // unsubscribes
  }
}

export const CurrentValuesMap = (props: {
  hasAppStartupData: boolean // TODO: Move...
}) => {
  return (
    <MapBase hasAppStartupData={props.hasAppStartupData} loadingData={false} onInit={initCurrentValuesMap}>
      <SelectedMapCard />
    </MapBase>
  )
}
