import { TChartSeries } from 'components/StackedChart/types'

export const makeRegionalSeriesFromRegularSeries = (
  baseSeries: TChartSeries,
  regionalSeries: Partial<TChartSeries>,
): TChartSeries => {
  return {
    ...baseSeries,
    fillOpacity: 0.3,
    zIndex: 0,
    id: `${baseSeries.id}_regional`,
    linkedTo: baseSeries.id,
    /**
     * setting zones as an empty array removes dotted forecast lines. This
     * can't be universalized, but easiest way to do it without bringing
     * in an adjusted latestNonForecast timestamp
     * TODO: for now will show dashed line when any are forecast
     * but in future should calculate first forecasted date for regional series
     */
    //zones: [],
    ...regionalSeries,
    isRegional: true,
  } as TChartSeries
}

export const makeRegionalSeries = (series: TChartSeries): TChartSeries => {
  return {
    ...series,
    id: `${series.id}_regional`,
    /**
     * setting zones as an empty array removes dotted forecast lines. This
     * can't be universalized, but easiest way to do it without bringing
     * in an adjusted latestNonForecast timestamp
     *
     * TODO: for now will show dashed line when any are forecast
     * but in future should calculate first forecasted date for regional series
     */
    //zones: [],
    isRegional: true,
    zIndex: 0,
  } as TChartSeries
}
