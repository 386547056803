import { TFieldAssetKeyTypes } from 'App/Map/types'
import { getIdAndEmitterTypeFromZoneEmitterTypeKey } from 'App/Map/_utils/irrigationZoneEmitterTypeKeyUtil'
import { TPublishStatus } from 'components/StackedChart/types'
import { colors, irrigationEventsColorMap, TRGBAColorWith1AtTheEnd } from 'settings/colors'
import { fieldAssetStore } from 'stores/fieldAssetStore'
import { selectedFieldAssetsStore } from 'stores/selectedFieldAssetsStore'
import { mapApiEmitterTypeToLabel } from 'utils/mapApiEmitterTypeToLabel'
import { getIrrigationEventColor } from './getIrrigationEventColor'

export const makeEmitterSeries = (
  emitterData: {
    [irrigationZoneEmitterId: TFieldAssetKeyTypes.TIrrigationZoneEmitterId]: {
      emitterType: TFieldAssetKeyTypes.TEmitterType
      zoneId: number
      startTime: string
      endTime: string
      publishStatus?: TPublishStatus
    }[]
  },
  hasRain: boolean,
) => {
  const properties = fieldAssetStore.getState().properties
  const selectedProperty = selectedFieldAssetsStore.getState().property

  const irrigationZoneEmittersForProperty =
    (selectedProperty && properties?.[selectedProperty]?.irrigationZoneEmitters) || {}

  return Object.entries(emitterData)
    .sort(([a], [b]) => {
      const emitterAZoneName =
        irrigationZoneEmittersForProperty?.[a as TFieldAssetKeyTypes.TIrrigationZoneEmitterId]?.name

      const emitterBZoneName =
        irrigationZoneEmittersForProperty?.[b as TFieldAssetKeyTypes.TIrrigationZoneEmitterId]?.name

      // reverse order because the chart order starts from the bottom
      return emitterBZoneName.localeCompare(emitterAZoneName)
    })
    .sort(([a], [b]) => {
      const emitterA = getIdAndEmitterTypeFromZoneEmitterTypeKey(
        a as TFieldAssetKeyTypes.TIrrigationZoneEmitterId,
      ).emitterType

      const emitterB = getIdAndEmitterTypeFromZoneEmitterTypeKey(
        b as TFieldAssetKeyTypes.TIrrigationZoneEmitterId,
      ).emitterType

      // reverse order because the chart order starts from the bottom
      return emitterB.localeCompare(emitterA)
    })
    .map(([irrigationZoneEmitterId, emitterEvents], emitterIndex: number) => {
      const emitterType = irrigationZoneEmitterId.split('|||')[1] as TFieldAssetKeyTypes.TEmitterType
      const color = irrigationEventsColorMap[emitterType]

      const intervalsForChart = emitterEvents.map((s, index) => {
        const isFuture = s.startTime > new Date().toISOString()

        return {
          key: `${index}-${irrigationZoneEmitterId}-${emitterIndex}`,
          x: +new Date(s.startTime),
          x2: s.endTime ? +new Date(s.endTime) : undefined,
          y: hasRain ? emitterIndex + 1 : emitterIndex,
          color: getIrrigationEventColor(color, isFuture, s.publishStatus) as TRGBAColorWith1AtTheEnd,
          pointWidth: 14,
          publishStatus: s.publishStatus,
        }
      })

      return {
        type: 'xrange' as const,
        showInLegend: false,
        data: intervalsForChart,
        id: `${irrigationZoneEmitterId}-${emitterIndex}`,
        irrigationZoneEmitterId: irrigationZoneEmitterId as TFieldAssetKeyTypes.TIrrigationZoneEmitterId,
        zoneName:
          irrigationZoneEmitterId &&
          irrigationZoneEmittersForProperty?.[
            irrigationZoneEmitterId as TFieldAssetKeyTypes.TIrrigationZoneEmitterId
          ]?.name,
        pointPadding: 0.5,
        groupPadding: 0.5,
        borderRadius: 2,
        borderColor: colors.grey800,
        color,
        name: mapApiEmitterTypeToLabel(
          irrigationZoneEmitterId.split('|||')[1] as TFieldAssetKeyTypes.TEmitterType,
        ),
      }
    })
}
