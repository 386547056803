import { css } from '@emotion/css'
import { Group, Select, Text } from '@mantine/core'
import { PanelDetailsContext } from 'App/Map/PanelDetails/PanelDetails'
import { translate } from 'i18n/i18n'
import { forwardRef, useContext, useMemo } from 'react'
import { colors } from 'settings/colors'
import { SharedSettings } from 'settings/SharedSettings'
import { fieldAssetStore } from 'stores/fieldAssetStore'
import { selectedFieldAssetsStore } from 'stores/selectedFieldAssetsStore'
import { alphabeticalSort } from 'utils/alphabeticalSort'
import { filterFieldAssetsByValueTypes } from 'utils/filterFieldAssetsByValueTypes'
import { searchStringFromStringWithSpaces } from 'utils/searchStringFromStringWithSpaces'
import { setSelectedFieldAsset } from 'utils/setSelectedFieldAsset/setSelectedFieldAsset'
import { sortByKey } from 'utils/sortByKey'
import { hubConnectorSettings } from 'settings/hubConnectorSettings'
import { TDropdownSelectorProps } from '../_types'

type SelectDataItem = {
  value: string
  label: string
  associatedWeatherStationName: string | undefined
  group: string
}

export type TDropdownSelectorBlockProps = TDropdownSelectorProps & {
  showAssociatedWeatherStationName?: boolean
}

export const DropdownSelectorBlock = ({
  valuesTimeseriesToFilterOn,
  showAssociatedWeatherStationName,
}: TDropdownSelectorBlockProps) => {
  const { selectedBlock } = selectedFieldAssetsStore.useSelector((s) => ({
    selectedBlock: s.block,
  }))

  const { containerWidth } = useContext(PanelDetailsContext)

  const { properties } = fieldAssetStore.useSelector((s) => ({
    properties: s?.properties ?? [],
  }))

  const data = useMemo<SelectDataItem[]>(() => {
    return Object.values(properties ?? {})
      .sort(sortByKey('propertyName'))
      .flatMap(({ blocks, points, propertyName }) => {
        if (!blocks) return []

        let filteredBlocks = Object.values(blocks)

        if (valuesTimeseriesToFilterOn) {
          filteredBlocks = filterFieldAssetsByValueTypes({
            fieldAssets: Object.values(blocks),
            valuesTimeseries: valuesTimeseriesToFilterOn,
          })
        }

        const sortedBlocks = filteredBlocks.sort((a, b) =>
          alphabeticalSort(
            a.name ?? translate.phrases.banyanApp('Unnamed Block'),
            b.name ?? translate.phrases.banyanApp('Unnamed Block'),
          ),
        )

        return sortedBlocks.map((block) => {
          const associatedWeatherStationName =
            !!block.associatedWeatherStation && showAssociatedWeatherStationName
              ? points?.[block.associatedWeatherStation].name
              : undefined

          return {
            value: String(block.blockId),
            label: block.name ?? translate.phrases.banyanApp('Unnamed Station'),
            associatedWeatherStationName: associatedWeatherStationName,
            group: propertyName,
          }
        })
      })
  }, [selectedBlock, properties])

  interface ItemProps extends React.ComponentPropsWithoutRef<'div'> {
    label: string
    associatedWeatherStationName: string
  }

  const ItemComponent = forwardRef<HTMLDivElement, ItemProps>(
    ({ label, associatedWeatherStationName, ...others }: ItemProps, ref) => (
      <div ref={ref} {...others}>
        <Group noWrap>
          <div>
            <Text size={'xs'}>{label}</Text>
            {!!associatedWeatherStationName && (
              <Text size={'xs'} color={colors.grey500}>
                {associatedWeatherStationName}
              </Text>
            )}
          </div>
        </Group>
      </div>
    ),
  )

  ItemComponent.displayName = 'ItemComponent'

  if (!data.length) return null

  return (
    // can prevent the section from collapsing by passing stopPropagation
    <div onClick={(e) => e.stopPropagation()}>
      <Select
        size="xs"
        className={css({
          width: containerWidth < 600 ? 200 : Math.min(280, Math.round(containerWidth / 3)),
          border: `1px solid ${colors.grey500}`,
          color: colors.grey800,
          borderRadius: 3,
        })}
        clearable={false}
        data={data}
        itemComponent={ItemComponent}
        filter={(value, item) => {
          const fullStringToSearch = `${item?.label ?? ''}${item?.group ?? ''}`

          return searchStringFromStringWithSpaces(value, fullStringToSearch)
        }}
        onChange={(newSelectedBlock: string) => setSelectedFieldAsset({ block: Number(newSelectedBlock) })}
        placeholder={translate.phrases.banyanApp('Select a Block')}
        searchable
        styles={{
          ...SharedSettings.MANTINE_SELECT_RIGHT_ICON_CHANGER,
          ...SharedSettings.MANTINE_SELECT_UNDERLINE_SEPARATOR_STYLES,
          dropdown: { marginTop: -6, fontWeight: 'normal' },
          input: { ...SharedSettings.MANTINE_SELECT_RIGHT_ICON_CHANGER.input, paddingRight: 28 },
        }}
        value={(selectedBlock as number).toString()}
        withinPortal
        zIndex={hubConnectorSettings.zIndexForDropdownSelectorInStackemCharts}
      />
    </div>
  )
}
