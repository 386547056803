import { routes } from '@semios/app-platform-banyan-route-definitions'
import { unitConverter } from 'utils/unitConverter/unitConverter'
import { TGetCacheUpdatesFromResponseParameters, TGetCacheUpdatesFromResponseReturn } from './_types'
import { generateWindAPIArgs } from './_utils/generateWindAPIArgs'
import { isEmpty } from '@semios/app-platform-common'
import { apiFetch } from 'utils/apiFetch'
import { generateGetWindCacheUpdatesFromResponse } from './_utils/generateGetWindCacheUpdatesFromResponse'

const baseUnitConverter = unitConverter.windSpeed
const heatmapExtremesForAllPropertiesInVisibleRegions = true

const makeApiArgs = (
  processedCaches: TGetCacheUpdatesFromResponseParameters['processedCaches'],
): routes.ValuesCurrent.Request => {
  return generateWindAPIArgs({
    heatmapExtremesForAllPropertiesInVisibleRegions,
    processedCaches,
    outOfBlockPoint: {
      windSpeedValueType: 'windSpeed_mps',
      windDirectionValueType: 'windDirection_degrees',
    },
    inBlockPoint: {
      windSpeedValueType: 'windSpeed_mps',
      windDirectionValueType: 'windDirection_degrees',
    },
    property: {
      windSpeedValueType: 'windSpeed_minMedianMax_mps',
      windDirectionValueType: 'windDirection_average_degrees',
    },
    region: {
      windSpeedValueType: 'windSpeed_minMedianMax_mps',
      windDirectionValueType: 'windDirection_average_degrees',
    },
  })
}

export const getResponseAndShapeForCacheUpdate = async ({
  cacheKeys,
  processedCaches,
}: TGetCacheUpdatesFromResponseParameters): Promise<TGetCacheUpdatesFromResponseReturn> => {
  const args = makeApiArgs(processedCaches)

  if (isEmpty(args)) return {}

  const response = await apiFetch<routes.ValuesCurrent.Request, routes.ValuesCurrent.Response>({
    url: routes.ValuesCurrent.path,
    body: args,
  })

  return generateGetWindCacheUpdatesFromResponse({
    cacheKeys,
    heatmapExtremesForAllPropertiesInVisibleRegions,
    processedCaches,
    response,
    outOfBlockPoint: {
      windSpeedValueType: 'windSpeed_mps',
      windDirectionValueType: 'windDirection_degrees',
      unitConverterFunction: baseUnitConverter,
    },
    inBlockPoint: {
      windSpeedValueType: 'windSpeed_mps',
      windDirectionValueType: 'windDirection_degrees',
      unitConverterFunction: baseUnitConverter,
    },
    property: {
      windSpeedValueType: 'windSpeed_minMedianMax_mps',
      windDirectionValueType: 'windDirection_average_degrees',
      unitConverterFunction: baseUnitConverter,
    },
    region: {
      windSpeedValueType: 'windSpeed_minMedianMax_mps',
      windDirectionValueType: 'windDirection_average_degrees',
      unitConverterFunction: baseUnitConverter,
    },
  })
}
