import { removeCmOrInFromSoilProbeDepths } from 'utils/removeCmOrInFromSoilProbeDepths'
import { detailsPanelStore } from 'stores/detailsPanelStore'
import { userDetailsStore } from 'stores/userDetailsStore'
import type { PlotOptions } from 'highcharts'

const firstForecastTimestamp = +new Date() + 1000

export const soilPlotOptions: PlotOptions = {
  // we want some of the lines on soil chart to not be dashed so we make them splines instead
  spline: {
    zones: [
      {
        value: firstForecastTimestamp,
      },
      {
        dashStyle: 'Solid',
      },
    ],
  },
  series: {
    events: {
      legendItemClick: function (event) {
        event.preventDefault()

        const { depth: userDepthSetting } = userDetailsStore.getState()

        // visibility state should be maintained in probes depths of cm only
        const fixedTargetName =
          userDepthSetting === 'IMPERIAL'
            ? removeCmOrInFromSoilProbeDepths(event.target.name) * 2.5
            : removeCmOrInFromSoilProbeDepths(event.target.name)

        const newVisibilityState = !event.target.visible

        const soilVisibility: Record<number, boolean> = event.target.chart.legend.allItems.reduce(
          (acc: Record<number, boolean>, item) => {
            // visibility state should be maintained in probes depths of cm only
            const fixedSeriesName =
              userDepthSetting === 'IMPERIAL'
                ? removeCmOrInFromSoilProbeDepths(item.name) * 2.5
                : removeCmOrInFromSoilProbeDepths(item.name)

            if (fixedSeriesName) {
              acc[fixedSeriesName] = item.visible
            }

            return acc
          },
          {},
        )

        soilVisibility[fixedTargetName] = newVisibilityState

        const allItemsAreVisible = Object.values(soilVisibility).every((v) => v)

        detailsPanelStore.setState((p) => {
          return {
            ...p,
            soilVisibility,
            allSoilDepthsAreSelected: allItemsAreVisible,
          }
        })
      },
    },
  },
}
