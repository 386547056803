import { Footer } from '../NodeInstallation/components/Footer/Footer'
import { Button } from 'components/Button/Button'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Notification, useMantineTheme } from '@mantine/core'
import { translate } from 'i18n/i18n'
import { GoogleMap } from 'components/GoogleMap/GoogleMap'
import { IconPin } from 'components/icons/IconPin'
import { renderToStaticMarkup } from 'react-dom/server'
import svg64 from 'svg64'
import { NODE_STATUS_COLORS } from '../Map/_utils/getActiveNodeStatus'
import { CurrentLocation } from 'App/Map/_utils/CurrentLocation/CurrentLocation'
import { WideHeader } from 'components/ModalDrawer/WideHeader/WideHeader'

const ICON_WIDTH = 28
const ICON_HEIGHT = 40

const DefaultLocationMarker: React.FC<{
  map: google.maps.Map
  coordinates: { lat: number; lng: number }
}> = ({ map, coordinates }) => {
  const markerRef = useRef<null | google.maps.Marker>(null)
  const { viewBox, width, height, children: svgElement } = IconPin().props

  const SVGIconString = `
    <svg width="${width}" height="${height}" viewBox="${viewBox}" xmlns="http://www.w3.org/2000/svg" color="grey">
        ${renderToStaticMarkup(svgElement)}
    </svg>`

  useEffect(() => {
    if (!map) return

    const marker = new window.google.maps.Marker({
      map,
      position: coordinates,
      icon: {
        url: svg64(SVGIconString),
        scaledSize: new window.google.maps.Size(ICON_WIDTH, ICON_HEIGHT),
      },
    })

    markerRef.current = marker

    return () => {
      // Remove the marker when the component unmounts
      marker.setMap(null)
    }
  }, [map])

  return null
}

const RelocationMarker: React.FC = () => {
  // set marker to the center of the map (anchor point is the bottom center of the icon)
  return (
    <div
      css={{
        position: 'absolute',
        top: `calc(50% - ${ICON_HEIGHT}px)`,
        left: `calc(50% - ${ICON_WIDTH / 2}px)`,
        zIndex: 10000,
        color: NODE_STATUS_COLORS.planned,
        fontSize: `${ICON_HEIGHT}px`,
      }}
    >
      <IconPin />
    </div>
  )
}

const NodePositioningModal: React.FC<{
  onClose: () => void
  mapDefaultCenter: google.maps.LatLngLiteral
  mapDefaultZoom?: number
  showDefaultLocationMarker?: boolean
  headerTitle?: string
  onSubmit?: (mapCenter: google.maps.LatLngLiteral) => Promise<void> | void
  showBanner?: boolean
  disableSubmitButtonOnDefaultLocation?: boolean
}> = ({
  onSubmit,
  onClose,
  headerTitle,
  mapDefaultCenter,
  showDefaultLocationMarker = false,
  mapDefaultZoom,
  showBanner = false,
  disableSubmitButtonOnDefaultLocation = false,
}) => {
  const [hasMapMoved, setHasMapMoved] = useState(false)
  const [bannerHeight, setBannerHeight] = useState<string | null>('0px')
  const [map, setMap] = useState<google.maps.Map | null>(null)
  const [isSaving, setIsSaving] = useState(false)
  const theme = useMantineTheme()

  useEffect(() => {
    if (map) {
      const eventListner = map.addListener('center_changed', () => {
        setHasMapMoved(true)
      })

      return () => {
        eventListner.remove()
      }
    }
  }, [map])

  useEffect(() => {
    // get the notification banner height then push down the current position and reset buttons below notification banner
    const height = document.getElementById('confirm-location-banner')?.offsetHeight

    setBannerHeight(height?.toString() || '0px')
  }, [])

  const mapOptions = useMemo(() => {
    return {
      mapTypeId: 'satellite',
      disableDefaultUI: true,
      fullscreenControl: false,
      mapTypeControl: false,
      streetViewControl: false,
      minZoom: 3,
      zoom: mapDefaultZoom || 13,
      center: mapDefaultCenter,
      disableDoubleClickZoom: true,
      tilt: 0,
    }
  }, [])

  const handleResetPosition = (e: React.MouseEvent) => {
    e.stopPropagation()

    if (mapDefaultCenter) {
      map?.setCenter(mapDefaultCenter)

      setHasMapMoved(false)
    }
  }

  const handleCancel = () => {
    onClose()
  }

  const handleSubmit = async () => {
    const mapCenter = map?.getCenter()?.toJSON()

    if (!mapCenter) return

    setIsSaving(true)

    await onSubmit?.(mapCenter)

    setIsSaving(false)
  }

  const getButtonsOffsetTop = () => {
    if (!headerTitle) return 'calc(20px + env(safe-area-inset-top))'

    return bannerHeight
      ? `calc(${bannerHeight}px + env(safe-area-inset-top) + 10px) `
      : 'calc(80px + env(safe-area-inset-top))'
  }

  return (
    <>
      <>
        {bannerHeight && showBanner && (
          <Notification
            closeButtonProps={{
              style: {
                position: 'absolute',
                top: '10px',
                right: '12px',
                transform: 'scale(1.5)',
                color: 'black',
                zIndex: 100,
              },
            }}
            id="confirm-location-banner"
            title={<span style={{ fontSize: '18px' }}>{'Confirm Install Location'}</span>}
            css={{
              position: 'absolute',
              paddingTop: 'calc(10px)',
              top: 'calc(0px + env(safe-area-inset-top))',
              left: 0,
              zIndex: 10000,
              width: '100%',
              border: 'none',
              borderRadius: 0,
            }}
            withCloseButton
            onClose={() => setBannerHeight(null)}
            styles={{ icon: { backgroundColor: theme.colors.white[0] } }}
            color="white"
          >
            <span style={{ color: 'black' }}>
              {translate.phrases.placeholder('You will')} <strong>{'NOT'}</strong>
              {translate.phrases.placeholder(
                ' be able to change the equipment location once it is installed.',
              )}
              <br />
              {translate.phrases.placeholder(
                'Moving equipment location after initial install could result in loss of data.',
              )}
            </span>
          </Notification>
        )}
        {headerTitle && (
          <WideHeader
            title={headerTitle}
            css={{
              zIndex: 1000,
              position: 'absolute',
              top: 0,
              width: '100%',
              left: 0,
              background: '#ECECEC',
            }}
            onClose={onClose}
            isSecondaryModal={true}
          />
        )}
      </>
      <div style={{ position: 'absolute', top: 0, left: 0, zIndex: 100 }}>
        <GoogleMap
          width={'100vw'}
          height={'100vh'}
          style={{ position: 'relative', left: 0, top: 0, zIndex: 1 }}
          mapOptions={mapOptions}
          onInit={setMap}
          defaultErrorMessage={translate.phrases.placeholder('Error loading map.')}
        />
        {map && (
          <>
            {showDefaultLocationMarker && <DefaultLocationMarker map={map} coordinates={mapDefaultCenter} />}

            <RelocationMarker />

            <CurrentLocation
              style={{
                position: 'absolute',
                top: getButtonsOffsetTop(),
                right: '20px',
                zIndex: 10000000,
              }}
              map={map}
            />
          </>
        )}

        <Footer
          nextButtonLabel="Confirm"
          nextButtonIcon={null}
          disableNextButton={disableSubmitButtonOnDefaultLocation && !hasMapMoved}
          showPreviousButton
          previousButtonLabel="Cancel"
          previousButtonIcon={null}
          onPrevious={handleCancel}
          onNext={handleSubmit}
          loading={isSaving}
        />
        <Button
          size="lg"
          css={{
            'position': 'absolute',
            'zIndex': '100',
            'top': getButtonsOffsetTop(),
            'left': '20px',
            'backgroundColor': 'white',
            '&:not([disabled],[dataLoading="true"]):hover': {
              background: 'white',
              boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)',
              color: 'black',
            },
          }}
          onClickCapture={handleResetPosition}
          variant={'tertiary'}
          disabled={!hasMapMoved}
        >
          {translate.phrases.placeholder('Reset')}
        </Button>
      </div>
    </>
  )
}

export default NodePositioningModal
