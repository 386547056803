import { routes } from '@semios/app-platform-banyan-route-definitions'
import React, { useEffect, useState } from 'react'
import { renderFunctions } from './renderFunctions'

type SidekickStatementsResult = {
  WEATHER: {
    air_temperature_sidekick?: routes.LandingPageValuesCurrent.Response['regions'][string]['air_temperature_sidekick']
    weatherForecastDaily?: routes.LandingPageValuesCurrent.Response['regions'][string]['weatherForecastDaily']
  }
  PEST: {
    [
      trapValueType: `trap_catches_insect_id_${number}_sidekick`
    ]: routes.LandingPageValuesCurrent.Response['regions'][string]['trap_catches_insect_id_1_sidekick']
    [
      degreeDayValueType: `degree_days_insect_id_${number}_sidekick`
    ]: routes.LandingPageValuesCurrent.Response['regions'][string]['degree_days_insect_id_1_sidekick']
  }
}

export const SidekickStatements = ({ type, regionId }: { type: 'WEATHER' | 'PEST'; regionId: string }) => {
  const [statements, setStatements] = useState<SidekickStatementsResult[typeof type] | null>(null)

  useEffect(() => {
    if (regionId && type) {
      const fetchStatements = async () => {
        const urlToUse = `${import.meta.env.VITE_API_URL}${`/${routes.LandingPageValuesCurrent.path}`.replace(
          /\/\/+/g,
          '/',
        )}` // add a regex to delete extra slashes

        const params: routes.LandingPageValuesCurrent.Request = {
          regions: {
            regionIds: [regionId],
            values: {},
          },
        }

        if (type === 'WEATHER') {
          params.regions.values.air_temperature_sidekick = true

          params.regions.values.weatherForecastDaily = true
        } else if (type === 'PEST') {
          params.regions.values.degree_days_insect_id_1_sidekick = true

          params.regions.values.trap_catches_insect_id_6_sidekick = true
        }

        const response = await fetch(urlToUse, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(params),
        })

        const result = await response.json()
        const regionResult = result.regions[regionId]

        if (type === 'WEATHER') {
          setStatements({
            air_temperature_sidekick: regionResult.air_temperature_sidekick,
            weatherForecastDaily: regionResult.weatherForecastDaily,
          })
        }

        if (type === 'PEST') {
          const trapCatchKey = 'trap_catches_insect_id_6_sidekick'
          const degreeDaysKey = 'degree_days_insect_id_1_sidekick'

          setStatements({
            [trapCatchKey]: regionResult[trapCatchKey],
            [degreeDaysKey]: regionResult[degreeDaysKey],
          })
        }
      }

      fetchStatements()
    }
  }, [type, regionId])

  if (!statements) {
    return null
  }

  const children: React.ReactNode[] = []

  if ('air_temperature_sidekick' in statements) {
    children.push(
      <div key="air_temperature_sidekick">
        {renderFunctions.air_temperature_sidekick(statements.air_temperature_sidekick)}
      </div>,
    )
  }

  if ('weatherForecastDaily' in statements) {
    children.push(
      <div key="weatherForecastDaily">
        {renderFunctions.weatherForecastDaily(statements.weatherForecastDaily)}
      </div>,
    )
  }

  if ('degree_days_insect_id_1_sidekick' in statements) {
    children.push(
      <div key="degree_days_insect_id_1_sidekick">
        {renderFunctions.degree_days_insect_id_1_sidekick(statements.degree_days_insect_id_1_sidekick)}
      </div>,
    )
  }

  if ('trap_catches_insect_id_6_sidekick' in statements) {
    children.push(
      <div key="trap_catches_insect_id_6_sidekick">
        {renderFunctions.trap_catches_insect_id_1_sidekick(statements.trap_catches_insect_id_6_sidekick)}
      </div>,
    )
  }

  return <div>{children}</div>
}
