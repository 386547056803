import { routes } from '@semios/app-platform-banyan-route-definitions'
import moment from 'moment-timezone'
import { apiFetch } from 'utils/apiFetch'
import { TCachedValuesFieldAssetData, TFieldAsset, TFieldAssetValues, THasValuesDataArgs } from './_types'

const doWeHaveCachedDataAndIsItFresh = (args: THasValuesDataArgs): boolean => {
  const { data, lngLatsOrBlockIds, fieldAssetType, valuesRequested } = args

  if (!lngLatsOrBlockIds) return false

  const tenMinutesAgoTimestamp = +moment.tz().subtract(10, 'minutes')

  const isSomeDataStaleOrAbsent = Object.keys(valuesRequested).some((requestedValueGroup) => {
    //@ts-ignore
    const valueGroupData = data?.[fieldAssetType]?.[lngLatsOrBlockIds[0]]?.['values'] as {
      [key: string]: { lastFetchedTime?: number }[] | undefined
    }

    const currentDataGroup = valueGroupData?.[requestedValueGroup as keyof typeof valueGroupData]?.[0]

    // if data is absent, return true
    if (!currentDataGroup) return true

    const lastFetchedTime = currentDataGroup?.lastFetchedTime
    const isDataFresh = lastFetchedTime && lastFetchedTime >= tenMinutesAgoTimestamp

    // if data is old, we'll return true
    return !isDataFresh
  })

  return !isSomeDataStaleOrAbsent
}

const addLastFetchedTimeAndMergeFieldAssetValues = (
  fieldAsset1: TFieldAsset,
  fieldAsset2: TFieldAsset,
): TFieldAsset => {
  const lastFetchedTime = +moment.tz()

  const mappedValuesWithLastFetchedTime = (values: TFieldAssetValues) => {
    const fieldAssetValuesWithLastFetchedTime: TFieldAssetValues = {}

    Object.entries(values).forEach(([valueGroup, valueGroupData]) => {
      // @ts-ignore
      fieldAssetValuesWithLastFetchedTime[valueGroup] = valueGroupData.map((s) => ({ ...s, lastFetchedTime }))
    })

    return fieldAssetValuesWithLastFetchedTime
  }

  return Object.entries(fieldAsset2).reduce(
    (mergedFieldAssetType, [fieldAssetId, newFieldAsset]) => {
      const existingFieldAsset = mergedFieldAssetType[fieldAssetId]

      if (existingFieldAsset) {
        const newValues = newFieldAsset.values || {}

        const updatedValues = {
          ...existingFieldAsset.values,
          ...mappedValuesWithLastFetchedTime(newValues),
        }

        return { ...mergedFieldAssetType, [fieldAssetId]: { ...existingFieldAsset, values: updatedValues } }
      } else {
        const mappedValues = mappedValuesWithLastFetchedTime(newFieldAsset.values)

        return { ...mergedFieldAssetType, [fieldAssetId]: { ...newFieldAsset, values: mappedValues } }
      }
    },
    { ...fieldAsset1 },
  )
}

const mergeData = (prevData: TCachedValuesFieldAssetData, newData: TCachedValuesFieldAssetData) => {
  return Object.keys(newData).reduce(
    (prev, fieldAssetType) => {
      return {
        ...prev,
        [fieldAssetType]: addLastFetchedTimeAndMergeFieldAssetValues(
          // @ts-ignore
          prev[fieldAssetType] ?? {},
          // @ts-ignore
          newData[fieldAssetType] ?? {},
        ),
      }
    },
    { ...prevData },
  )
}

export const fetchValuesData = async (
  requestBody: routes.Values.Request,
  locallyCachedValuesData: TCachedValuesFieldAssetData = {},
): Promise<TCachedValuesFieldAssetData> => {
  const valuesToRequest: Partial<Record<keyof routes.Values.Request, unknown>> = {}
  const { dateFrom, dateTo, includeSource, ...rest } = requestBody

  Object.entries(rest).forEach(([fieldAssetType, fieldAssetRequestObject]) => {
    const { valuesRequested } = fieldAssetRequestObject

    let lngLats = [] as string[]
    let blockIds = [] as number[]

    if ('lngLats' in fieldAssetRequestObject) lngLats = fieldAssetRequestObject.lngLats as string[]

    if ('blockIds' in fieldAssetRequestObject) blockIds = fieldAssetRequestObject.blockIds as number[]

    const lngLatsOrBlockIds = lngLats.length > 0 ? lngLats : blockIds

    const weHaveThisDataAndItsFresh = doWeHaveCachedDataAndIsItFresh({
      data: locallyCachedValuesData,
      lngLatsOrBlockIds,
      fieldAssetType: fieldAssetType as keyof routes.Values.Response,
      valuesRequested,
    })

    if (!weHaveThisDataAndItsFresh) {
      valuesToRequest[fieldAssetType as keyof routes.Values.Request] = fieldAssetRequestObject
    }
  })

  if (Object.keys(valuesToRequest).length !== 0) {
    /**
     * TODO: this will request everything rather than just
     * the marginal differences between what we had. This
     * is probably a good trade-off though, as
     */
    const res = await apiFetch<routes.Values.Request, ResponseType>({
      url: routes.Values.path,
      body: requestBody,
    })

    if (typeof res === 'string') throw new Error(res)

    return mergeData(locallyCachedValuesData, res)
  }

  return locallyCachedValuesData
}
