import { routes } from '@semios/app-platform-banyan-route-definitions'
import { SprayConditionsTitleChildren } from 'App/Map/PanelDetails/SectionTitleBars/SprayConditionsTitleChildren/SprayConditionsTitleChildren'
import { getTimezoneForSelectedPropertyOrRegion } from 'App/Map/PanelDetails/_utils/getTimezoneForSelectedPropertyOrRegion'
import { propertyLacksPermissionSectionMaker } from 'App/Map/PanelDetails/_utils/propertyLacksPermissionSectionMaker'
import { selectedPropertyHasPermission } from 'App/Map/PanelDetails/_utils/selectedPropertyHasPermission'
import { EAggregationInterval } from 'App/Map/types'
import { StackedChartSection } from 'components/StackedChart/types'
import { translate } from 'i18n/i18n'
import moment from 'moment-timezone'
import { selectedFieldAssetsStore, TSelectedFieldAssetsStoreState } from 'stores/selectedFieldAssetsStore'
import { selectedValueGroupsStore } from 'stores/selectedValueGroupsStore/selectedValueGroupsStore'
import { chooseAmongstUnAggHourlyAndDaily } from '../../chooseAmongstUnAggHourlyAndDaily'
import { Legend } from './Legend/Legend'
import { makeIntervals } from './makeIntervals'
import { doesSelectedBlockHaveValueTypes } from 'utils/doesSelectedFieldAssetHaveValueTypes'

const checkPermission = () => selectedPropertyHasPermission({ permission: 'VIEW_SPRAY_FORECAST' })

export const apiArgs = ({
  selectedValueGroups,
  selectedFieldAssets,
}: {
  selectedValueGroups: ReturnType<typeof selectedValueGroupsStore.getState>['selectedValueGroups']
  selectedFieldAssets: TSelectedFieldAssetsStoreState
}): Partial<routes.Values.Request> => {
  if (!checkPermission()) return {}

  if (!selectedValueGroups.spray_conditions || !selectedFieldAssets.block) return {}

  const aggInterval = chooseAmongstUnAggHourlyAndDaily()

  const preferredAggregationInterval = {
    preferredAggregationInterval:
      aggInterval === EAggregationInterval.UNAGGREGATED ? EAggregationInterval.HOURLY : aggInterval,
  }

  const valuesRequested: Record<string, typeof preferredAggregationInterval> = {
    sprayConditions: preferredAggregationInterval,
  }

  if (!doesSelectedBlockHaveValueTypes({ valuesTimeseries: Object.keys(valuesRequested) })) return {}

  return {
    blocks: {
      blockIds: [selectedFieldAssets.block],
      valuesRequested,
    },
  }
}

export const content = ({ data }: { data: routes.Values.Response }): StackedChartSection => {
  const commonReturnItems = {
    title: translate.phrases.banyanApp('Spray Conditions'),
    titleChildren: (
      <SprayConditionsTitleChildren
        propertiesXorBlocks="BLOCKS"
        valuesTimeseriesToFilterOn={['sprayConditions']}
      />
    ),
    id: 'stackem-spray-conditions',
  }

  if (!checkPermission()) return propertyLacksPermissionSectionMaker(commonReturnItems)

  const timezone = getTimezoneForSelectedPropertyOrRegion()
  const blockId = String(selectedFieldAssetsStore.getState().block)
  const { metadata, timeseries } = data?.blocks?.[blockId]?.values?.sprayConditions?.[0] ?? {}
  const isDaily = metadata?.aggregationInterval === EAggregationInterval.DAILY
  const intervals = makeIntervals({ interval: isDaily ? 'day' : 'hour', timeseries, timezone })

  return {
    ...commonReturnItems,
    items: [
      {
        childrenLower: <Legend intervals={intervals} />,
        chartConfig: {
          semiosHighchartsAdditions: {
            id: 'Spray Conditions',
            firstForecastTimestamp: +new Date(),
          },
          chart: {
            type: 'xrange',
            height: 200,
          },
          tooltip: {
            xDateFormat: isDaily ? '%D' : '%Z',
            formatter: function () {
              const { color, name, x, x2 } = this.points?.[0]?.point ?? {}
              const dateFrom = moment.tz(x, timezone)
              const dateTo = moment.tz(x2, timezone)
              const formatToUse = isDaily ? 'MMM D, YYYY' : 'ddd, MMM D, YYYY h:mm a (z)'
              const formattedDateFrom = translate.dates.format(dateFrom, formatToUse)
              const formattedDateTo = translate.dates.format(dateTo, formatToUse)

              return `
              <div style="font-size:16px;display:flex;flex-direction:column;gap:4px 12px">
                <div style="display:flex;align-items:center;gap:6px">
                  <div style="width:12px;height:12px;background:${color}"></div>
                  <div style="font-weight:bold">${name}</div>
                </div>
                <table>
                  <tr>
                    <td style="font-weight:bold;padding:0 6px">${translate.phrases.banyanApp('From')}</td>
                    <td>${formattedDateFrom}</td>
                  </tr>
                  <tr>
                    <td style="font-weight:bold;padding:0 6px">${translate.phrases.banyanApp('To')}</td>
                    <td>${formattedDateTo}</td>
                  </tr>
                </table>
              </div>`
            },
          },
          series: [
            {
              showInLegend: false, // we make a manual legend instead in childrenLower
              borderRadius: 0,
              minPointLength: 3,
              borderWidth: 0,
              enableMouseTracking: true,
              type: 'xrange',
              id: 'sprayConditions',
              name: translate.phrases.banyanApp('Spray Conditions'),
              data: intervals,
            },
          ],
        },
      },
    ],
    titleChildren: (
      <SprayConditionsTitleChildren
        propertiesXorBlocks="BLOCKS"
        sprayConditionsRules={metadata?.sprayConditionsRules}
        valuesTimeseriesToFilterOn={['sprayConditions']}
      />
    ),
  }
}
