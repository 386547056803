import moment from 'moment-timezone'
import { persistentInit } from 'stores/_utils/persistentStore/persistentStore'
import { getIsoWithoutTheZ } from 'utils/getIsoWithoutTheZ'
import { smallStore } from './smallStore'
import { HISTORICAL_NUMBER_OF_DAYS_FOR_REGIONAL_ONLY_USERS } from '@semios/app-platform-common'
import { userDetailsStore } from './userDetailsStore'

const storeName = 'detailsPanelStore'

type ISOStringWithoutTheZed = string

export type TPrecipitationAggregation = 'HOURLY' | 'DAILY'

export type TEvapotranspirationValueToShow = 'ETC' | 'ETO'

export type TChillMarVsMay = 'MAR' | 'MAY'

export type TChillSepVsNov = 'SEP' | 'NOV'

export type TSelectedTab = 'DETAILS' | 'SUMMARY' | 'STATUS' | ''

// exclude -5cm | default
export type TSoilAverage = 'ALL_EXCEPT_SHALLOWEST' | 'DEFAULT'

export const detailsPanelStore = persistentInit<{
  allSoilDepthsAreSelected: boolean
  showSelectedSoilDepths: boolean
  chillMarVsMay: TChillMarVsMay
  chillSepVsNov: TChillSepVsNov
  compareSeasonsInterval: number
  dateFrom: ISOStringWithoutTheZed
  dateTo: ISOStringWithoutTheZed
  wmDateFrom: ISOStringWithoutTheZed
  wmDateTo: ISOStringWithoutTheZed
  selectedTab: TSelectedTab
  evapotranspirationValueToShow: TEvapotranspirationValueToShow
  keyForRefreshing: string | null
  keyForRefreshingSummaryTab: string | null
  keyForRedrawing: string | null
  larvalTrendShowValues: boolean
  precipitationAggregation: TPrecipitationAggregation
  soilDepthsToAverage: TSoilAverage
  soilVisibility: Record<string, boolean>
  hasExtraRightPadding: boolean
  showDateRangeDoesntApply: boolean
}>({
  initialState: {
    allSoilDepthsAreSelected: false,
    showSelectedSoilDepths: false,
    chillMarVsMay: 'MAR',
    chillSepVsNov: 'SEP',
    compareSeasonsInterval: 0,
    dateFrom: getIsoWithoutTheZ(moment.tz(moment.tz.guess()).subtract(3, 'days').startOf('day')),
    dateTo: getIsoWithoutTheZ(moment.tz(moment.tz.guess()).add(14, 'days').endOf('day')),
    wmDateFrom: getIsoWithoutTheZ(moment.tz(moment.tz.guess()).subtract(30, 'days').startOf('day')),
    wmDateTo: getIsoWithoutTheZ(moment.tz(moment.tz.guess()).endOf('day')),
    selectedTab: '',
    evapotranspirationValueToShow: 'ETC',
    keyForRefreshing: null,
    keyForRefreshingSummaryTab: null,
    keyForRedrawing: null,
    larvalTrendShowValues: false,
    precipitationAggregation: 'HOURLY',
    soilDepthsToAverage: 'DEFAULT',
    soilVisibility: {},
    hasExtraRightPadding: false,
    showDateRangeDoesntApply: true,
  },
  keysToPutInURL: {
    allSoilDepthsAreSelected: true,
    showSelectedSoilDepths: true,
    chillMarVsMay: true,
    chillSepVsNov: true,
    compareSeasonsInterval: true,
    dateFrom: true,
    dateTo: true,
    wmDateFrom: true,
    wmDateTo: true,
    selectedTab: true,
    evapotranspirationValueToShow: true,
    keyForRefreshing: false,
    keyForRefreshingSummaryTab: false,
    keyForRedrawing: false,
    larvalTrendShowValues: false,
    precipitationAggregation: true,
    soilDepthsToAverage: true,
    soilVisibility: true,
    hasExtraRightPadding: false,
    showDateRangeDoesntApply: false,
  },
  keysToPutInPersistentStorage: {
    allSoilDepthsAreSelected: true,
    showSelectedSoilDepths: true,
    chillMarVsMay: true,
    chillSepVsNov: true,
    compareSeasonsInterval: true,
    dateFrom: true,
    dateTo: true,
    wmDateFrom: true,
    wmDateTo: true,
    selectedTab: true,
    evapotranspirationValueToShow: true,
    keyForRefreshing: false,
    keyForRefreshingSummaryTab: false,
    keyForRedrawing: false,
    larvalTrendShowValues: false,
    precipitationAggregation: true,
    soilDepthsToAverage: true,
    soilVisibility: true,
    hasExtraRightPadding: false,
    showDateRangeDoesntApply: false,
  },
  storeName,
})

smallStore.subscribeToChanges(
  (s) => s.showRegionalData,
  (showRegionalData) => {
    if (showRegionalData) {
      detailsPanelStore.setState((s) => ({
        ...s,
        compareSeasonsInterval: 0,
        keyForRefreshing: new Date().toISOString(),
      }))
    } else {
      detailsPanelStore.setState((s) => ({
        ...s,
        keyForRedrawing: new Date().toISOString(),
      }))
    }
  },
)

userDetailsStore.subscribeToChanges(
  (s) => s.isFreeRegionalUser,
  (isFreeRegionalUser) => {
    if (isFreeRegionalUser) {
      detailsPanelStore.setState((s) => ({
        ...s,
        dateFrom: getIsoWithoutTheZ(moment.tz(moment.tz.guess()).subtract(3, 'days').startOf('day')),
        dateTo: getIsoWithoutTheZ(
          moment
            .tz(moment.tz.guess())
            .add(HISTORICAL_NUMBER_OF_DAYS_FOR_REGIONAL_ONLY_USERS, 'days')
            .endOf('day'),
        ),
      }))
    } else {
      detailsPanelStore.setState((s) => ({
        ...s,
        dateFrom: getIsoWithoutTheZ(moment.tz(moment.tz.guess()).subtract(3, 'days').startOf('day')),
        dateTo: getIsoWithoutTheZ(moment.tz(moment.tz.guess()).add(14, 'days').endOf('day')),
      }))
    }
  },
)
