import { routes } from '@semios/app-platform-banyan-route-definitions'

export type M_ITEM =
  | 'M_Block'
  | 'M_Zone' // Not in csv... but I'm thinking we'll need it
  | 'M_SCD'
  | 'M_InBlockPoint'
  | 'M_OutOfBlockPoint'
  | 'M_Trap'
  | 'M_CurrentLocation'
  | 'M_Property'
  | 'M_Region'
  | 'M_IrrigationZone'

export type ITEM = {
  MapItem: M_ITEM
  Section: string
  valueGroup: string
  valueType: string
  childItems?: string[]
}

export enum MAP_VISUAL {
  BLOCK = 'BLOCK',
  POINT = 'POINT',
  PROPERTY = 'PROPERTY',
  SCD = 'SCD',
  TRAP = 'TRAP',
  REGION = 'REGION',
  IRRIGATION_ZONE = 'IRRIGATION_ZONE',
}

export type TValuesCurrentPropertiesValueTypes = Exclude<
  keyof NonNullable<routes.ValuesCurrent.Response['properties']>[keyof NonNullable<
    routes.ValuesCurrent.Response['properties']
  >],
  '_metaData'
>

export type TValuesCurrentBlocksValueTypes = Exclude<
  keyof NonNullable<routes.ValuesCurrent.Response['blocks']>[keyof NonNullable<
    routes.ValuesCurrent.Response['blocks']
  >],
  '_metaData'
>

export type TValuesCurrentPointsValueTypes = Exclude<
  keyof NonNullable<routes.ValuesCurrent.Response['points']>[keyof NonNullable<
    routes.ValuesCurrent.Response['points']
  >],
  '_metaData'
>

export type TValuesCurrentHeatmapPointsValueTypes = Exclude<
  keyof NonNullable<routes.ValuesCurrent.Response['heatmap_points']>[keyof Omit<
    NonNullable<routes.ValuesCurrent.Response['heatmap_points']>,
    'blockIds'
  >],
  'blockIds' | '_metaData'
>

export type TValuesCurrentIrrigationZoneValueTypes = Exclude<
  keyof NonNullable<routes.ValuesCurrent.Response['irrigation_zones']>[keyof NonNullable<
    routes.ValuesCurrent.Response['irrigation_zones']
  >],
  '_metaData'
>

export type TValuesCurrentRegionValueTypes = Exclude<
  keyof NonNullable<routes.ValuesCurrent.Response['regions']>[keyof NonNullable<
    routes.ValuesCurrent.Response['regions']
  >],
  '_metaData'
>
