export const regions = [
  {
    regionId: 'd48e40e1-ed19-4e2b-949e-bd80dadb0863',
    name: 'Alameda',
  },
  {
    regionId: 'f6a9fa61-078c-4b0a-a5bb-d6c8212b843e',
    name: 'Amador',
  },
  {
    regionId: '437641c0-593a-44ca-917d-66e254b5cb40',
    name: 'Butte',
  },
  {
    regionId: 'ffe88bef-3be1-4310-ad76-a4596a5adde0',
    name: 'Calaveras',
  },
  {
    regionId: '33eb20dc-173c-4c31-84d2-6a4e8b3cb277',
    name: 'Colusa',
  },
  {
    regionId: 'ab3255f0-42bf-46a0-873b-93f83a4a0528',
    name: 'Contra Costa',
  },
  {
    regionId: 'e7be37ae-a69b-40fb-b245-d18e5d193a0a',
    name: 'El Dorado',
  },
  {
    regionId: '2752c523-2f31-4ad8-b56e-bda126e98ed5',
    name: 'Fresno',
  },
  {
    regionId: '868c7aea-70a7-4e6e-b2ac-7f81cafca1fd',
    name: 'Glenn',
  },
  {
    regionId: '0830bb14-df66-4a2e-a80d-3b6bae2e95ce',
    name: 'Kern',
  },
  {
    regionId: 'aa0e1c2c-9d43-4d46-a29a-16e749c9f224',
    name: 'Kings',
  },
  {
    regionId: '278dfbd2-d687-4e53-b581-c88d97b411c4',
    name: 'Madera',
  },
  {
    regionId: 'd051a086-15ef-4a4d-9ad0-317f56239c84',
    name: 'Mariposa',
  },
  {
    regionId: '92d858a6-581a-4efd-a659-9610e61ef2be',
    name: 'Merced',
  },
  {
    regionId: '066bf679-ad48-4ca3-8221-beb701b3d68a',
    name: 'Nevada',
  },
  {
    regionId: '01ef96e6-324f-45d7-af1f-14b11d36f9d9',
    name: 'Placer',
  },
  {
    regionId: 'b6018172-aef4-4f8f-9aca-301770895a42',
    name: 'Sacramento',
  },
  {
    regionId: 'f06db6cb-2554-4b20-833f-3070a3546cec',
    name: 'San Benito',
  },
  {
    regionId: '75dd07b8-9c9d-4276-9fa5-2aa570aa50e4',
    name: 'San Joaquin',
  },
  {
    regionId: '387fea24-1aa6-4548-8dcc-a3744db12fbf',
    name: 'Shasta',
  },
  {
    regionId: '70b58512-1069-41dd-9cc1-cd4754a47064',
    name: 'Solano',
  },
  {
    regionId: '8ee8fa73-ceb3-4a19-9bf9-eb303ca7a258',
    name: 'Stanislaus',
  },
  {
    regionId: 'a4c219b3-51f6-4213-81d5-2c0e971709bf',
    name: 'Sutter',
  },
  {
    regionId: '638719f3-1ea1-4ba4-818b-eaf50cd2ea13',
    name: 'Tehama',
  },
  {
    regionId: '7d1a8403-aad1-4aca-b22a-3d76b10ce6ee',
    name: 'Tulare',
  },
  {
    regionId: 'b23d604e-0447-4a19-810a-69d534daf32a',
    name: 'Tuolumne',
  },
  {
    regionId: '3c5e6d91-87bf-46ce-b824-9e934a56886b',
    name: 'Yolo',
  },
  {
    regionId: '39b24a93-c4a9-421f-9557-91f05f3923f9',
    name: 'Yuba',
  },
]
